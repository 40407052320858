import React, { useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { useEffectAsync } from '../../../reactHelper';
import { prefixString } from '../../../common/util/stringUtils';

const SupportTicketTypeSelect = (props) => {
  const t = useTranslation();
  const [loading, setLoading] = useState(true);
  const [ticketTypes, setTicketTypes] = useState([]);

  useEffectAsync(async () => {
    const response = await fetch('/api/support-tickets/types');
    if (response.ok) {
      const data = await response.json();
      setTicketTypes(data);
    } else {
      throw Error(await response.text());
    }
    setLoading(false);
  }, []);

  return (loading ? (
    <TextField {...props} variant="filled" label={t('sharedType')} value="" disabled />
  ) : (
    <TextField
      {...props}
      select
      variant="filled"
      label={t('sharedType')}
      helperText=" "
    >
      {ticketTypes.map((type) => (
        <MenuItem key={type} value={type}>
          {t(prefixString('supportTicketType', type))}
        </MenuItem>
      ))}
    </TextField>
  ));
};

export default SupportTicketTypeSelect;
