import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField, Button,
  MenuItem, Snackbar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCatch, useEffectAsync } from '../reactHelper';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import AccountsMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import { snackBarDurationShortMs } from '../common/util/duration';

const AddDriverPage = () => {
  const t = useTranslation();
  const [devices, setDevices] = useState([]);
  const [items, setItems] = useState({});
  const [open, setOpen] = useState(false);
  const [driverNameCheck, setDriverNameCheck] = useState('');

  const navigate = useNavigate();

  useEffectAsync(async () => {
    const getDrivers = await fetch('/api/drivers');
    const getDevices = await fetch('/api/devices');
    if (getDrivers.ok) {
      const DriverItems = await getDrivers.json();
      const DeviceItems = await getDevices.json();
      const filteredDevices = DeviceItems.filter((device) => !DriverItems.some((driver) => driver.deviceId === device.id));
      setDevices(filteredDevices);
    } else {
      throw Error(await getDrivers.text());
    }
  }, []);

  const checkInputText = (input) => {
    if (!input) {
      setDriverNameCheck(t('driverNameIsEmptyErrorMessage'));
      return true;
    }
    if (input.length < 1 || input.length > 40) {
      setDriverNameCheck(t('inputTextLengthLimitType', { minLength: 1, maxLength: 40 }));
      return true;
    }
    return false;
  };

  const handleSave = useCatch(async () => {
    const isFail = checkInputText(items.name);
    if (!isFail) {
      const response = await fetch('/api/drivers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(items),
      });
      if (response.ok) {
        setOpen(true);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        throw Error(await response.text());
      }
    }
  });

  return (
    <PageLayout menu={<AccountsMenu />} breadcrumbs={['settingsUser', 'sharedDrivers']}>
      <CardLayout>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('addDriverTitle')}
          button={(
            <Button
              fullWidth
              variant="outlined"
              color="outlinedButtonGreen"
              onClick={handleSave}
            >
              {t('shareSave')}
            </Button>
          )}
        >
          <TextField
            required
            color="success"
            variant="filled"
            value={items.name || ''}
            error={driverNameCheck !== ''}
            helperText={driverNameCheck !== '' ? driverNameCheck : ' '}
            onChange={(event) => {
              setDriverNameCheck('');
              setItems({ ...items, name: event.target.value });
            }}
            label={t('driverName')}
          />
          <TextField
            color="success"
            variant="filled"
            value={items.phone || ''}
            onChange={(event) => {
              setItems({ ...items, phone: event.target.value });
            }}
            helperText=" "
            label={t('driverPhone')}
          />
          <TextField
            select
            variant="filled"
            label={t('deviceTitle')}
            value={items.deviceId || ''}
            defaultValue=""
            helperText=" "
            onChange={(event) => {
              setItems({ ...items, deviceId: event.target.value });
            }}
          >
            <MenuItem value="">{t('shareSelectNothing')}</MenuItem>
            {devices && devices.length > 0 && devices.map((device) => (
              <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
            ))}
          </TextField>
        </CardSection>
      </CardLayout>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={snackBarDurationShortMs}
        message={t('addDriverSuccessMessage')}
      />
    </PageLayout>
  );
};

export default AddDriverPage;
