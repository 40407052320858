import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button, IconButton, Typography, TextField, InputAdornment,
  ListItemText, ListItemIcon,
  Radio, RadioGroup, FormControlLabel, FormControl,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import AccountMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import TransferAdminView from './components/TransferAdminView';
import { useEffectAsync } from '../reactHelper';
import { filterFullNameByKeyword } from './components/SearchHeader';
import { truncateText } from '../common/util/formatter';

const useStyles = makeStyles(() => ({
  switchContainer: {
    margin: '0.5rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const GroupsAdminTransferPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();
  const [members, setMembers] = useState([]);
  const [transferView, setTransferView] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState({ userId: 0 });
  const [validate, setValidate] = useState(false);
  const [groupGeofences, setGroupGeofences] = useState([]);
  const [groupDevices, setGroupDevices] = useState([]);
  const [keyword, setKeyword] = useState('');

  // const sharedGroupGeofences = useSelector((state) => state.geofences.groupItems);
  // const sharedGroupDevices = useSelector((state) => state.devices.groupItems);

  useEffectAsync(async () => {
    const getMembers = await fetch(`/api/groups-members/members?groupId=${id}`);
    try {
      if (getMembers.ok) {
        const thisMembers = await getMembers.json();
        setMembers(thisMembers);
      } else {
        setMembers([]);
        throw Error(await getMembers.text());
      }
    } catch (error) {
      setMembers([]);
      throw Error(error);
    }
  }, [id]);

  useEffectAsync(async () => {
    try {
      const response = await fetch(`/api/groups/single-group-devices?groupId=${id}`);
      if (response.ok) {
        const devices = await response.json();
        setGroupDevices(devices);
      } else {
        setGroupDevices([]);
        throw Error(await response.text());
      }
    } catch (error) {
      setGroupDevices([]);
      throw Error(error);
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(`/api/groups/groupGeofence?groupId=${id}`);
    if (response.ok) {
      setGroupGeofences(await response.json());
    }
  }, []);

  const checkValidate = () => {
    // check if new admin is be selected
    const isNewAdminSelected = selectedAdmin.userId !== 0;

    // check if group have shared devices
    const isShareDeviceNotExist = groupDevices.length < 1;

    // check if group have shared geofence
    const isSharedGeofenceNotExist = !groupGeofences.some((groupGeo) => groupGeo.groupId === Number(id));

    setValidate(isNewAdminSelected && isShareDeviceNotExist && isSharedGeofenceNotExist);
  };

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout disableDivider>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('groupAdminTransfer')}
          button={(
            <Button
              fullWidth
              variant="contained"
              color="containedButtonGreen"
              onClick={() => {
                checkValidate();
                setTransferView(true);
              }}
            >
              {t('groupAdminTransferAssign')}
            </Button>
          )}
        >
          <TextField
            variant="filled"
            label={t('searchByName')}
            helperText=" "
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <Typography variant="body2" sx={{ marginTop: '0.87rem', marginBottom: '1.19rem' }}>
            {t('groupAdminTransferMessage')}
          </Typography>
          <FormControl fullWidth>
            <Typography sx={{ mb: '0.5rem' }}>{t('groupAllMember')}</Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              { members.filter(filterFullNameByKeyword(keyword)).map((member) => (
                <div className={classes.switchContainer} key={member.userId}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={truncateText(member.fullName, 25)} />
                  {member.role === 'member' && (
                    <FormControlLabel
                      key={member.userId}
                      value={member.userId}
                      onChange={() => {
                        setSelectedAdmin(member);
                      }}
                      control={<Radio />}
                    />
                  )}
                </div>
              )) }
            </RadioGroup>
          </FormControl>
          <TransferAdminView open={transferView} validate={validate} newAdmin={selectedAdmin} onClose={() => setTransferView(false)} />
        </CardSection>
      </CardLayout>
    </PageLayout>
  );
};

export default GroupsAdminTransferPage;
