import React, { useEffect, useState } from 'react';
import { PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth';
import {
  disableMultiFactors, enablePhoneFactorAuth, sendEnrollPhoneVerificationCode, sendPhoneVerificationCode, clearRecaptchaVerifier,
} from '../auth';
import { useEffectAsync } from '../../reactHelper';

const VERIFY_CODE_LENGTH = 6;
const VERIFY_CODE_CD_SECONDS = 59;

/**
 * @param {*} onVerificationCodeVerifySuccess callback function to be called when verification code is verified (sign in success)
 * @param {*} onFactorEnableSuccess callback function to be called when factor is enabled
 * @param {*} onFactorDisableSuccess callback function to be called when factor is disabled
 * @param {*} onVerificationCodeResendSuccess callback function to be called when verification code is resent
 * @returns
 */
const PhoneFactorAuth = ({
  action, multiFactorResolver, phoneNumber, setPhoneNumberHint, setVerificationCode, setVerificationCodeError,
  inputVerificationCode, verificationCodeToVerify, onVerificationCodeVerifySuccess, onFactorDisableSuccess, onFactorEnableSuccess,
  resendVerificationCode, onVerificationCodeResendSuccess, onVerificationCodeResendFail,
}) => {
  const [expectedVerificationId, setExpectedVerificationId] = useState('');
  const recaptchaContainerId = 'recaptcha-container';

  const isVerificationCodeValid = (value) => {
    const regex = new RegExp(`^\\d{${VERIFY_CODE_LENGTH}}$`);
    return regex.test(value);
  };

  const handleVerifyCode = async () => {
    if (isVerificationCodeValid(verificationCodeToVerify)) {
      const cred = PhoneAuthProvider.credential(expectedVerificationId, verificationCodeToVerify);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      try {
        await multiFactorResolver.resolveSignIn(multiFactorAssertion);
        setVerificationCodeError(null);
        if (onVerificationCodeVerifySuccess) {
          onVerificationCodeVerifySuccess(cred);
        }

        if (action === 'unenroll') {
          await disableMultiFactors();
          onFactorDisableSuccess();
        }

        clearRecaptchaVerifier(recaptchaContainerId);
      } catch (error) {
        setVerificationCodeError(error);
      }
    } else {
      setVerificationCodeError(new Error('Invalid verification code'));
    }
  };

  const handleVerifyEnrollCode = async () => {
    try {
      const multiFactorObj = await enablePhoneFactorAuth(expectedVerificationId, verificationCodeToVerify);
      clearRecaptchaVerifier(recaptchaContainerId);
      onFactorEnableSuccess(multiFactorObj);
    } catch (error) {
      setVerificationCodeError(error);
    }
  };

  const sendVerificationCode = async (multiFactorResolver) => {
    const multiFactorHint = multiFactorResolver.hints[0];
    if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      setPhoneNumberHint(multiFactorHint.phoneNumber);
      const verificationId = await sendPhoneVerificationCode(multiFactorResolver, recaptchaContainerId);
      setExpectedVerificationId(verificationId);
    }
  };

  const sendEnrollVerificationCode = async (phoneNumber) => {
    const verificationId = await sendEnrollPhoneVerificationCode(phoneNumber, recaptchaContainerId);
    setExpectedVerificationId(verificationId);
  };

  useEffectAsync(async () => {
    try {
      if (multiFactorResolver) {
        await sendVerificationCode(multiFactorResolver);
      } else {
        await sendEnrollVerificationCode(phoneNumber);
      }
      if (onVerificationCodeResendSuccess && resendVerificationCode) {
        onVerificationCodeResendSuccess();
      }
    } catch (error) {
      if (onVerificationCodeResendFail) {
        onVerificationCodeResendFail(error);
      }
    }
  }, [resendVerificationCode]);

  useEffect(() => {
    setVerificationCodeError(null);
    if ((inputVerificationCode === '' || /^\d+$/.test(inputVerificationCode)) && inputVerificationCode.length <= VERIFY_CODE_LENGTH) {
      setVerificationCode(inputVerificationCode);
    }
  }, [inputVerificationCode]);

  useEffect(() => {
    if (verificationCodeToVerify) {
      if (multiFactorResolver) {
        handleVerifyCode();
      }

      if (action === 'enroll') {
        handleVerifyEnrollCode();
      }
    }
  }, [verificationCodeToVerify]);

  // clear recaptcha verifier when unmount
  useEffect(() => () => {
    clearRecaptchaVerifier(recaptchaContainerId);
  }, []);

  return <div id={recaptchaContainerId} />;
};

export default PhoneFactorAuth;
export { VERIFY_CODE_LENGTH, VERIFY_CODE_CD_SECONDS };
