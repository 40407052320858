import React, { Children } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, Container, Divider } from '@mui/material';
import CardSection from './CardSection';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      maxWidth: theme.dimensions.cardLayoutMaxWidth,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  card: {
    pointerEvents: 'auto',
    borderRadius: '1.25rem',
    margin: '1.5rem 0',
    padding: '0.875rem 0',
  },
  cardDivider: {
    margin: '0 0.875rem',
  },
}));

const CardLayout = ({ children, disableDivider }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Card elevation={8} className={classes.card}>
        {Array.isArray(children) ? Children.map(children, (child, index) => (
          <>
            {!!index && !disableDivider && child?.type?.name === CardSection.name && <Divider className={classes.cardDivider} />}
            {child}
          </>
        ), []) : children}
      </Card>
    </Container>
  );
};

export default CardLayout;
