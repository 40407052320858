import React, { useState } from 'react';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import MapPage from './main/MapPage';
// import CombinedReportPage from './reports/CombinedReportPage';
import RouteReportPage from './reports/RouteReportPage';
import ServerPage from './settings/ServerPage';
import UsersPage from './settings/management/UsersPage';
import ManagementDevicesPage from './settings/management/DevicesPage';
import NewsAdminPage from './settings/management/NewsPage';
import ManagementSupportPage from './settings/management/SupportPage';
import DeviceEditPage from './accounts/DevicePage';
// import UserPage from './settings/UserPage';
// import NotifyPage from './settings/NotifyPage';
import NotificationsPage from './settings/NotificationsPage';
import NotificationPage from './settings/NotificationPage';
import GroupsPage from './settings/management/GroupsPage';
import GroupPage from './settings/GroupPage';
// import PositionPage from './other/PositionPage';
import NetworkPage from './other/NetworkPage';
import EventReportPage from './reports/EventReportPage';
import ReplayPage from './other/ReplayPage';
// import TripReportPage from './reports/TripReportPage';
import StopReportPage from './reports/StopReportPage';
// import SummaryReportPage from './reports/SummaryReportPage';
import ChartReportPage from './reports/ChartReportPage';
import DriversPage from './accounts/DriversPage';
import AddDriverPage from './accounts/AddDriverPage';
import EditDriverPage from './accounts/EditDriverPage';
import DriverPage from './settings/DriverPage';
// import CalendarsPage from './settings/CalendarsPage';
// import CalendarPage from './settings/CalendarPage';
// import ComputedAttributesPage from './settings/ComputedAttributesPage';
// import ComputedAttributePage from './settings/ComputedAttributePage';
// import MaintenancesPage from './settings/MaintenancesPage';
// import MaintenancePage from './settings/MaintenancePage';
// import CommandsPage from './settings/CommandsPage';
// import CommandPage from './settings/CommandPage';
import StatisticsPage from './reports/StatisticsPage';
import LoginHistoryPage from './reports/LoginHistoryPage';
import LoginPage from './login/LoginPage';
import LoginRedirectPage from './login/LoginRedirectPage';
import RegisterPage from './login/RegisterPage';
import ResetPasswordPage from './login/ResetPasswordPage';
import GeofencesPage from './other/GeofencesPage';
// import GeofencePage from './settings/GeofencePage';
import useQuery from './common/util/useQuery';
import { useEffectAsync } from './reactHelper';
import { devicesActions } from './store';
import EventPage from './other/EventPage';
import PreferencesPage from './settings/PreferencesPage';
import AccumulatorsPage from './settings/AccumulatorsPage';
import CommandDevicePage from './settings/CommandDevicePage';
import CommandGroupPage from './settings/CommandGroupPage';
import App from './App';
// import ChangeServerPage from './other/ChangeServerPage';
import GroupsAccountPage from './accounts/GroupsPage';
import GroupMemberPage from './accounts/GroupPage';
import GroupsEditPage from './accounts/GroupsEditPage';
import GroupNotificationsPage from './accounts/GroupNotificationsPage';
import GroupsShareDevicesPage from './accounts/GroupsShareDevicesPage';
import GroupsApplyGeofencePage from './accounts/GroupsApplyGeofencePage';
import GroupsMemberManagePage from './accounts/GroupsMemberManagePage';
import GroupsMemberInvitePage from './accounts/GroupsMemberInvitePage';
import GroupsAdminTransferPage from './accounts/GroupsAdminTransferPage';
import BasicInfoPage from './accounts/BasicInfoPage';
import BillPage from './accounts/BillPage';
import DevicesPage from './accounts/DevicesPage';
import AddDevicesPage from './accounts/AddDevicesPage';
import PasswordChangePage from './accounts/PasswordChangePage';
// import ScheduledPage from './reports/ScheduledPage';
import DevicePage from './settings/DevicePage';
// import DeviceConnectionsPage from './accounts/DeviceConnectionsPage';
import GroupConnectionsPage from './settings/GroupConnectionsPage';
import UserConnectionsPage from './settings/UserConnectionsPage';
import SupportPage from './settings/SupportPage';
import NotFoundPage from './other/NotFoundPage';
import TwoFactorAuthenticationChangePage from './accounts/TwoFactorAuthenticationChangePage';
import DeleteAccountPage from './accounts/DeleteAccountPage';
import AccountDeleteHelpPage from './help/AccountDeleteHelpPage';
import ProtectedRoute from './ProtectedRoute';
import { useAdministrator, useSuperuser } from './common/util/permissions';
import NewsPage from './news/NewsPage';
import ApiLogPage from './settings/management/ApiLogPage';
import DeviceLogPage from './settings/management/DeviceLogPage';

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [redirectsHandled, setRedirectsHandled] = useState(false);

  const { pathname } = useLocation();
  const query = useQuery();

  useEffectAsync(async () => {
    if (query.get('token')) {
      const token = query.get('token');
      await fetch(`/api/session?token=${encodeURIComponent(token)}`);
      navigate(pathname);
    } else if (query.get('deviceId')) {
      const deviceId = query.get('deviceId');
      const response = await fetch(`/api/devices?uniqueId=${deviceId}`);
      if (response.ok) {
        const items = await response.json();
        if (items.length > 0) {
          dispatch(devicesActions.selectId(items[0].id));
        }
      } else {
        throw Error(await response.text());
      }
      navigate('/');
    } else if (query.get('eventId')) {
      const eventId = parseInt(query.get('eventId'), 10);
      navigate(`/event/${eventId}`);
    } else {
      setRedirectsHandled(true);
    }
  }, [query]);

  if (!redirectsHandled) {
    return (<LinearProgress />);
  }
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/login/redirect" element={<LoginRedirectPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      {/* <Route path="/change-server" element={<ChangeServerPage />} /> */}
      <Route path="/help/account-delete" element={<AccountDeleteHelpPage />} />
      <Route path="/" element={<App />}>
        <Route index element={<MapPage />} />

        {/* <Route path="map" element={<MapPage />} /> */}
        {/* <Route path="position/:id" element={<PositionPage />} /> */}
        <Route path="network/:positionId" element={<NetworkPage />} />
        <Route path="event/:id" element={<EventPage />} />
        <Route path="replay" element={<ReplayPage />} />
        <Route path="geofences" element={<GeofencesPage />} />
        <Route path="accounts">
          <Route path="groups" element={<GroupsAccountPage />} />
          <Route path="group/:id" element={<GroupMemberPage />} />
          <Route path="group/:id/notifications" element={<GroupNotificationsPage />} />
          <Route path="edit-group/:id" element={<GroupsEditPage />} />
          <Route path="share-devices/:id" element={<GroupsShareDevicesPage />} />
          <Route path="apply-geofence/:id" element={<GroupsApplyGeofencePage />} />
          <Route path="member-manage/:id" element={<GroupsMemberManagePage />} />
          <Route path="member-invite/:id" element={<GroupsMemberInvitePage />} />
          <Route path="admin-transfer/:id" element={<GroupsAdminTransferPage />} />
          <Route path="bill" element={<BillPage />} />
          <Route path="drivers" element={<DriversPage />} />
          <Route path="add-driver" element={<AddDriverPage />} />
          <Route path="edit-driver/:id" element={<EditDriverPage />} />
          <Route path="info/:id" element={<BasicInfoPage />} />
          <Route path="devices" element={<DevicesPage />} />
          {/* <Route path="device/:id/connections" element={<DeviceConnectionsPage />} /> */}
          <Route path="add-device" element={<AddDevicesPage />} />
          <Route path="device/:id" element={<DeviceEditPage />} />
          <Route path="reset-password/:id" element={<PasswordChangePage />} />
          <Route path="two-factor-authentication" element={<TwoFactorAuthenticationChangePage />} />
          <Route path="delete-account" element={<DeleteAccountPage />} />
        </Route>
        <Route path="settings">
          <Route path="accumulators/:deviceId" element={<AccumulatorsPage />} />
          {/* <Route path="commands" element={<CommandsPage />} />
          <Route path="command/:id" element={<CommandPage />} />
          <Route path="command" element={<CommandPage />} /> */}
          {/* <Route path="attributes" element={<ComputedAttributesPage />} />
          <Route path="attribute/:id" element={<ComputedAttributePage />} />
          <Route path="attribute" element={<ComputedAttributePage />} /> */}
          <Route path="device/:id/command" element={<CommandDevicePage />} />
          <Route path="device/:id" element={<DevicePage />} />
          <Route path="device" element={<DevicePage />} />
          <Route path="driver/:id" element={<DriverPage />} />
          <Route path="driver" element={<DriverPage />} />
          {/* <Route path="geofence/:id" element={<GeofencePage />} /> */}
          {/* <Route path="geofence" element={<GeofencePage />} /> */}
          <Route path="group/:id/connections" element={<GroupConnectionsPage />} />
          <Route path="group/:id/command" element={<CommandGroupPage />} />
          <Route path="group/:id" element={<GroupPage />} />
          <Route path="group" element={<GroupPage />} />
          {/* <Route path="maintenances" element={<MaintenancesPage />} /> */}
          {/* <Route path="maintenance/:id" element={<MaintenancePage />} />
          <Route path="maintenance" element={<MaintenancePage />} /> */}
          {/* <Route path="notify" element={<NotifyPage />} /> */}
          <Route path="notifications" element={<NotificationsPage />} />
          {/* <Route path="notification/:id" element={<NotificationPage />} /> */}
          <Route path="notification" element={<NotificationPage />} />
          <Route path="preferences" element={<PreferencesPage />} />
          <Route path="support" element={<SupportPage />} />
          <Route path="user/:id/connections" element={<UserConnectionsPage />} />
          {/* <Route path="user/:id" element={<UserPage />} /> */}
          {/* <Route path="user" element={<UserPage />} /> */}
          <Route path="management">
            <Route element={<ProtectedRoute permissions={useSuperuser} />}>
              <Route path="server" element={<ServerPage />} />
            </Route>
            <Route element={<ProtectedRoute permissions={useAdministrator} />}>
              <Route path="users" element={<UsersPage />} />
              <Route path="groups" element={<GroupsPage />} />
              <Route path="devices" element={<ManagementDevicesPage />} />
              <Route path="news" element={<NewsAdminPage />} />
              <Route path="support" element={<ManagementSupportPage />} />
              <Route path="logs">
                <Route path="api-logs" element={<ApiLogPage />} />
                <Route path="device-logs" element={<DeviceLogPage />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="news" element={<NewsPage />} />

        <Route path="reports">
          {/* <Route path="combined" element={<CombinedReportPage />} /> */}
          <Route path="chart" element={<ChartReportPage />} />
          <Route path="event" element={<EventReportPage />} />
          <Route path="route" element={<RouteReportPage />} />
          <Route path="stop" element={<StopReportPage />} />
          {/* <Route path="summary" element={<SummaryReportPage />} /> */}
          {/* <Route path="trip" element={<TripReportPage />} /> */}
          {/* <Route path="scheduled" element={<ScheduledPage />} /> */}
          <Route path="statistics" element={<StatisticsPage />} />
          <Route path="login-history" element={<LoginHistoryPage />} />
        </Route>
      </Route>
      <Route path="not-found" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Navigation;
