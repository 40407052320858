import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import { useEffectAsync, useCatch } from '../reactHelper';
import DeviceFilter from './components/DeviceFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import AccountMenu from './components/AccountMenu';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import StyledTableHeadCell from '../common/components/StyledTableHeadCell';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { usePreference } from '../common/util/preferences';
import { useDeviceReadonly } from '../common/util/permissions';
import useReportStyles from '../reports/common/useReportStyles';
import { formatTime, truncateText } from '../common/util/formatter';

const DevicesPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const hours12 = usePreference('twelveHourFormat');

  const deviceReadonly = useDeviceReadonly();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [devicesShow, setDevicesShow] = useState([]);
  const [devicesWithGroup, setDevicesWithGroup] = useState([]);
  const [groupDevices, setGroupDevices] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/groups/all-registered-devices');
      if (response.ok) {
        const devices = await response.json();
        setDevicesWithGroup(devices);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const getRegistedDevices = await fetch('/api/devices');
      const getGroupDevices = await fetch('/api/groups/all-group-devices');
      if (getRegistedDevices.ok && getGroupDevices.ok) {
        const devices = await getRegistedDevices.json();
        const groupDevices = await getGroupDevices.json();
        const uniqueDevices = [...new Map(groupDevices.map((device) => [device.id, device])).values()];
        setGroupDevices(groupDevices);
        setAllDevices([...devices, ...uniqueDevices]);
        setDevicesShow([...devices, ...uniqueDevices]);
      } else {
        throw Error(await getRegistedDevices.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const handleSubmit = useCatch(async ({ deviceIds, groupIds }) => {
    setLoading(true);
    try {
      if (groupIds) {
        const filteredDevices = allDevices.filter((device) => device.groupId === groupIds);
        setDevicesShow(filteredDevices);
      }
      if (deviceIds) {
        const filteredDevices = allDevices.filter((device) => deviceIds.includes(device.id));
        setDevicesShow(filteredDevices);
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <div className={classes.containerMain}>
        <div className={classes.header}>
          <DeviceFilter allDevices={allDevices} devicesWithGroup={devicesWithGroup} groupDevices={groupDevices} handleSubmit={handleSubmit} multiDevice multiGroup includeGroups>
            {/* <ColumnSelect
              columns={displayColumns}
              setColumns={setColumns}
              // columnsObject={displayPositionAttributes}
              // columnsArray={available}
              rawValues
              disabled={!(displayColumns.length + 1)}
            /> */}
          </DeviceFilter>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>{t('deviceNameTitle')}</StyledTableHeadCell>
              <StyledTableHeadCell>{t('groupTitle')}</StyledTableHeadCell>
              <StyledTableHeadCell>{t('IMEI')}</StyledTableHeadCell>
              {/* <TableCell>{t('sharedPhone')}</TableCell>
              <TableCell>{t('deviceModel')}</TableCell>
              <TableCell>{t('deviceContact')}</TableCell> */}
              <StyledTableHeadCell>{t('userExpirationTime')}</StyledTableHeadCell>
              <StyledTableHeadCell className={classes.columnAction} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? devicesShow.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item.id}>
                <TableCell>{truncateText(item.name, 20)}</TableCell>
                <TableCell>
                  {devicesWithGroup.map((device) => {
                    if (device.id === item.id) {
                      return (
                        <React.Fragment key={device.groupId}>
                          {device.groupName}
                          <br />
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                  {groupDevices.map((device) => {
                    if (device.id === item.id) {
                      return (
                        <React.Fragment key={device.groupId}>
                          {device.groupName}
                          <br />
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </TableCell>
                <TableCell>{item.uniqueId}</TableCell>
                <TableCell>{formatTime(item.expirationTime, 'date', hours12)}</TableCell>
                <TableCell className={classes.columnAction} padding="none">
                  {item.hasOwnProperty('owner') ? null : (
                    <CollectionActions
                      itemId={item.id}
                      editPath="/accounts/device"
                      endpoint="devices"
                      setTimestamp={setTimestamp}
                      readonly={deviceReadonly}
                    />
                  )}
                </TableCell>
              </TableRow>
            )) : (<TableShimmer columns={4} endAction />)}
          </TableBody>
        </Table>
      </div>
      {/* <CollectionFab editPath="/settings/device" /> */}
    </PageLayout>
  );
};

export default DevicesPage;
