import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useCatch } from '../../reactHelper';

const useStyles = makeStyles({
  dialog: {
    backgroundColor: '#383838',
  },
  titleText: {
    color: '#FFFFFF',
  },
  contentText: {
    color: '#CECECE',
  },
  buttonColor: {
    color: '#2ECD77',
  },
});

const RemoveView = ({
  open, selectedIDs, setSelectedCount, setLoading, setSelectedAll, setShowLabel, setDevices, showItems, setShowItems, onClose, title, content,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const { id } = useParams();

  const createBody = (deviceId) => {
    const body = {};
    body.groupId = id;
    body.deviceId = deviceId;
    return body;
  };

  const handleRemove = useCatch(async () => {
    onClose();
    setLoading(true);
    const results = [];
    const url = '/api/permissions';
    selectedIDs.forEach(async (selectedID) => {
      results.push(fetch(url, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(createBody(selectedID)),
      }));
      await Promise.all(results);
    });

    try {
      selectedIDs.forEach(async (selectedID) => {
        showItems = showItems.filter((device) => device.id !== selectedID);
      });
      setDevices(showItems);
      setShowItems(showItems);
    } finally {
      setLoading(false);
      setShowLabel(false);
      setSelectedAll(false);
      setSelectedCount(0);
    }
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle className={classes.titleText}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={classes.buttonColor}>{t('quitCancel')}</Button>
        <Button onClick={handleRemove} className={classes.buttonColor}>{t('removeConfirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveView;
