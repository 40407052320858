import React, { createRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card, Tooltip, Typography,
} from '@mui/material';
import { usePreference } from '../../common/util/preferences';
import { formatTime } from '../../common/util/formatter';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'none',
    border: '1px solid',
    borderRadius: '1rem',
    borderColor: theme.palette.divider,
    padding: '1rem',
    width: theme.dimensions.newsCardWidth,
    height: (props) => (props.disableImagePreview ? 'inherit' : theme.dimensions.newsCardHeight),
    margin: '1rem',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    [theme.breakpoints.down('sm')]: {
      width: theme.dimensions.newsCardWidthPhone,
    },
  },
  title: {
    lineHeight: 1,
  },
  duration: {
    fontSize: '0.875rem',
    color: theme.palette.text.hint,
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: `calc(${theme.dimensions.newsCardHeight} - 5rem)`,
    },
  },
}));

const NewsCard = ({
  news, onClick, disableImagePreview,
}) => {
  const classes = useStyles({ disableImagePreview });
  const hours12 = usePreference('twelveHourFormat');
  const newsTitleRef = createRef();

  const handleOnClick = () => {
    if (onClick) {
      onClick(news.id);
    }
  };

  useEffect(() => {
    news.disableTooltip = newsTitleRef.current.scrollWidth <= newsTitleRef.current.clientWidth;
  }, []);

  return (
    <Card className={classes.card} onClick={handleOnClick}>
      <Tooltip title={news.title} disableHoverListener={news.disableTooltip}>
        <Typography className={classes.title} ref={newsTitleRef} noWrap>{news.title}</Typography>
      </Tooltip>
      {(news.startTime || news.endTime) && (
        <Typography className={classes.duration}>
          {formatTime(news.startTime, 'date', hours12)}
          ~
          {formatTime(news.endTime, 'date', hours12)}
        </Typography>
      )}
      {!disableImagePreview && (
        <div className={classes.image}>
          {news.imagePath && (
            <img src={news.imagePath} alt="News" />
          )}
        </div>
      )}
    </Card>
  );
};

export default NewsCard;
