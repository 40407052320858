import React from 'react';
import {
  Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader,
} from '@mui/material';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import makeStyles from '@mui/styles/makeStyles';
// import StorageIcon from '@mui/icons-material/Storage';
// import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useRestriction } from '../../common/util/permissions';
// import {
//   useAdministrator, useManager, useRestriction,
// } from '../../common/util/permissions';
// import useFeatures from '../../common/util/useFeatures';
import { nativePostMessage } from './NativeInterface';
import { sessionActions } from '../../store';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '0.375rem 1rem',
  },
  listSubheader: {
    padding: '0 1rem',
    margin: '0.25rem 0',
    fontSize: '0.95rem',
    fontWeight: 500,
    color: theme.palette.text.nav,
  },
}));

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const AccountMenu = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const readonly = useRestriction('readonly');
  //   const admin = useAdministrator();
  //   const manager = useManager();
  const userId = useSelector((state) => state.session.user.id);

  // const features = useFeatures();
  const user = useSelector((state) => state.session.user);
  const handleLogout = async () => {
    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken && !user.readonly) {
      window.localStorage.removeItem('notificationToken');
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: tokens.length > 1 ? tokens.filter((it) => it !== notificationToken).join(',') : undefined,
          },
        };
        await fetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }
    await fetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    dispatch(sessionActions.logoutUser());
    navigate('/login', { replace: true });
  };

  return (
    <>
      <List
        subheader={(
          <ListSubheader component="div" className={classes.listSubheader}>
            {t('groupTitle')}
          </ListSubheader>
        )}
      >
        <MenuItem
          title={t('groupMember')}
          link="/accounts/groups"
          icon={<GroupsIcon />}
          selected={location.pathname.startsWith('/accounts/group') ||
            location.pathname.startsWith('/accounts/member-manage') ||
            location.pathname.startsWith('/accounts/apply-geofence') ||
            location.pathname.startsWith('/accounts/share-devices')}
        />
      </List>
      {!readonly && (
        <>
          <List
            subheader={(
              <ListSubheader component="div" className={classes.listSubheader}>
                {t('accountTitle')}
              </ListSubheader>
            )}
          >
            <MenuItem
              variant="nav"
              title={t('basicInfo')}
              link={`/accounts/info/${userId}`}
              icon={<PersonIcon />}
              selected={location.pathname === `/accounts/info/${userId}` ||
                location.pathname === `/accounts/reset-password/${userId}` ||
                location.pathname === '/accounts/two-factor-authentication' ||
                location.pathname === '/accounts/delete-account'}
            />
            <MenuItem
              title={t('billManagement')}
              link="/accounts/bill"
              icon={<LocalAtmIcon />}
              selected={location.pathname.startsWith('/accounts/bill')}
            />
          </List>
          <List
            subheader={(
              <ListSubheader component="div" className={classes.listSubheader}>
                {t('manageResoueces')}
              </ListSubheader>
            )}
          >
            <MenuItem
              title={t('deviceTitle')}
              link="/accounts/devices"
              icon={<BikeScooterIcon />}
              selected={location.pathname.startsWith('/accounts/device')}
            />
            <MenuItem
              title={t('sharedGeofences')}
              link="/geofences"
              icon={<EditRoadIcon />}
              selected={location.pathname.startsWith('/geofence')}
            />
            <MenuItem
              title={t('sharedDrivers')}
              link="/accounts/drivers"
              icon={<AccountCircleIcon />}
              selected={location.pathname.startsWith('/accounts/drivers') ||
                location.pathname.startsWith('/accounts/edit-driver') ||
                location.pathname.startsWith('/accounts/add-driver')}
            />
            {/* {!features.disableDrivers && (
              <MenuItem
                title={t('sharedDrivers')}
                link="/accounts/drivers"
                icon={<PersonIcon />}
                selected={location.pathname.startsWith('/accounts/drivers')}
              />
            )} */}
          </List>
        </>
      )}
      <Divider className={classes.divider} />
      <List>
        <ListItemButton key="/accounts/logout" component={Link} onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary={t('loginLogout')} />
        </ListItemButton>
      </List>
    </>
  );
};

export default AccountMenu;
