import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  // const navigate = useNavigate();

  useEffect(() => {
    // setTimeout(() => {
    //   navigate('/');
    // }, 5000);
  });

  return (
    <> 404 Not Found </>
  );
};

export default NotFoundPage;
