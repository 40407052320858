import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'events',
  initialState: {
    items: [],
  },
  reducers: {
    refresh(state, action) {
      state.items = [...action.payload];
    },
    add(state, action) {
      state.items.unshift(...action.payload);
    },
    delete(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },
    deleteAll(state) {
      state.items = [];
    },
    filpIsProcessed(state, action) {
      const index = state.items.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.items[index].isProcessed = !state.items[index].isProcessed;
      }
    },
  },
});

export { actions as eventsActions };
export { reducer as eventsReducer };
