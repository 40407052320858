import React from 'react';
import { Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  policy: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: theme.palette.text.hint,
  },
  link: {
    color: theme.palette.text.link,
    textDecorationColor: theme.palette.text.link,
    '&:hover': {
      fontWeight: 'bold',
    },
  },
}));

const PolicyLink = ({
  children, link,
}) => {
  const classes = useStyles();

  return (
    <Link
      href={link}
      underline="hover"
      className={classes.link}
    >
      {children}
    </Link>
  );
};

const Policy = () => {
  const t = useTranslation();
  const classes = useStyles();

  const getPolicyLink = (anchor) => {
    // Get current language
    const { language } = useLocalization();
    const lang = language !== 'zhTW' ? 'en' : '';
    const languageParam = lang ? `?lang=${lang}` : '';
    return `/policy${languageParam}#${anchor}`;
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.policy}>
        {t('Continuing registration indicates agreement to ATrack\'s Privacy Policy and Terms of Use.', {
          privacyPolicy: (
            <PolicyLink link={getPolicyLink('privacy')}>
              {t('Privacy Policy')}
            </PolicyLink>
          ),
          termsOfUse: (
            <PolicyLink link={getPolicyLink('tos')}>
              {t('Terms of Use')}
            </PolicyLink>
          ),
        }, true)}
      </Typography>
    </div>
  );
};

export default Policy;
