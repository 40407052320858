import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRedirectResult } from 'firebase/auth';
import auth, { signInWithGoogleRedirect, signInWithAppleRedirect } from '../firebase/auth';
import { sessionActions, errorsActions } from '../store';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import useQuery from '../common/util/useQuery';
import LoginLayout from './LoginLayout';

const LoginRedirectPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const t = useTranslation();

  const [loadingMessage, setLoadingMessage] = useState(t('Login Redirecting...'));

  const wait = async (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

  const handleFirebaseLogin = async (user) => {
    if (user && user.accessToken) {
      const response = await fetch(`/api/session/firebase/auth?idToken=${user.accessToken}`);
      if (response.ok) {
        const user = await response.json();
        dispatch(sessionActions.loginUser(user));

        const next = query.get('next');
        if (next) {
          navigate(next);
        } else {
          setLoadingMessage(t('loginWelcomeWords'));
          await wait(2000);
          navigate('/');
        }
      } else {
        auth.signOut();
        const responseJson = await response.json();
        if (responseJson.message) {
          dispatch(errorsActions.push(t(responseJson.message)));
        } else {
          dispatch(errorsActions.push(t('Authentication error or you have bound other login methods')));
        }
        navigate('/login');
      }
    }
  };

  const handleSignInWithRedirect = async (provider) => {
    try {
      switch (provider) {
        case 'google':
          await signInWithGoogleRedirect();
          break;
        // case 'facebook':
        //   await signInWithFacebookRedirect();
        //   break;
        case 'apple':
          await signInWithAppleRedirect();
          break;
        default:
          navigate('/login');
      }
    } catch (error) {
      dispatch(errorsActions.push(t('Authentication error or you have bound other login methods')));
      navigate('/login');
    }
  };

  // for handle signInWithRedirect
  // for handle onAuthStateChanged error issue, see: https://github.com/firebase/firebase-js-sdk/issues/6254
  useEffectAsync(async () => {
    let fireBaseListener;
    try {
      await getRedirectResult(auth);
      fireBaseListener = auth.onAuthStateChanged((user) => {
        if (user) {
          handleFirebaseLogin(user);
        } else {
          const provider = new URLSearchParams(window.location.search).get('provider');
          handleSignInWithRedirect(provider);
        }
      });
    } catch (error) {
      dispatch(errorsActions.push(t('Authentication error or you have bound other login methods')));
      navigate('/login');
    }

    return () => {
      if (fireBaseListener) {
        fireBaseListener();
      }
    };
  }, []);

  return (
    <LoginLayout loadingMessage={loadingMessage} />
  );
};

export default LoginRedirectPage;
