import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles({
  dialog: {
    backgroundColor: '#383838',
  },
  titleText: {
    color: '#FFFFFF',
  },
  contentText: {
    color: '#CECECE',
  },
  buttonColor: {
    color: '#2ECD77',
  },
});

const DeleteView = ({
  open, memberId, onClose, setTimestamp, title, content,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const { id } = useParams();

  const handleDelete = () => {
    fetch(`/api/groups-members/member?groupId=${id}&userId=${memberId}&role=member`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    });
    setTimestamp(Date.now());
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle className={classes.titleText}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={classes.buttonColor}>{t('quitCancel')}</Button>
        <Button onClick={() => { handleDelete(); }} className={classes.buttonColor}>{t('deleteMember')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteView;
