import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Typography,
  FormGroup, Switch, ListItemText, ListItemIcon,
} from '@mui/material';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import makeStyles from '@mui/styles/makeStyles';
import AccountMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch, useEffectAsync } from '../reactHelper';
import { truncateText } from '../common/util/formatter';

const useStyles = makeStyles(() => ({
  switchContainer: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const GroupsApplyGeofencePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [geofences, setGeofences] = useState([]);
  const [groupGeofences, setGroupGeofences] = useState([]);
  const [group, setGroup] = useState({});
  const [applyAll, setApplyAll] = useState(false);

  useEffect(() => {
    // check if all geofences button are checked to trigger applyAll button
    let isAllGeofenceChecked = false;
    if (geofences.length === groupGeofences.length) {
      isAllGeofenceChecked = geofences.every((geofence) => groupGeofences.some((groupGeo) => (groupGeo.groupId === Number(id) && groupGeo.geofenceId === geofence.id)));
    }
    if (groupGeofences.length === 0) {
      isAllGeofenceChecked = false;
    }
    setApplyAll(isAllGeofenceChecked);
  }, [geofences, groupGeofences]);

  useEffectAsync(async () => {
    setLoading(true);
    const response = await fetch(`/api/groups/${id}`);
    try {
      if (response.ok) {
        const group = await response.json();
        setGroup(group);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/geofences');
      if (response.ok) {
        const data = await response.json();
        setGeofences(data);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/groups/groupGeofence?groupId=${id}`);
      if (response.ok) {
        setGroupGeofences(await response.json());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const onGroupItemSaved = useCatch(async () => {
    const response = await fetch(`/api/groups/groupGeofence?groupId=${id}`);
    if (response.ok) {
      const getGroupGeofences = await response.json();
      setGroupGeofences(getGroupGeofences);
    } else {
      throw Error(await response.text());
    }
  });

  const createBody = (geofenceId) => {
    const body = {};
    body.groupId = id;
    body.geofenceId = geofenceId;
    return body;
  };

  const handleAllApply = useCatch(async (event) => {
    const isChecked = event.target.checked;
    const results = [];
    const url = '/api/permissions';
    if (isChecked) {
      geofences.forEach(async (geofence) => {
        // check if geofence.id is not in groupGeofences
        if (!groupGeofences.some((groupGeo) => groupGeo.groupId === Number(id) && groupGeo.geofenceId === geofence.id)) {
          results.push(fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createBody(geofence.id)),
          }));
          await Promise.all(results);
          onGroupItemSaved();
        }
      });
    } else {
      geofences.forEach(async (geofence) => {
        // check if geofence.id is not in groupGeofences
        if (groupGeofences.some((groupGeo) => groupGeo.groupId === Number(id) && groupGeo.geofenceId === geofence.id)) {
          results.push(fetch(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createBody(geofence.id)),
          }));
          await Promise.all(results);
          onGroupItemSaved();
        }
      });
    }
    setApplyAll(isChecked);
  });

  const handleApply = useCatch(async (event, geofenceId) => {
    const isChecked = event.target.checked;
    const results = [];
    const url = '/api/permissions';
    results.push(fetch(url, {
      method: isChecked ? 'POST' : 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(createBody(geofenceId)),
    }));
    await Promise.all(results);
    onGroupItemSaved();
  });

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('groupAdminGeofenceShow')}
        >
          <Typography gutterBottom variant="h5" component="div">
            {group.name}
          </Typography>
          <Typography variant="body2">
            {t('groupAdminGeofenceMessage')}
          </Typography>
          <FormGroup>
            <div className={classes.switchContainer}>
              <ListItemText primary={t('groupAdminGeofenceApplyAll')} />
              <Switch
                checked={applyAll}
                onChange={(event) => {
                  setApplyAll(event.target.checked);
                  handleAllApply(event);
                }}
              />
            </div>
            {!loading ? geofences.map((item) => (
              <div className={classes.switchContainer} key={item.id}>
                <ListItemIcon>
                  <EditRoadIcon />
                </ListItemIcon>
                <ListItemText primary={truncateText(item.name, 20)} />
                <Switch
                  checked={groupGeofences.some((geofence) => geofence.groupId === Number(id) && geofence.geofenceId === item.id) || applyAll}
                  onChange={(event) => handleApply(event, item.id)}
                />
              </div>
            )) : null}
          </FormGroup>
        </CardSection>
      </CardLayout>
    </PageLayout>
  );
};

export default GroupsApplyGeofencePage;
