import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useCatch } from '../../reactHelper';

const useStyles = makeStyles({
  dialog: {
    backgroundColor: '#383838',
  },
  titleText: {
    color: '#FFFFFF',
  },
  contentText: {
    color: '#CECECE',
  },
  buttonColor: {
    color: '#2ECD77',
  },
});

const TransferAdminView = ({
  open, validate, newAdmin, onClose,
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  useEffect(() => {
    setShowErrorMsg(false);
  }, [open]);

  const handleAdminTransfer = useCatch(async () => {
    if (validate) {
      const response = await fetch(`/api/groups-members/admin?groupId=${id}&userId=${newAdmin.userId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(),
      });
      if (response.ok) {
        onClose();
      }
    }
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle className={classes.titleText}>{validate ? t('groupAdminTransferSuccessTitle') : t('groupAdminTransferFailedTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          { validate ? t('groupAdminTransferSuccessMessage', { newAdminName: newAdmin.fullName }) : t('groupAdminTransferFailedMessage') }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        { validate && (
          <Button
            onClick={() => {
              handleAdminTransfer();
              navigate(`/accounts/group/${id}`);
            }}
            className={classes.buttonColor}
          >
            {t('groupAdminTransferConfirm')}
          </Button>
        )}
        { !validate && <Button onClick={onClose} className={classes.buttonColor}>{t('groupAdminTransferReturn')}</Button>}
      </DialogActions>
      {showErrorMsg && <Alert severity="error">{t('deleteGroupErrorMessage')}</Alert>}
    </Dialog>
  );
};

export default TransferAdminView;
