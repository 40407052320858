import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Link as MuiLink, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { messagesActions } from '../store';
import { useCatch } from '../reactHelper';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import SupportTicketTypeSelect from './components/support/SupportTicketTypeSelect';
import SupportTicketDeviceSelect from './components/support/SupportTicketDeviceSelect';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.link,
    textDecorationColor: theme.palette.text.link,
    '&:hover': {
      fontWeight: 'bold',
    },
  },
}));

const Link = (props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <MuiLink
      {...props}
      underline="hover"
      className={classes.link}
    >
      {children}
    </MuiLink>
  );
};

const SupportPage = () => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const user = useSelector((state) => state.session.user);
  const [ticketDeviceId, setTicketDeviceId] = useState('');
  const [ticketType, setTicketType] = useState('');
  const [ticketContent, setTicketContent] = useState('');
  const offcialWebsite = process.env.REACT_APP_OFFCIAL_WEBSITE;
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

  const handleSubmit = useCatch(async () => {
    const response = await fetch('/api/support-tickets', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deviceId: ticketDeviceId,
        type: ticketType,
        content: ticketContent,
      }),
    });
    if (response.ok) {
      setTicketDeviceId('');
      setTicketType('');
      setTicketContent('');
      dispatch(messagesActions.push(t('supportServiceSubmitted')));
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle']}>
      <CardLayout>
        <CardSection
          header={t('supportService')}
          description={t('supportServiceDescription')}
          button={(
            <Button
              fullWidth
              variant="outlined"
              color="outlinedButtonGreen"
              onClick={handleSubmit}
              disabled={!(ticketDeviceId && ticketType && ticketContent)}
            >
              {t('supportServiceButtonSubmit')}
            </Button>
          )}
        >
          <TextField
            disabled
            variant="filled"
            label={t('userEmail')}
            value={user.email}
            helperText=" "
          />
          <SupportTicketDeviceSelect
            value={ticketDeviceId}
            onChange={(e) => setTicketDeviceId(e.target.value)}
          />
          <SupportTicketTypeSelect
            value={ticketType}
            onChange={(e) => setTicketType(e.target.value)}
          />
          <TextField
            multiline
            maxRows={4}
            variant="filled"
            label={t('sharedDescription')}
            value={ticketContent}
            onChange={(e) => setTicketContent(e.target.value)}
            helperText=" "
          />
        </CardSection>
        <CardSection
          header={t('aboutUs')}
          description={t('aboutUsDescription', {
            websiteUrl: <Link href={offcialWebsite} target="_blank">{offcialWebsite}</Link>,
            supportEmail: <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>,
          })}
        />
      </CardLayout>
    </PageLayout>
  );
};

export default SupportPage;
