import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { sessionActions } from '../store';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import AccountsMenu from './components/AccountMenu';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useCatch } from '../reactHelper';
import AlertDialog from '../common/components/AlertDialog';

const DeleteAccountPage = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleDeleteAccount = useCatch(async () => {
    setLoading(true);
    const response = await fetch(`/api/users/${user.id}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      setError(true);
    }
    setLoading(false);
    setIsModalOpen(true);
  });

  const handleClickAgree = async () => {
    setIsModalOpen(false);
    setError(false);
    if (!error) {
      dispatch(sessionActions.logoutUser());
      navigate('/login');
    }
  };

  return (
    <PageLayout
      menu={<AccountsMenu />}
      breadcrumbs={['settingsUser', 'basicInfo']}
      loading={loading}
    >
      <AlertDialog
        isOpen={isModalOpen}
        title={error ? t('userDeleteAccountDialogFailTitle') : t('userDeleteAccountDialogSuccessTitle')}
        content={error ? t('userDeleteAccountDialogFailContent') : t('userDeleteAccountDialogSuccessContent')}
        agreeBtnText={error ? t('modalButtonOk') : t('modalButtonAgree')}
        onAgree={handleClickAgree}
      />
      <CardLayout disableDivider>
        <CardSection
          navIcon={<ArrowBack />}
          header={t('userDeleteAccount')}
          description={(
            <>
              {t('userDeleteAccountDescription')}
              <ul>
                <li>{t('userDeleteAccountConfirmList1')}</li>
                <li>{t('userDeleteAccountConfirmList2')}</li>
                <li>{t('userDeleteAccountConfirmList3')}</li>
              </ul>
            </>
          )}
        />
        <CardSection
          header={t('userDeleteAccountReminder')}
          description={t('userDeleteAccountReminderDescription')}
          button={(
            <Button
              fullWidth
              variant="contained"
              color="containedButtonGreen"
              onClick={handleDeleteAccount}
              disabled={loading}
            >
              {t('userDeleteAccountButtonConfirm')}
            </Button>
          )}
        />
      </CardLayout>
    </PageLayout>
  );
};

export default DeleteAccountPage;
