import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  uniqueId: {
    name: t('groupIMEI'),
    type: 'string',
    property: true,
  },
  expirationTime: {
    name: t('userExpirationTime'),
    type: 'string',
    property: true,
  },
  owner: {
    name: t('groupOwner'),
    type: 'string',
    property: true,
  },
}), [t]);
