import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography, IconButton, Table, TableBody, TableHead, TableRow, TableCell, Checkbox, Box,
} from '@mui/material';
import { NavigateBefore } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import PageHeader from '../common/components/PageHeader';
import GroupFilter from './components/GroupFilter';
import ColumnSelect from './components/ColumnSelect';
import { useTranslation } from '../common/components/LocalizationProvider';
import useGroupStyles from './common/useGroupStyles';
import usePersistedState from '../common/util/usePersistedState';
import useGroupMemberAttributes from '../common/attributes/useGroupMemberAttributes';
import StyledTableHeadCell from '../common/components/StyledTableHeadCell';
import QuitView from './components/QuitView';
import { useCatch, useEffectAsync } from '../reactHelper';
import RemoveView from './components/RemoveView';

const GroupPage = () => {
  const classes = useGroupStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.session.user);
  const groupSeletedColumns = useSelector((state) => state.groups.groupSeletedColumns);

  const [role, setRole] = useState('');
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quitView, setQuitView] = useState(false);
  const [removeView, setRemoveView] = useState(false);
  const [group, setGroup] = useState({});
  const [showItems, setShowItems] = useState([]);
  const [devices, setDevices] = useState([]);
  const [showLabel, setShowLabel] = useState(false);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  const groupAttributes = useGroupMemberAttributes(t);
  const groupColumns = Object.keys(groupAttributes);
  const displayGroupAttributes = groupColumns
    .reduce((obj, key) => Object.assign(obj, { [key]: groupAttributes[key] }), {});

  const defaultSelectedPositionColumns = ['deviceName', 'uniqueId', 'expirationTime', 'owner'];
  const [columns, setColumns] = usePersistedState('groupColumns', defaultSelectedPositionColumns);
  let displayColumns = columns.filter((column) => groupColumns.indexOf(column) > -1);
  const { id } = useParams();

  useEffect(() => {
    displayColumns = groupColumns.filter((column) => groupSeletedColumns.includes(column));
  }, []);

  useEffect(() => {
    setColumns(displayColumns);
    if (selectedCount > 0) {
      setShowLabel(true);
    } else {
      setShowLabel(false);
    }
  }, [selectedCount]);

  // set group item
  useEffectAsync(async () => {
    setLoading(true);
    const response = await fetch(`/api/groups/${id}`);
    try {
      if (response.ok) {
        const group = await response.json();
        setGroup(group);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  // set devices item
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/groups/single-group-devices?groupId=${id}`);
      if (response.ok) {
        const devices = await response.json();
        setDevices(devices);
        setShowItems(devices);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  // get the group role of this account
  useEffectAsync(async () => {
    const getMembers = await fetch(`/api/groups-members/members?groupId=${id}`);
    try {
      if (getMembers.ok) {
        const GroupMembers = await getMembers.json();
        const members = GroupMembers.filter((member) => member.role === 'member');
        setMembers(members);
        const thisRole = GroupMembers.filter((member) => member.userId === currentUser.id)[0].role;
        setRole(thisRole);
      } else {
        throw Error(await getMembers.text());
      }
    } catch (error) {
      throw Error(error);
    }
  }, []);

  const handleDelete = (event, id) => {
    setSelectedAll(false);
    if (event.target.checked) {
      setSelectedCount(selectedCount + 1);
      setSelectedID([...selectedID, id]);
    } else {
      setSelectedCount(selectedCount - 1);
      setSelectedID(selectedID.filter((item) => item !== id));
    }
  };

  const handleAllDelete = (event) => {
    setSelectedAll(event.target.checked);
    if (event.target.checked) {
      setSelectedCount(devices.length);
      setSelectedID(devices.map((item) => item.id));
    } else {
      setSelectedCount(0);
      setSelectedID([]);
    }
  };

  const handleView = useCatch(async ({ deviceIds }) => {
    try {
      setLoading(true);
      if (role === 'admin') {
        const response = await fetch(`/api/groups/single-group-devices?groupId=${id}`);
        if (response.ok) {
          const registedDevices = await response.json();
          const filteredDevices = registedDevices.filter((device) => deviceIds.includes(device.id));
          setShowItems(filteredDevices);
        } else {
          throw Error(await response.text());
        }
      }
      if (role === 'member') {
        const filteredDevices = devices.filter((device) => deviceIds.includes(device.id));
        setShowItems(filteredDevices);
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <PageLayout
      menu={<AccountMenu />}
      breadcrumbs={['settingsUser', 'groupMember']}
    >
      <PageHeader bottomDivider navIcon={<NavigateBefore />} title={group.name} navIconOnClick={() => navigate('/accounts/groups')}>
        { role === 'admin' && (
          <>
            <IconButton size="small" onClick={() => navigate(`/accounts/edit-group/${id}`)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={() => navigate(`/accounts/group/${id}/notifications`)}>
              <SettingsIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={() => setQuitView(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </>
        )}
        { role === 'member' && (
          <IconButton size="small" onClick={() => setQuitView(true)}>
            <LogoutIcon fontSize="small" />
          </IconButton>
        )}
        <QuitView open={quitView} role={role} members={members} devices={showItems} onClose={() => setQuitView(false)} />
      </PageHeader>
      <div className={classes.header}>
        <GroupFilter handleView={handleView} devices={devices} role={role} multiDevice>
          <ColumnSelect
            // columns={displayColumns}
            pageId={id}
            setColumns={setColumns}
            columnsObject={displayGroupAttributes}
            rawValues
            disabled={!(displayColumns.length + 1)}
          />
        </GroupFilter>
      </div>
      {showLabel ? (
        <div>
          <Box sx={{ bgcolor: '#FF002E1A', display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ padding: '1rem 0 1rem 1.5rem' }} variant="h6">
              {selectedCount > 0 ? `${selectedCount} ${t('groupDeviceSelected')} ` : null}
            </Typography>
            <div style={{ paddingRight: '3rem', paddingTop: '1rem' }}>
              <IconButton onClick={() => setRemoveView(true)}>
                <DeleteIcon />
              </IconButton>
              <RemoveView open={removeView} selectedIDs={selectedID} setSelectedCount={setSelectedCount} setLoading={setLoading} setSelectedAll={setSelectedAll} setShowLabel={setShowLabel} setDevices={setDevices} showItems={showItems} setShowItems={setShowItems} onClose={() => setRemoveView(false)} title={t('checkRemoveShareMessage')} content={t('showRemoveShareMessage')} />
            </div>
          </Box>
        </div>
      ) : (
        <div style={{ padding: '1rem 0 1rem 1.5rem' }}>
          <Typography variant="h5">{t('groupDeviceListLabel')}</Typography>
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell className={classes.columnAction}>
              { role === 'admin' && (
                <Checkbox
                  key="selectAll"
                  checked={selectedAll}
                  onChange={(event) => { handleAllDelete(event); }}
                />
              )}
            </StyledTableHeadCell>
            <StyledTableHeadCell>{t('groupDeviceNameTitle')}</StyledTableHeadCell>
            {displayColumns.map((key) => (<StyledTableHeadCell key={key}>{displayGroupAttributes[key].name}</StyledTableHeadCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? showItems.map((device) => (
            <TableRow key={device.id}>
              <TableCell className={classes.columnAction}>
                { role === 'admin' && (
                  <Checkbox
                    key={device.id}
                    checked={selectedID.indexOf(device.id) > -1}
                    onChange={(event) => { handleDelete(event, device.id); }}
                  />
                )}
              </TableCell>
              <TableCell>{device.name}</TableCell>
              {displayColumns.map((key) => (
                <TableCell key={key}>{device[key]}</TableCell>
              ))}
            </TableRow>
          )) : null}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default GroupPage;
