import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import PersonIcon from '@mui/icons-material/Person';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useGroupStyles from '../common/useGroupStyles';
import { devicesActions, reportsActions } from '../../store';

const GroupFilter = ({
  children, handleView, devices, role, ignoreDevice, multiDevice,
}) => {
  const classes = useGroupStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [allDevices, setAllDevices] = useState([]);
  const [controlAll, setControlAll] = useState(false);

  const deviceId = useSelector((state) => state.devices.selectedId);
  const deviceIds = useSelector((state) => state.devices.selectedIds);
  const period = useSelector((state) => state.reports.period);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);

  useEffect(() => {
    if (devices.length > 0) {
      const ids = devices.map((device) => device.id);
      setAllDevices(ids);
      dispatch(devicesActions.selectIds(ids)); // initial select all devices
    }
  }, [devices]);

  const handleClick = () => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case 'today':
        selectedFrom = moment().startOf('day');
        selectedTo = moment().endOf('day');
        break;
      case 'yesterday':
        selectedFrom = moment().subtract(1, 'day').startOf('day');
        selectedTo = moment().subtract(1, 'day').endOf('day');
        break;
      case 'thisWeek':
        selectedFrom = moment().startOf('week');
        selectedTo = moment().endOf('week');
        break;
      case 'previousWeek':
        selectedFrom = moment().subtract(1, 'week').startOf('week');
        selectedTo = moment().subtract(1, 'week').endOf('week');
        break;
      case 'thisMonth':
        selectedFrom = moment().startOf('month');
        selectedTo = moment().endOf('month');
        break;
      case 'previousMonth':
        selectedFrom = moment().subtract(1, 'month').startOf('month');
        selectedTo = moment().subtract(1, 'month').endOf('month');
        break;
      default:
        selectedFrom = moment(from, moment.HTML5_FMT.DATETIME_LOCAL);
        selectedTo = moment(to, moment.HTML5_FMT.DATETIME_LOCAL);
        break;
    }

    handleView({
      deviceId,
      deviceIds,
      from: selectedFrom.toISOString(),
      to: selectedTo.toISOString(),
    });
  };

  const handleDevices = (e) => {
    if (e.target.value.includes('all')) {
      if (controlAll) {
        dispatch(devicesActions.selectIds(allDevices));
      } else {
        dispatch(devicesActions.selectIds([]));
      }
    } else {
      dispatch(multiDevice ? devicesActions.selectIds(e.target.value) : devicesActions.selectId(e.target.value));
    }
  };

  return (
    <>
      <div className={classes.filter}>
        {!ignoreDevice && (
          <div className={classes.filterItem}>
            <FormControl fullWidth>
              <InputLabel className={classes.selectLabel}>{t(multiDevice ? 'deviceTitle' : 'reportDevice')}</InputLabel>
              <Select
                variant="filled"
                label={t(multiDevice ? 'deviceTitle' : 'reportDevice')}
                value={multiDevice ? deviceIds : deviceId || ''}
                onChange={handleDevices}
                multiple={multiDevice}
              >
                <MenuItem value="all" onClick={() => { setControlAll(!controlAll); }}>{t('deviceSelectAll')}</MenuItem>
                {devices.map((device) => (
                  <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {/* {includeGroups && (
          <div className={classes.filterItem}>
            <FormControl fullWidth>
              <InputLabel className={classes.selectLabel}>{t('settingsGroups')}</InputLabel>
              <Select
                variant="filled"
                label={t('settingsGroups')}
                value={groupIds}
                onChange={(e) => dispatch(reportsActions.updateGroupIds(e.target.value))}
                multiple
              >
                {Object.values(groups).sort((a, b) => a.name.localeCompare(b.name)).map((group) => (
                  <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )} */}
        <div className={classes.filterItem}>
          <FormControl fullWidth>
            <InputLabel className={classes.selectLabel}>{t('reportPeriod')}</InputLabel>
            <Select variant="filled" label={t('reportPeriod')} value={period} onChange={(e) => dispatch(reportsActions.updatePeriod(e.target.value))}>
              <MenuItem value="today">{t('reportToday')}</MenuItem>
              <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
              <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
              <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
              <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
              <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
              <MenuItem value="custom">{t('reportCustom')}</MenuItem>
            </Select>
          </FormControl>
        </div>
        {period === 'custom' && (
          <div className={classes.filterItem}>
            <TextField
              className={classes.datetimeField}
              variant="filled"
              label={t('reportFrom')}
              type="datetime-local"
              value={from}
              onChange={(e) => dispatch(reportsActions.updateFrom(e.target.value))}
              fullWidth
            />
          </div>
        )}
        {period === 'custom' && (
          <div className={classes.filterItem}>
            <TextField
              className={classes.datetimeField}
              variant="filled"
              label={t('reportTo')}
              type="datetime-local"
              value={to}
              onChange={(e) => dispatch(reportsActions.updateTo(e.target.value))}
              fullWidth
            />
          </div>
        )}
        {children}
      </div>
      <div className={classes.setting}>
        <div className={classes.settingItem}>
          <Button
            fullWidth
            variant="outlined"
            color="outlinedButtonGreen"
            disabled={devices.length < 1}
            onClick={() => handleClick()}
          >
            <Typography variant="button" noWrap sx={{ textTransform: 'none' }}>{t('groupShow')}</Typography>
          </Button>
        </div>
        {role === 'admin' && (
          <>
            <div className={classes.settingItem}>
              <Button
                fullWidth
                variant="outlined"
                color="outlinedButtonGreen"
                startIcon={<BikeScooterIcon />}
                onClick={() => navigate(`/accounts/share-devices/${id}`)}
              >
                <Typography variant="button" noWrap sx={{ textTransform: 'none' }}>{t('groupShareDeviceShow')}</Typography>
              </Button>
            </div>
            <div className={classes.settingItem}>
              <Button
                fullWidth
                variant="outlined"
                color="outlinedButtonGreen"
                startIcon={<PersonIcon />}
                onClick={() => navigate(`/accounts/member-manage/${id}`)}
              >
                <Typography variant="button" noWrap sx={{ textTransform: 'none' }}>{t('groupMemberManageShow')}</Typography>
              </Button>
            </div>
            <div className={classes.settingItem}>
              <Button
                fullWidth
                variant="outlined"
                color="outlinedButtonGreen"
                startIcon={<EditRoadIcon />}
                onClick={() => navigate(`/accounts/apply-geofence/${id}`)}
              >
                <Typography variant="button" noWrap sx={{ textTransform: 'none' }}>{t('groupAdminGeofenceShow')}</Typography>
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GroupFilter;
