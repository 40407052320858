import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  accordion: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  },
  summary: {
    padding: theme.spacing(0, 3),
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  summaryText: {
    fontSize: '1.375rem',
  },
  details: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(2)}`,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const CardAccordion = ({ summary, summaryRight, children, detailSx, searching = false }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(searching);
  }, [searching]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion disableGutters elevation={0} square className={classes.accordion} expanded={isExpanded} onChange={handleExpand}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.summary}
      >
        <Typography className={classes.summaryText}>{summary}</Typography>
        <div>{summaryRight}</div>
      </AccordionSummary>
      <AccordionDetails className={classes.details} sx={detailSx}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CardAccordion;
