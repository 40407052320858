import { grey } from '@mui/material/colors';
import palette from './palette';

export default {
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: grey[50],
      },
    },
  },
  // https://mui.com/material-ui/customization/theme-components/#theme-style-overrides
  // custom variants support list: https://github.com/mui/material-ui/issues/21749
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        height: '3.5rem',
        boxShadow: 'none',
        fontSize: '1.125rem',
        borderRadius: '0.5rem',
        fontWeight: 400,
        textTransform: 'none',
        '&:hover, :active, :focus': {
          boxShadow: 'none',
        },
        ...((ownerState.variant === 'contained') && {
          border: 'none',
          ...(ownerState.color === 'containedButtonGreen' && {
            '&:disabled': {
              color: palette().text.primary,
              backgroundColor: palette().containedButtonGreen.main,
              opacity: 0.5,
            },
            '&:focus': {
              backgroundColor: palette().containedButtonGreen.dark,
            },
          }),
          ...(ownerState.color === 'containedButtonGrey' && {
            '&:disabled': {
              backgroundColor: palette().containedButtonGrey.dark,
              opacity: 0.5,
            },
            '&:focus': {
              backgroundColor: palette().containedButtonGrey.dark,
            },
          }),
        }),
        ...((ownerState.variant === 'outlined') && {
          border: '2px solid',
          ...(ownerState.color === 'outlinedButtonGreen' && {
            borderColor: palette().colors.primary,
            '&:hover, :active, :focus': {
              border: '2px solid',
              borderColor: palette().colors.secondary,
              backgroundColor: palette().outlinedButtonGreen.hover,
            },
            '&:disabled': {
              border: '2px solid',
              borderColor: palette().outlinedButtonGreen.disable,
              color: palette().text.hint,
              opacity: 0.5,
            },
          }),
        }),
        ...((ownerState.variant === 'text') && {
          fontSize: '1rem',
          color: palette().text.primary,
          fontWeight: 400,
          '&:hover, :active': {
            backgroundColor: palette().background.default,
            textDecoration: 'underline',
            fontWeight: 700,
          },
          '&:disabled': {
            textDecoration: 'underline',
            fontWeight: 700,
            opacity: 0.5,
          },
        }),
        '.MuiButton-startIcon': {
          color: palette().text.primary,
        },
      }),
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: palette().text.primary,
        lineHeight: '1rem',
        '&.MuiInputLabel-shrink:not(.Mui-disabled, .Mui-error)': {
          color: palette().colors.primary,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        '& .MuiFilledInput-input': {
          paddingBottom: '0.75rem',
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: palette().background.default,
        '&:hover': {
          backgroundColor: palette().background.default,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: palette().background.default,
          },
        },
        '&.Mui-focused': {
          backgroundColor: palette().background.default,
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottomColor: palette().primary.main,
          borderBottomWidth: '2px',
        },
        '&.Mui-disabled': {
          backgroundColor: palette().background.default,
          '&:before': {
            borderBottomStyle: 'solid',
          },
        },
      },
      input: {
        '&:focus': {
          backgroundColor: palette().background.default,
        },
        '&::-ms-reveal': {
          display: 'none', // disable Edge password reveal
        },
      },
      underline: {
        '&:before': {
          borderBottomWidth: '2px',
        },
        '&.Mui-disabled:before': {
          borderBottomColor: palette().text.disable,
        },
        '&:not(.Mui-disabled, .Mui-error):before': {
          borderBottomColor: palette().primary.main,
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        color: palette().text.primary,
        ...((ownerState.variant === 'grey') && {
          color: palette().text.hint,
        }),
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: palette().colors.menu.icon,
      },
    },
  },
  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '6.25rem',
        '&:hover': {
          backgroundColor: palette().colors.menu.inactive.hover,
        },
        '&.Mui-selected': {
          backgroundColor: palette().colors.menu.active.background,
          '&:hover': {
            backgroundColor: palette().colors.menu.active.hover,
          },
        },
        '.MuiListItemIcon-root': {
          color: palette().colors.menu.icon,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        // color: '',
      },
      colorPrimary: {
        '&.Mui-checked': {
          // Controls checked color for the thumb
          color: palette().colors.switch.active,
        },
        '&.Mui-checked.Mui-disabled': {
          color: palette().colors.switch.activeDisabled,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        // opacity: 0.2,
        // backgroundColor: '',
        '.Mui-checked.Mui-checked + &': {
          // Controls checked color for the track
          opacity: 0.5,
          backgroundColor: palette().colors.switch.active,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        fontWeight: 500,
        fontSize: '0.875rem',
        border: '1px solid',
        ...((ownerState.variant === 'transparent') && {
          border: 'none',
          backgroundColor: 'transparent',
          color: palette().text.hint,
          '&:hover': {
            backgroundColor: palette().background.default,
          },
        }),
        ...((ownerState.variant !== 'outlined') && {
          border: 'none',
        }),
      }),
    },
  },
  MuiBottomNavigationAction: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        fontWeight: 500,
        color: palette().text.primary,
        '& .MuiSvgIcon-root': {
          borderRadius: '6.25rem',
          width: '4rem',
          height: '2rem',
          margin: '0.25rem 0',
          borderTop: '0.25rem solid',
          borderBottom: '0.25rem solid',
          borderColor: 'transparent',
        },
        '&:hover': {
          '& .MuiSvgIcon-root': {
            backgroundColor: palette().colors.menu.inactive.hover,
          },
        },
        '&.Mui-selected': {
          color: palette().text.primary,
          '& .MuiSvgIcon-root': {
            backgroundColor: palette().colors.menu.active.background,
          },
          '&:hover': {
            '& .MuiSvgIcon-root': {
              backgroundColor: palette().colors.menu.active.hover,
            },
          },
        },
      },
    },
  },
};
