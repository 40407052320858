import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, TextField,
  Button,
  Alert,
  Backdrop, CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Unstable_Grid2';
import { useCatch, useEffectAsync } from '../reactHelper';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import AccountsMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    flexShrink: 0,
    marginTop: '5px',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '100%',
    },
  },
  inputIMEI: {
    marginTop: theme.spacing(3),
    display: 'flex',
    '& > *': {
      flexBasis: '100%',
    },
  },
}));

const AddDevicesPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const navigate = useNavigate();

  const [item, setItem] = useState({});
  const [fail, setFail] = useState(false);
  const [deviceNameCheck, setDeviceNameCheck] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [progress, setProgress] = useState(false);

  const validate = () => Object.keys(item).length !== 0 && item.name && item.uniqueId && isVerify && !fail;

  useEffectAsync(async () => {
    if (!item || Object.keys(item).length === 0) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setItem(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [item]);

  const checkInputText = (input) => {
    if (input.length < 1 || input.length > 40) {
      setDeviceNameCheck(t('inputTextLengthLimitType', { minLength: 1, maxLength: 40 }));
      return true;
    }
    return false;
  };

  const normalizeInputIMEI = () => {
    let regexIMEI = '';
    if (typeof item.uniqueId === 'string') {
      regexIMEI = item.uniqueId.trim(); // trim function only can use in string type
    }
    setItem({ ...item, uniqueId: regexIMEI }); // set normalized IMEI to the uniqueId of item
    return regexIMEI;
  };

  const handleRegister = useCatch(async () => {
    const isFail = checkInputText(item.name);
    if (!isFail) {
      const url = '/api/devices';

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
      });

      if (response.ok) {
        navigate(-1);
      } else if (!response.ok && response.status === 400) {
        setFail(true);
        setErrMsg(t('imeiIsRegisted', { IMEI: item.uniqueId }));
      } else {
        throw Error(await response.text());
      }
    }
  });

  const handleVerify = async () => {
    const regexIMEI = normalizeInputIMEI(item.uniqueId);
    setProgress(true);
    const res = await fetch(`https://asia-east1-atrack-care.cloudfunctions.net/atrack-dev-adm?imei=${regexIMEI}`, {
      method: 'GET',
      'Access-Control-Allow-Origin': '*',
      withCredentials: true,
      crossorigin: true,
      // mode: 'no-cors',
    });
    if (res.ok) {
      setIsVerify(true);
      setErrMsg(t('imeiVerifySuccess'));
      setFail(false);
    } else if (!res.ok && res.status === 400) {
      setErrMsg(t('imeiFormatError'));
      setFail(true);
    } else if (!res.ok && res.status === 404) {
      setErrMsg(t('imeiNotExist', { IMEI: item.uniqueId }));
      setFail(true);
    } else {
      res.text().then((text) => { setErrMsg(text); }); // API Key authentication failure
      setFail(true);
    }
    setProgress(false);
  };

  return (
    <PageLayout menu={<AccountsMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('addDeviceTitle')}
          button={(
            <Button
              fullWidth
              variant="outlined"
              color="outlinedButtonGreen"
              onClick={handleRegister}
              disabled={!validate() || deviceNameCheck !== ''}
            >
              {t('deviceRegister')}
            </Button>
          )}
        >
          <TextField
            error={deviceNameCheck !== ''}
            color="success"
            variant="filled"
            value={item?.name || ''}
            onChange={(event) => {
              setItem({ ...item, name: event.target.value });
              setDeviceNameCheck('');
            }}
            helperText={deviceNameCheck !== '' ? deviceNameCheck : ''}
            label={t('deviceNameTitle')}
          />
          <Grid container spacing={2} className={classes.gridItem}>
            <Grid xs={9}>
              <div className={classes.inputIMEI}>
                <TextField
                  error={fail}
                  color="success"
                  variant="filled"
                  value={item?.uniqueId || ''}
                  onChange={(event) => {
                    setItem({ ...item, uniqueId: event.target.value });
                    setIsVerify(false);
                  }}
                  label={t('imeiTitle')}
                />
              </div>
              <div>
                {fail && <Alert variant="outlined" severity="error" sx={{ marginTop: '10px' }}>{errMsg}</Alert>}
                {!fail && isVerify && <Alert variant="outlined" severity="info" sx={{ marginTop: '10px' }}>{errMsg}</Alert>}
              </div>
            </Grid>
            <Grid xs={3} sx={{ display: 'flex', marginTop: '15px', marginBottom: '30px' }}>
              <Button
                fullWidth
                type="button"
                variant="outlined"
                color="outlinedButtonGreen"
                onClick={handleVerify}
              >
                <Typography variant="button" sx={{ fontSize: 16 }}>{t('verify')}</Typography>
              </Button>
            </Grid>
          </Grid>
        </CardSection>
      </CardLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={progress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageLayout>
  );
};

export default AddDevicesPage;
