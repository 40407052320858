import React from 'react';
import {
  TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.colors.tableHeader,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
  },
}));

const StyledTableHeadCell = (props) => {
  const { children } = props;
  return (
    <StyledTableCell {...props}>
      {children}
    </StyledTableCell>
  );
};

export default StyledTableHeadCell;
