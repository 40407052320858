import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Typography, InputAdornment, IconButton, TextField, Button, Snackbar,
} from '@mui/material';
import { ArrowBack, Visibility, VisibilityOff } from '@mui/icons-material';
import { getMultiFactorResolver } from 'firebase/auth';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import AccountsMenu from './components/AccountMenu';
import { snackBarDurationShortMs } from '../common/util/duration';
import useQuery from '../common/util/useQuery';
// import { sessionActions } from '../store';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import auth, {
  AUTH_PASSWORD_MAX_LENGTH, AUTH_PASSWORD_MIN_LENGTH, changePasswordWithCredential, changeUserPassword, getCredentialWithPassword,
} from '../firebase/auth';
import { useCatch } from '../reactHelper';
import PhoneFactorAuthSection from './components/twoFactorAuthentication/PhoneFactorAuthSection';

const PasswordChangePage = () => {
  const navigate = useNavigate();
  const t = useTranslation();

  const currentUser = useSelector((state) => state.session.user);
  const query = useQuery();
  const [item, setItem] = useState(currentUser);
  const [queryHandled, setQueryHandled] = useState(false);
  const attribute = query.get('attribute');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);

  // 2FA required
  const [multiFactorResolver, setMultiFactorResolver] = useState();
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCodeResent, setVerificationCodeResent] = useState(false);

  const [snackBarMessage, setSnackBarMessage] = useState('');

  const handleClickOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    setOldPasswordError(false);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setNewPasswordError(false);
    setConfirmNewPasswordError(false);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmNewPasswordError(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleSubmit = useCatch(async (event) => {
  //   event.preventDefault();
  //   const url = '/api/users/1';
  //   const response = await fetch(url, {
  //     method: 'PUT',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: new URLSearchParams(`password=${encodeURIComponent(confirmPassword)}`),
  //   });
  //   if (response.ok) {
  //     setSnackbarOpen(true);
  //   } else {
  //     throw Error(await response.text());
  //   }
  // });

  const onVerificationCodeVerifySuccess = async () => {
    try {
      await changeUserPassword(newPassword);
      setSnackBarMessage(t('shareSaved'));
    } catch (error) {
      if (error.code === 'auth/weak-password' || error.code === 'auth/password-does-not-meet-requirements') {
        setIsVerifying(false);
        setNewPasswordError(true);
        setConfirmPassword('');
      } else {
        throw new Error();
      }
    }
  };

  const onVerificationCodeResendSuccess = () => {
    setVerificationCodeResent(true);
  };

  const handleSubmit = useCatch(async (event) => {
    event.preventDefault();
    try {
      const credential = getCredentialWithPassword(item.email, oldPassword);
      if (newPassword === confirmPassword) {
        await changePasswordWithCredential(credential, newPassword);
        setSnackBarMessage(t('shareSaved'));
      } else {
        setConfirmNewPasswordError(true);
        setConfirmPassword('');
      }
    } catch (error) {
      if (error.code === 'auth/wrong-password' || error.code === 'auth/invalid-credential' || error.code === 'auth/invalid-login-credentials') {
        setOldPasswordError(true);
        setOldPassword('');
      } else if (error.code === 'auth/weak-password' || error.code === 'auth/password-does-not-meet-requirements') {
        // firebase-emulator thorws 'auth/weak-password' when password is less than 6 characters
        // firebase with identity platform throws 'auth/password-does-not-meet-requirements'
        setNewPasswordError(true);
        setConfirmPassword('');
      } else if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, error);
        setMultiFactorResolver(resolver);
        setIsVerifying(true);
      } else {
        throw new Error();
      }
    }
  });

  useEffect(() => {
    setSnackBarMessage(verificationCodeResent ? t('twoFactorAuthenticationMessagesSent') : '');
  }, [verificationCodeResent]);

  useEffect(() => {
    if (multiFactorResolver) {
      setIsVerifying(true);
    }
  }, [multiFactorResolver]);

  useEffect(() => {
    if (!queryHandled && item && attribute) {
      if (!item.attributes.hasOwnProperty('attribute')) {
        const updatedAttributes = { ...item.attributes };
        updatedAttributes[attribute] = '';
        setItem({ ...item, attributes: updatedAttributes });
      }
      setQueryHandled(true);
    }
  }, [item, queryHandled, setQueryHandled, attribute]);

  return (
    <PageLayout
      menu={<AccountsMenu />}
      breadcrumbs={['settingsUser', 'basicInfo']}
    >
      <CardLayout>
        {!isVerifying ? (
          <CardSection
            navIcon={<ArrowBack />}
            header={(
              <Typography sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
                {t('changePassword')}
              </Typography>
            )}
            description={t('changePasswordHint', { minLength: AUTH_PASSWORD_MIN_LENGTH, maxLength: AUTH_PASSWORD_MAX_LENGTH })}
            button={(
              <Button
                fullWidth
                variant="outlined"
                color="outlinedButtonGreen"
                disabled={!oldPassword || !newPassword || !confirmPassword || (newPassword !== confirmPassword)}
                onClick={handleSubmit}
              >
                {t('sharedSave')}
              </Button>
            )}
          >
            <TextField
              fullWidth
              label={t('oldPassword')}
              variant="filled"
              autoFocus
              type={showOldPassword ? 'text' : 'password'}
              onChange={handleOldPasswordChange}
              error={oldPasswordError}
              helperText={oldPasswordError ? t('wrong password') : ' '}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      tabIndex={-1}
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label={t('newPassword')}
              variant="filled"
              type={showNewPassword ? 'text' : 'password'}
              onChange={handleNewPasswordChange}
              error={newPasswordError}
              helperText={newPasswordError ? t('userPasswordPolicyHelperText', { minLength: AUTH_PASSWORD_MIN_LENGTH, maxLength: AUTH_PASSWORD_MAX_LENGTH }) : ' '}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      tabIndex={-1}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label={t('confirmNewPassword')}
              variant="filled"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={confirmNewPasswordError}
              helperText={confirmNewPasswordError ? t('wrong password') : ' '}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      tabIndex={-1}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </CardSection>
        ) : (
          <PhoneFactorAuthSection
            title={t('changePassword')}
            multiFactorResolver={multiFactorResolver}
            onVerificationCodeVerifySuccess={onVerificationCodeVerifySuccess}
            onVerificationCodeResendSuccess={onVerificationCodeResendSuccess}
          />
        )}
      </CardLayout>
      <Snackbar
        open={!!snackBarMessage}
        onClose={() => {
          setSnackBarMessage('');
          if (verificationCodeResent) {
            setVerificationCodeResent(false);
          } else {
            navigate(-1);
          }
        }}
        autoHideDuration={snackBarDurationShortMs}
        message={snackBarMessage}
      />
    </PageLayout>
  );
};

export default PasswordChangePage;
