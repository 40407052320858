import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  CardContent, FormControl, IconButton, Typography,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: '1rem 0',
  },
  header: {
    marginBottom: '0.375rem',
    fontWeight: 400,
    fontSize: '1.375rem',
    lineHeight: '3.75rem',
    padding: '0 1.875rem',
  },
  navIconContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  description: {
    fontSize: '0.875rem',
    fontWeight: 500,
    margin: '0.75rem 0',
    padding: '0 1.875rem',
  },
  form: {
    display: 'flex',
    gap: '0.5rem',
  },
  children: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    margin: '0.5rem 0',
    padding: '0 1.875rem',
  },
  button: {
    marginTop: '0.375rem',
    marginBottom: '0.875rem',
    padding: '0 1.875rem',
  },
}));

const CardSection = ({
  header, description, children, button, contentSx, headerSx, descriptionSx, childrenSx, buttonSx, navIcon, navIconOnClick,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNavIconOnClick = () => {
    if (navIconOnClick) {
      navIconOnClick();
      return;
    }
    navigate(-1);
  };

  return (
    <CardContent className={classes.cardContent} sx={contentSx}>
      {header && (
        <div className={classes.header} style={headerSx}>
          {navIcon ? (
            <div className={classes.navIconContainer}>
              <IconButton onClick={handleNavIconOnClick}>
                {navIcon}
              </IconButton>
              {header}
            </div>
          ) : header}
        </div>
      )}
      {description && (
        <Typography className={classes.description} sx={descriptionSx} component="div">
          {description}
        </Typography>
      )}
      <FormControl className={classes.form}>
        {children && (
          <div className={classes.children} style={childrenSx}>
            {children}
          </div>
        )}
      </FormControl>
      {button && (
        <div className={classes.button} style={buttonSx}>
          {button}
        </div>
      )}
    </CardContent>
  );
};

export default CardSection;
