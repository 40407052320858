import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, TextField, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import CardSection from '../../../common/components/CardSection';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import PhoneFactorAuth, { VERIFY_CODE_CD_SECONDS, VERIFY_CODE_LENGTH } from '../../../firebase/components/PhoneFactorAuth';

const PhoneFactorAuthSection = ({
  title, action, multiFactorResolver, onVerificationCodeVerifySuccess, onFactorDisableSuccess, onFactorEnableSuccess, onVerificationCodeResendSuccess, onVerificationCodeResendFail,
}) => {
  const t = useTranslation();

  const currentUser = useSelector((state) => state.session.user);
  const [phoneNumber] = useState(`${currentUser.countryCode}${currentUser.phone}`);
  const [phoneNumberHint, setPhoneNumberHint] = useState('');
  const [inputVerificationCode, setInputVerificationCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeToVerify, setVerificationCodeToVerify] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState(false);
  const [resendVerificationCodeTimes, setResendVerificationCodeTimes] = useState(0);
  const [resendVerificationCodeCoolDownSeconds, setResendVerificationCodeCoolDownSeconds] = useState(VERIFY_CODE_CD_SECONDS);
  const [countdownLock, setCountdownLock] = useState(true);

  const isValidInputVerificationCode = inputVerificationCode && verificationCode.length === VERIFY_CODE_LENGTH;

  const handleVerificationCodeSubmit = () => {
    setVerificationCodeToVerify(verificationCode);
  };

  const handleSpecialKey = (event) => {
    if (event.keyCode === 13 && isValidInputVerificationCode) {
      handleVerificationCodeSubmit(event);
    }
  };

  const resendVerificationCode = async () => {
    setResendVerificationCodeTimes(resendVerificationCodeTimes + 1);
    setCountdownLock(true);
    setResendVerificationCodeCoolDownSeconds(VERIFY_CODE_CD_SECONDS);
  };

  useEffect(() => {
    if (!multiFactorResolver && phoneNumber) {
      // replace phone number with asterisks, start from the 2nd char to last 4th char
      setPhoneNumberHint(`${phoneNumber.slice(0, 1)}${phoneNumber.slice(1).replace(/.(?=.{4})/g, '*')}`);
    }
  });

  useEffect(() => {
    // count down resend verification code cool down
    setTimeout(() => {
      if (resendVerificationCodeCoolDownSeconds > 0) {
        setResendVerificationCodeCoolDownSeconds(resendVerificationCodeCoolDownSeconds - 1);
        if (resendVerificationCodeCoolDownSeconds === 1) {
          setCountdownLock(false);
        }
      }
    }, 1000);
  });

  return (
    <CardSection
      navIcon={<ArrowBack />}
      header={(
        <Typography sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
          {title || t('twoFactorAuthentication')}
        </Typography>
      )}
      description={t('twoFactorAuthenticationDescriptionVerify', { phoneNumberHint })}
      button={(
        <Button
          fullWidth
          variant="outlined"
          color="outlinedButtonGreen"
          disabled={!isValidInputVerificationCode}
          onClick={handleVerificationCodeSubmit}
        >
          {t('twoFactorAuthenticationButtonVerify')}
        </Button>
      )}
    >
      <Typography sx={{ fontSize: '1.375rem', lineHeight: '1.75rem', margin: '0.625rem 0' }}>
        {t('twoFactorAuthenticationVerificationCodeEnterHint')}
      </Typography>
      <TextField
        fullWidth
        label={t('twoFactorAuthenticationVerificationCode')}
        variant="filled"
        autoFocus
        value={verificationCode}
        error={!!verificationCodeError}
        helperText={verificationCodeError ? (
          verificationCodeError.code === 'auth/code-expired' ? t('twoFactorAuthenticationVerificationCodeExpiredError') : t('twoFactorAuthenticationVerificationCodeError')
        ) : ' '}
        onChange={(e) => setInputVerificationCode(e.target.value)}
        onKeyUp={handleSpecialKey}
      />
      <div>
        <Button
          variant="text"
          onClick={resendVerificationCode}
          disabled={countdownLock}
        >
          {t('twoFactorAuthenticationVerificationCodeResend')}
          {resendVerificationCodeCoolDownSeconds > 0 && ` (0:${String(resendVerificationCodeCoolDownSeconds).padStart(2, '0')})`}
        </Button>
      </div>
      <PhoneFactorAuth
        action={action}
        multiFactorResolver={multiFactorResolver}
        phoneNumber={phoneNumber}
        setPhoneNumberHint={setPhoneNumberHint}
        setVerificationCode={setVerificationCode}
        setVerificationCodeError={setVerificationCodeError}
        inputVerificationCode={inputVerificationCode}
        verificationCodeToVerify={verificationCodeToVerify}
        onVerificationCodeVerifySuccess={onVerificationCodeVerifySuccess}
        onFactorDisableSuccess={onFactorDisableSuccess}
        onFactorEnableSuccess={onFactorEnableSuccess}
        resendVerificationCode={resendVerificationCodeTimes}
        onVerificationCodeResendSuccess={onVerificationCodeResendSuccess}
        onVerificationCodeResendFail={onVerificationCodeResendFail}
      />
    </CardSection>
  );
};

export default PhoneFactorAuthSection;
