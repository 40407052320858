import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles({
  dialog: {
    backgroundColor: '#383838',
  },
  titleText: {
    color: '#FFFFFF',
  },
  contentText: {
    color: '#CECECE',
  },
  buttonColor: {
    color: '#2ECD77',
  },
});

const QuitView = ({
  open, role, members, devices, onClose,
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.session.user);
  const classes = useStyles();
  const { id } = useParams();
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  useEffect(() => {
    setShowErrorMsg(false);
  }, [open]);

  const handleQuit = () => {
    setShowErrorMsg(false);
    if (role === 'admin') {
      if (devices.length < 1 && members.length < 1) {
        fetch(`/api/groups/${id}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        });
        fetch(`/api/groups-members/member?groupId=${id}&userId=${currentUser.id}&role=admin`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        });
        navigate('/accounts/groups');
      } else {
        setShowErrorMsg(true);
      }
    }

    if (role === 'member') {
      fetch(`/api/groups-members/member?groupId=${id}&userId=${currentUser.id}&role=${role}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
      navigate('/accounts/groups');
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle className={classes.titleText}>{role === 'admin' ? t('checkDeleteMessage') : t('checkQuitMessage')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {role === 'admin' ? t('showDeleteMessage') : t('showQuitMessage')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={classes.buttonColor}>{t('quitCancel')}</Button>
        <Button onClick={() => { handleQuit(); }} className={classes.buttonColor}>{role === 'admin' ? t('deleteConfirm') : t('quitConfirm')}</Button>
      </DialogActions>
      {showErrorMsg && <Alert severity="error">{t('deleteGroupErrorMessage')}</Alert>}
    </Dialog>
  );
};

export default QuitView;
