import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControl, InputLabel, Select, Button, Typography,
  MenuItem,
} from '@mui/material';
import { useEffectAsync } from '../../reactHelper';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';

const DriverFilter = ({
  children, handleSubmit,
}) => {
  const classes = useReportStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [disableDriver, setDisableDriver] = useState(false);
  const [disableDevice, setDisableDevice] = useState(false);

  useEffect(() => {
    setSelectedDriver('all');
    setSelectedDevice('none');
  }, []);
  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      const getDevices = await response.json();
      setDevices(getDevices);
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch('/api/drivers');
    if (response.ok) {
      const getDrivers = await response.json();
      setDrivers(getDrivers);
    } else {
      throw Error(await response.text());
    }
  }, []);

  const handleClick = () => {
    handleSubmit({
      selectedDriver,
      selectedDevice,
    });
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterItem}>
        <FormControl fullWidth>
          <InputLabel className={classes.selectLabel}>{t('driverTitle')}</InputLabel>
          <Select
            variant="filled"
            label={t('driverTitle')}
            value={selectedDriver || ''}
            disabled={disableDriver}
            onChange={(e) => {
              setSelectedDriver(e.target.value);
              if (e.target.value !== 'all') {
                setDisableDevice(true);
              }
            }}
          >
            <MenuItem value="all" onClick={() => { setDisableDevice(false); }}>{t('shareSelectAll')}</MenuItem>
            {drivers && drivers.length > 0 && drivers.map((driver) => (
              <MenuItem key={driver.id} value={driver.id}>{driver.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.filterItem}>
        <FormControl fullWidth>
          <InputLabel className={classes.selectLabel}>{t('deviceTitle')}</InputLabel>
          <Select
            variant="filled"
            label={t('deviceTitle')}
            value={selectedDevice || ''}
            disabled={disableDevice}
            onChange={(e) => {
              setSelectedDevice(e.target.value);
              if (e.target.value !== 'none') {
                setDisableDriver(true);
              }
            }}
          >
            <MenuItem value="none" onClick={() => { setDisableDriver(false); }}>{t('shareSelectAll')}</MenuItem>
            {devices && devices.length > 0 && devices.map((device) => (
              <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {children}
      <div className={classes.filterItem}>
        <Button
          fullWidth
          variant="outlined"
          color="outlinedButtonGreen"
          // disabled={disabled}
          onClick={() => handleClick()}
        >
          <Typography variant="button" noWrap>{t('driverShow')}</Typography>
        </Button>
      </div>
      <div className={classes.filterItem}>
        <Button
          fullWidth
          variant="outlined"
          color="outlinedButtonGreen"
          onClick={() => navigate('/accounts/add-driver')}
        >
          <Typography variant="button" noWrap>{t('addNewDriver')}</Typography>
        </Button>
      </div>
    </div>
  );
};

export default DriverFilter;
