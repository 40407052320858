import React from 'react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '1.875rem',
    borderBottom: (props) => (props.bottomDivider ? '1px solid #e6e6e6' : 'none'),
  },
  toolbar: {
    marginLeft: 'auto',
  },
}));

const PageHeader = ({ children, navIcon, title, navIconOnClick, bottomDivider }) => {
  const classes = useStyles({ bottomDivider });
  const navigate = useNavigate();

  const handleNavIconOnClick = () => {
    if (navIconOnClick) {
      navIconOnClick();
      return;
    }
    navigate(-1);
  };

  return (
    <div className={classes.header}>
      <IconButton onClick={handleNavIconOnClick}>
        {navIcon}
      </IconButton>
      <Typography variant="h5">{title}</Typography>
      <div className={classes.toolbar}>
        {children}
      </div>
    </div>
  );
};

export default PageHeader;
