import React from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '22.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  header: {
    textAlign: 'center',
  },
  body: {
    gap: '1rem',
    marginTop: '1.5rem',
  },
  footer: {
    alignItems: 'center',
    gap: '2rem',
  },
}));

const FormContainer = ({ header, children, footer }) => {
  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        {header}
      </div>
      <FormControl fullWidth className={classes.body}>
        {children}
      </FormControl>
      <div className={classes.footer}>
        {footer}
      </div>
    </div>
  );
};

export default FormContainer;
