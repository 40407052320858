import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const SupportTicketDeviceSelect = ({ onChange, value }) => {
  const t = useTranslation();
  const devices = useSelector((state) => ({ ...state.devices.items }));

  return (
    <TextField
      select
      variant="filled"
      label={t('deviceTitle')}
      value={value || ''}
      onChange={onChange}
      helperText=" "
    >
      {Object.values(devices).map((device) => (
        <MenuItem key={device.id} value={device.id}>
          {device.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SupportTicketDeviceSelect;
