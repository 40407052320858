import React, { useState, useEffect } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { devicesActions, reportsActions } from '../../store';

const ReplayFilter = ({
  children, handleSubmit,
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groupDevices = useSelector((state) => state.devices.groupItems);

  const deviceId = useSelector((state) => state.devices.selectedId);
  const period = useSelector((state) => state.reports.period);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);

  const [allDevices, setAllDevices] = useState([]);

  const disabled = !deviceId;
  useEffect(() => {
    // account have only registered devices
    if (Object.keys(devices).length > 0) {
      setAllDevices(devices);
    }
    // account have only groups share devices
    if (Object.keys(groupDevices).length > 0) {
      setAllDevices(groupDevices);
    }
    // account have both registered devices and groups share devices
    if (Object.keys(devices).length > 0 && Object.keys(groupDevices).length > 0) {
      setAllDevices([...Object.values(devices), ...Object.values(groupDevices)]);
    }
  }, [devices, groupDevices]);

  const handleClick = (type) => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case 'today':
        selectedFrom = moment().startOf('day');
        selectedTo = moment().endOf('day');
        break;
      case 'yesterday':
        selectedFrom = moment().subtract(1, 'day').startOf('day');
        selectedTo = moment().subtract(1, 'day').endOf('day');
        break;
      case 'thisWeek':
        selectedFrom = moment().startOf('week');
        selectedTo = moment().endOf('week');
        break;
      case 'previousWeek':
        selectedFrom = moment().subtract(1, 'week').startOf('week');
        selectedTo = moment().subtract(1, 'week').endOf('week');
        break;
      case 'thisMonth':
        selectedFrom = moment().startOf('month');
        selectedTo = moment().endOf('month');
        break;
      case 'previousMonth':
        selectedFrom = moment().subtract(1, 'month').startOf('month');
        selectedTo = moment().subtract(1, 'month').endOf('month');
        break;
      default:
        selectedFrom = moment(from, moment.HTML5_FMT.DATETIME_LOCAL);
        selectedTo = moment(to, moment.HTML5_FMT.DATETIME_LOCAL);
        break;
    }

    handleSubmit({
      deviceId,
      from: selectedFrom.toISOString(),
      to: selectedTo.toISOString(),
      type,
    });
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterItem}>
        <FormControl fullWidth>
          <InputLabel className={classes.selectLabel}>{t('deviceTitle')}</InputLabel>
          <Select
            variant="filled"
            label={t('deviceTitle')}
            value={Object.values(allDevices).some((device) => device.id === deviceId) ? deviceId : ''}
            onChange={(e) => dispatch(devicesActions.selectId(e.target.value))}
          >
            {allDevices && (Object.values(allDevices).sort((a, b) => (a.name || '').localeCompare(b.name || '')).map((device) => (
              <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
            )))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.filterItem}>
        <FormControl fullWidth>
          <InputLabel className={classes.selectLabel}>{t('reportPeriod')}</InputLabel>
          <Select variant="filled" label={t('reportPeriod')} value={period} onChange={(e) => dispatch(reportsActions.updatePeriod(e.target.value))}>
            <MenuItem value="today">{t('reportToday')}</MenuItem>
            <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
            <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
            <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
            <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
            <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
            <MenuItem value="custom">{t('reportCustom')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            label={t('reportFrom')}
            type="datetime-local"
            value={from}
            onChange={(e) => dispatch(reportsActions.updateFrom(e.target.value))}
            fullWidth
          />
        </div>
      )}
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            label={t('reportTo')}
            type="datetime-local"
            value={to}
            onChange={(e) => dispatch(reportsActions.updateTo(e.target.value))}
            fullWidth
          />
        </div>
      )}
      {children}
      <div className={classes.filterItem}>
        <Button
          fullWidth
          variant="outlined"
          color="outlinedButtonGreen"
          disabled={disabled}
          onClick={() => handleClick('json')}
        >
          <Typography variant="button" noWrap>{t('reportShow')}</Typography>
        </Button>
      </div>
    </div>
  );
};

export default ReplayFilter;
