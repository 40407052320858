import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { LinearProgress, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  progress: {
    width: '70%',
    marginLeft: '15%',
    marginRight: '15%',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1.125rem',
    fontWeight: 400,
    fontSize: '2.8125rem',
  },
}));

// animation freeze when call async function issue,
// see: https://github.com/mui/material-ui/issues/10327
const Loading = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <Typography className={classes.message} align="center">
        {message}
      </Typography>
      <LinearProgress className={classes.progress} />
    </div>
  );
};

export default Loading;
