import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table, TableRow, TableCell, TableHead, TableBody, debounce,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LinkIcon from '@mui/icons-material/Link';
import makeStyles from '@mui/styles/makeStyles';
import { useCatch, useEffectAsync } from '../../reactHelper';
import { formatBoolean } from '../../common/util/formatter';
import { useTranslation } from '../../common/components/LocalizationProvider';
import PageLayout from '../../common/components/PageLayout';
import SettingsMenu from '../components/SettingsMenu';
// import CollectionFab from './components/CollectionFab';
import CollectionActions from '../components/CollectionActions';
import TableShimmer from '../../common/components/TableShimmer';
// import { useManager } from '../common/util/permissions';
import { useSuperuser } from '../../common/util/permissions';
import SearchHeader from '../components/SearchHeader';
// import { usePreference } from '../common/util/preferences';
import { messagesActions } from '../../store';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const UsersPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.session.user);

  // const manager = useManager();
  const superuser = useSuperuser();

  // const hours12 = usePreference('twelveHourFormat');

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = useCatch(async (userId) => {
    const response = await fetch(`/api/session/${userId}`);
    if (response.ok) {
      window.location.replace('/');
    } else {
      throw Error(await response.text());
    }
  });

  const actionLogin = {
    key: 'login',
    title: t('loginLogin'),
    icon: <LoginIcon fontSize="small" />,
    handler: handleLogin,
  };

  const actionConnections = {
    key: 'connections',
    title: t('sharedConnections'),
    icon: <LinkIcon fontSize="small" />,
    handler: (userId) => navigate(`/settings/user/${userId}/connections`),
  };

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/users?q=${searchKeyword}`);
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const handleEdit = () => {
    dispatch(messagesActions.push('coming soon'));
  };

  const handleSearch = useCallback(debounce(() => {
    setTimestamp(Date.now());
  }, 500), []);

  const handleSearchKeywordOnChange = (value) => {
    setSearchKeyword(value);
    handleSearch(value);
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['moreTitle', 'settingsUsers']}>
      <SearchHeader
        keyword={searchKeyword}
        onChange={handleSearchKeywordOnChange}
        alwaysShow
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('userEmail')}</TableCell>
            <TableCell>{t('userAdmin')}</TableCell>
            <TableCell>{t('sharedDisabled')}</TableCell>
            {/* <TableCell>{t('userExpirationTime')}</TableCell> */}
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{formatBoolean(item.administrator, t)}</TableCell>
              <TableCell>{formatBoolean(item.disabled, t)}</TableCell>
              {/* <TableCell>{formatTime(item.expirationTime, 'date', hours12)}</TableCell> */}
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions
                  readonly={!superuser}
                  itemId={item.id}
                  itemName={item.name}
                  itemTypeName={t('settingsUser')}
                  editPath="/settings/user"
                  removePath={currentUser.id === item.id ? '/accounts/delete-account' : null}
                  endpoint="users"
                  setTimestamp={setTimestamp}
                  customActions={superuser && [actionLogin, actionConnections]}
                  removeDialogContent={t('sharedDeleteItemConfirm', { itemName: `${item.name} (${item.email})` })}
                  onEdit={handleEdit}
                />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={6} endAction />)}
        </TableBody>
      </Table>
      {/* <CollectionFab editPath="/settings/user" /> */}
    </PageLayout>
  );
};

export default UsersPage;
