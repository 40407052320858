import React from 'react';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import NotificationSettingsTable from '../common/components/NotificationSettingsTable';

const NotificationsPage = () => (
  <PageLayout menu={<SettingsMenu />} breadcrumbs={['moreTitle', 'sharedNotifications']}>
    <NotificationSettingsTable endpoint="notifications" />
  </PageLayout>
);

export default NotificationsPage;
