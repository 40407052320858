import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import ReportFilter from './components/ReportFilter';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';

const LoginHistoryPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const users = useSelector((state) => state.users.items);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setIsVisible] = React.useState(false);

  const handleSubmit = useCatch(async ({ userId, from, to, type }) => {
    const query = new URLSearchParams({ userId, from, to });
    if (type === 'export') {
      window.location.assign(`/api/reports/login/xlsx?${query.toString()}`);
    } else if (type === 'mail') {
      const response = await fetch(`/api/reports/login/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/statistics/login?${query.toString()}`);
        if (response.ok) {
          const data = await response.json();
          setItems(data);
          if (data.length < 1) {
            setIsVisible(true);
            setTimeout(() => {
              setIsVisible(false);
            }, 3000);
          }
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportLoginHistory']}>
      <div className={classes.header}>
        <ReportFilter handleSubmit={handleSubmit} ignoreDevice includeUsers ignoreSchedule />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('sharedTime')}</TableCell>
            <TableCell>{t('userEmail')}</TableCell>
            <TableCell>{t('sharedType')}</TableCell>
            <TableCell>{t('sharedUserIP')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{formatTime(item.logTime)}</TableCell>
              <TableCell>{users[item.userId].email}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.ip}</TableCell>
            </TableRow>
          )) : (<TableShimmer columns={4} />)}
        </TableBody>
      </Table>
      {visible && <Alert severity="error">{t('sharedNoData')}</Alert>}
    </PageLayout>
  );
};

export default LoginHistoryPage;
