import {
  getAuth, connectAuthEmulator, GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider, OAuthProvider, multiFactor,
  signInWithRedirect, signInWithPopup, reauthenticateWithCredential, updatePassword, RecaptchaVerifier, PhoneAuthProvider, PhoneMultiFactorGenerator,
} from 'firebase/auth';
import app from './firebase';

const AUTH_PASSWORD_MIN_LENGTH = 8;
const AUTH_PASSWORD_MAX_LENGTH = 30;

const auth = getAuth(app);
const tenantId = process.env.REACT_APP_FIREBASE_AUTH_TENANT_ID;
if (tenantId) {
  auth.tenantId = tenantId;
}

if (window.location.hostname === 'localhost') {
  const emulatorHost = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;
  if (emulatorHost) {
    connectAuthEmulator(auth, emulatorHost, { disableWarnings: true });
  }
}

const googleAuthProvider = new GoogleAuthProvider();
// force select account
googleAuthProvider.setCustomParameters({ prompt: 'select_account' });

const signInWithGoogleRedirect = async () => signInWithRedirect(auth, googleAuthProvider);

const signInWithGooglePopup = async () => {
  const userCred = await signInWithPopup(auth, googleAuthProvider);
  return userCred.user;
};

const facebookProvider = new FacebookAuthProvider();

const signInWithFacebookRedirect = async () => signInWithRedirect(auth, facebookProvider);

const appleProvider = new OAuthProvider('apple.com');

const signInWithAppleRedirect = async () => signInWithRedirect(auth, appleProvider);

const getCredentialWithPassword = (email, password) => EmailAuthProvider.credential(email, password);

const changeUserPassword = async (newPassword) => {
  await updatePassword(auth.currentUser, newPassword);
};

const changePasswordWithCredential = async (credential, newPassword) => {
  const user = auth.currentUser;
  await reauthenticateWithCredential(user, credential);
  await updatePassword(user, newPassword);
};

const sendPasswordResetEmail = async (email, locale) => {
  if (auth.currentUser) {
    await auth.signOut();
  }

  const response = await fetch('/api/password/reset-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, locale }),
  });
  if (!response.ok) {
    throw Error(await response.json());
  }
};

const reauthenticateWithPassword = async (password) => {
  const credential = getCredentialWithPassword(auth.currentUser.email, password);
  await reauthenticateWithCredential(auth.currentUser, credential);
};

const disableMultiFactors = async () => {
  const multiFactorObj = multiFactor(auth.currentUser);
  const { enrolledFactors } = multiFactorObj;
  enrolledFactors.forEach(async (factor) => {
    await multiFactorObj.unenroll(factor);
  });
};

const enablePhoneFactorAuth = async (expectedVerificationId, verificationCodeToVerify, mfaDisplayName = '') => {
  const cred = PhoneAuthProvider.credential(expectedVerificationId, verificationCodeToVerify);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  return multiFactor(auth.currentUser).enroll(multiFactorAssertion, mfaDisplayName);
};

const recaptchaVerifiers = {};

const clearRecaptchaVerifier = (recaptchaVerifierId) => {
  if (recaptchaVerifiers[recaptchaVerifierId]) {
    recaptchaVerifiers[recaptchaVerifierId].clear();
    recaptchaVerifiers[recaptchaVerifierId] = null;
  }
};

const verifyPhoneNumberWithFirebase = async (phoneInfoOptions, recaptchaVerifierId) => {
  if (!recaptchaVerifiers[recaptchaVerifierId]) {
    // https://github.com/firebase/firebase-js-sdk/pull/7326/files
    recaptchaVerifiers[recaptchaVerifierId] = new RecaptchaVerifier(auth, recaptchaVerifierId, { size: 'invisible' });
  }

  const phoneAuthProvider = new PhoneAuthProvider(auth);
  return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifiers[recaptchaVerifierId]);
};

const sendPhoneVerificationCode = async (multiFactorResolver, recaptchaVerifierId) => {
  const multiFactorHint = multiFactorResolver.hints[0];
  if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    const phoneInfoOptions = {
      multiFactorHint: multiFactorResolver.hints[0],
      session: multiFactorResolver.session,
    };
    return verifyPhoneNumberWithFirebase(phoneInfoOptions, recaptchaVerifierId);
  }
  throw new Error('Send Verification Code Error');
};

const sendEnrollPhoneVerificationCode = async (phoneNumber, recaptchaVerifierId) => {
  const multiFactorSession = await multiFactor(auth.currentUser).getSession();
  const phoneInfoOptions = {
    phoneNumber,
    session: multiFactorSession,
  };
  return verifyPhoneNumberWithFirebase(phoneInfoOptions, recaptchaVerifierId);
};

export default auth;
export {
  signInWithGoogleRedirect, signInWithGooglePopup, getCredentialWithPassword, changeUserPassword, changePasswordWithCredential,
  sendPasswordResetEmail, signInWithFacebookRedirect, signInWithAppleRedirect, reauthenticateWithPassword, disableMultiFactors,
  sendPhoneVerificationCode, sendEnrollPhoneVerificationCode, enablePhoneFactorAuth, clearRecaptchaVerifier,
  AUTH_PASSWORD_MIN_LENGTH, AUTH_PASSWORD_MAX_LENGTH,
};
