import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, TextField, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from '../common/components/LocalizationProvider';
import FormContainer from './FormContainer';
import PhoneFactorAuth, { VERIFY_CODE_CD_SECONDS, VERIFY_CODE_LENGTH } from '../firebase/components/PhoneFactorAuth';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    fontSize: '1.75rem',
  },
  resendContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  resendButton: {
    color: theme.palette.text.hint,
    textDecoration: 'underline',
    height: 'auto',
    padding: '0 0.5rem',
  },
  buttonContainer: {
    display: 'flex',
    gap: '2rem',
  },
}));

const LoginVerification = ({
  onGoBack, multiFactorResolver, onVerificationCodeVerifySuccess, onVerificationCodeResendFail,
}) => {
  const t = useTranslation();
  const classes = useStyles();

  const [phoneNumberHint, setPhoneNumberHint] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState(null);
  const [inputVerificationCode, setInputVerificationCode] = useState('');
  const [verificationCodeToVerify, setVerificationCodeToVerify] = useState('');
  const [resendVerificationCodeCoolDownSeconds, setResendVerificationCodeCoolDownSeconds] = useState(VERIFY_CODE_CD_SECONDS);
  const [countdownLock, setCountdownLock] = useState(true);
  const [resendVerificationCodeTimes, setResendVerificationCodeTimes] = useState(0);

  const handleVerificationCodeSubmit = () => {
    setVerificationCodeToVerify(verificationCode);
  };

  const resendVerificationCode = async () => {
    setResendVerificationCodeTimes(resendVerificationCodeTimes + 1);
    setCountdownLock(true);
    setResendVerificationCodeCoolDownSeconds(VERIFY_CODE_CD_SECONDS);
  };

  useEffect(() => {
    // count down resend verification code cool down
    setTimeout(() => {
      if (resendVerificationCodeCoolDownSeconds > 0) {
        setResendVerificationCodeCoolDownSeconds(resendVerificationCodeCoolDownSeconds - 1);
        if (resendVerificationCodeCoolDownSeconds === 1) {
          setCountdownLock(false);
        }
      }
    }, 1000);
  });

  return (
    <FormContainer
      header={
        <Typography className={classes.title}>{t('loginVerificationTitle', { phoneNumberHint })}</Typography>
      }
    >
      <TextField
        fullWidth
        label={t('twoFactorAuthenticationVerificationCode')}
        variant="filled"
        autoFocus
        value={verificationCode}
        error={!!verificationCodeError}
        helperText={verificationCodeError ? (
          verificationCodeError.code === 'auth/code-expired' ? t('twoFactorAuthenticationVerificationCodeExpiredError') : t('twoFactorAuthenticationVerificationCodeError')
        ) : ' '}
        onChange={(e) => setInputVerificationCode(e.target.value)}
      />
      <div className={classes.resendContainer}>
        <Typography color="text.hint">{t('loginVerificationCodeResendHint')}</Typography>
        <Button
          variant="text"
          className={classes.resendButton}
          onClick={resendVerificationCode}
          disabled={countdownLock}
        >
          {t('loginVerificationCodeResend')}
          {resendVerificationCodeCoolDownSeconds > 0 && ` (0:${String(resendVerificationCodeCoolDownSeconds).padStart(2, '0')})`}
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="containedButtonGrey" onClick={onGoBack}>
          <ArrowBack />
        </Button>
        <Button
          variant="contained"
          color="containedButtonGreen"
          disabled={!verificationCode || verificationCode.length !== VERIFY_CODE_LENGTH}
          onClick={handleVerificationCodeSubmit}
          fullWidth
        >
          {t('twoFactorAuthenticationButtonVerify')}
        </Button>
      </div>
      <PhoneFactorAuth
        multiFactorResolver={multiFactorResolver}
        setPhoneNumberHint={setPhoneNumberHint}
        setVerificationCode={setVerificationCode}
        setVerificationCodeError={setVerificationCodeError}
        inputVerificationCode={inputVerificationCode}
        verificationCodeToVerify={verificationCodeToVerify}
        onVerificationCodeVerifySuccess={onVerificationCodeVerifySuccess}
        onVerificationCodeResendFail={onVerificationCodeResendFail}
        resendVerificationCode={resendVerificationCodeTimes}
      />
    </FormContainer>
  );
};

export default LoginVerification;
