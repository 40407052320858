import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  versionInfo: {
    position: 'absolute',
    fontSize: '0.75rem',
    color: theme.palette.text.hint,
    right: '2px',
    top: 0,
  },
}));

const VersionInfo = () => {
  const classes = useStyles();

  const serverVersion = useSelector((state) => state.session.server.fmsVersion);
  const webVersion = process.env.REACT_APP_FMS_WEB_VERSION;
  const [showVersionInfo, setShowVersionInfo] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 86 && document.activeElement.tagName.toLowerCase() !== 'input') {
        setShowVersionInfo(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.keyCode === 86) {
        setShowVersionInfo(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    showVersionInfo && (
      <div className={classes.versionInfo}>
        {`${webVersion}, ${serverVersion}`}
      </div>
    )
  );
};

export default VersionInfo;
