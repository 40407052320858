import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dialogBackgroud: {
    minWidth: '23.5rem',
    backgroundColor: theme.palette.colors.dialog.main.background,
  },
  dialogBackgroudDark: {
    minWidth: '23.5rem',
    backgroundColor: theme.palette.colors.dialog.dark.background,
  },
  dialogTitle: {
    color: theme.palette.colors.dialog.main.title,
  },
  dialogTitleDark: {
    color: theme.palette.colors.dialog.dark.title,
  },
  dialogContent: {
    color: theme.palette.colors.dialog.main.content,
  },
  dialogContentDark: {
    color: theme.palette.colors.dialog.dark.content,
  },
  dialogButton: {
    color: theme.palette.colors.dialog.main.button,
    '&:hover, :focus': {
      backgroundColor: theme.palette.colors.dialog.main.background,
      color: theme.palette.colors.button,
    },
  },
  dialogButtonDark: {
    color: theme.palette.colors.dialog.dark.button,
    '&:hover, :focus': {
      backgroundColor: theme.palette.colors.dialog.dark.background,
      color: theme.palette.colors.button,
    },
  },
}));

const AlertDialog = ({
  isOpen,
  title,
  content,
  agreeBtnText,
  disagreeBtnText,
  onClose = () => {},
  onAgree = () => {},
  onDisagree = () => {},
  dark = true,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleAgree = () => {
    onAgree();
  };

  const handleDisagree = () => {
    onDisagree();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: dark ? classes.dialogBackgroudDark : classes.dialogBackgroud,
        }}
      >
        {title && (
          <DialogTitle id="alert-dialog-title" className={dark ? classes.dialogTitleDark : classes.dialogTitle}>
            {title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={dark ? classes.dialogContentDark : classes.dialogContent}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {disagreeBtnText && (
            <Button onClick={handleDisagree} className={dark ? classes.dialogButtonDark : classes.dialogButton}>
              {disagreeBtnText}
            </Button>
          )}
          {agreeBtnText && (
            <Button onClick={handleAgree} autoFocus className={dark ? classes.dialogButtonDark : classes.dialogButton}>
              {agreeBtnText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
