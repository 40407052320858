import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMultiFactorResolver } from 'firebase/auth';
import { Snackbar } from '@mui/material';
import { errorsActions, sessionActions } from '../store';
import PageLayout from '../common/components/PageLayout';
import AccountsMenu from './components/AccountMenu';
import CardLayout from '../common/components/CardLayout';
import auth, { reauthenticateWithPassword } from '../firebase/auth';
import PasswordVerfiySection from './components/twoFactorAuthentication/PasswordVerifySection';
import PhoneFactorAuthSection from './components/twoFactorAuthentication/PhoneFactorAuthSection';
import { snackBarDurationShortMs } from '../common/util/duration';
import { useTranslation } from '../common/components/LocalizationProvider';

const TwoFactorAuthenticationChangePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const currentUser = useSelector((state) => state.session.user);

  const [multiFactorResolver, setMultiFactorResolver] = useState();
  const [isVerifying, setIsVerifying] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [verificationCodeResent, setVerificationCodeResent] = useState(false);

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handlePasswordVerfiy = async () => {
    try {
      dispatch(errorsActions.clear());
      await reauthenticateWithPassword(password);
      setIsVerifying(true);
    } catch (error) {
      const errorCode = error.code;
      if (errorCode === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, error);
        setMultiFactorResolver(resolver);
      } else {
        setPasswordError(true);
        setPassword('');
      }
    }
  };

  const handleFactorDisableSuccess = () => {
    setSnackbarMessage(t('twoFactorAuthenticationMessageDisabled'));
  };

  const handleFactorEnableSuccess = () => {
    setSnackbarMessage(t('twoFactorAuthenticationMessageEnabled'));
  };

  const onVerificationCodeResendSuccess = () => {
    setVerificationCodeResent(true);
  };

  const onVerificationCodeResendFail = (error) => {
    if (error.code === 'auth/too-many-requests') {
      dispatch(errorsActions.push(t('sharedTooManyRequestErrorMessage')));
    } else if (error.code === 'auth/invalid-user-token' || error.code === 'auth/requires-recent-login') {
      dispatch(errorsActions.push(t('twoFactorAuthenticationErrorMessageReEnterPassword')));
      setPassword('');
      setIsVerifying(false);
    } else {
      throw new Error();
    }
  };

  useEffect(() => {
    setSnackbarMessage(verificationCodeResent ? t('twoFactorAuthenticationMessagesSent') : '');
  }, [verificationCodeResent]);

  useEffect(() => {
    if (currentUser.isPhoneMultiFactorEnabled === undefined) {
      navigate('/not-found');
    }
  });

  useEffect(() => {
    if (multiFactorResolver) {
      setIsVerifying(true);
    }
  }, [multiFactorResolver]);

  return (
    <PageLayout
      menu={<AccountsMenu />}
      breadcrumbs={['settingsUser', 'basicInfo']}
    >
      <CardLayout>
        {!isVerifying ? (
          <PasswordVerfiySection
            handlePasswordVerfiy={handlePasswordVerfiy}
            password={password}
            passwordError={passwordError}
            onPasswordChange={onPasswordChange}
          />
        ) : (
          <PhoneFactorAuthSection
            action={currentUser.isPhoneMultiFactorEnabled ? 'unenroll' : 'enroll'}
            multiFactorResolver={multiFactorResolver}
            onFactorDisableSuccess={handleFactorDisableSuccess}
            onFactorEnableSuccess={handleFactorEnableSuccess}
            onVerificationCodeResendSuccess={onVerificationCodeResendSuccess}
            onVerificationCodeResendFail={onVerificationCodeResendFail}
          />
        )}
      </CardLayout>
      <Snackbar
        open={!!snackbarMessage}
        onClose={() => {
          setSnackbarMessage('');
          if (verificationCodeResent) {
            setVerificationCodeResent(false);
          } else {
            dispatch(sessionActions.updateUser({ ...currentUser, isPhoneMultiFactorEnabled: !currentUser.isPhoneMultiFactorEnabled }));
            navigate(`/accounts/info/${currentUser.id}`);
          }
        }}
        autoHideDuration={snackBarDurationShortMs}
        message={snackbarMessage}
      />
    </PageLayout>
  );
};

export default TwoFactorAuthenticationChangePage;
