import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container, Divider, Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useEffectAsync } from '../reactHelper';
import { usePreference } from '../common/util/preferences';
import { formatTime } from '../common/util/formatter';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from '../settings/components/SettingsMenu';
import NewsCard from './components/NewsCard';
import NewsSlider from './components/NewsSlider';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: theme.spacing(88),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  news: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    gap: theme.spacing(1),
    minHeight: '50vh',
  },
  title: {
    fontSize: '1.375rem',
  },
  duration: {
    fontSize: '0.875rem',
    color: theme.palette.text.hint,
  },
  moreList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  rule: {
    marginTop: 'auto',
    color: theme.palette.text.hint,
  },
  sliderContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  noContentHint: {
    color: theme.palette.text.hint,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const NewsPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const hours12 = usePreference('twelveHourFormat');

  const [newsList, setNewsList] = useState([]);
  const [newsId, setNewsId] = useState();
  const [news, setNews] = useState();
  const [newsRule, setNewsRule] = useState();

  const [noNewsHint, setNoNewsHint] = useState('');
  const [noMoreNewsHint, setNoMoreNewsHint] = useState('');

  useEffectAsync(async () => {
    const response = await fetch('/api/news');
    const responseJson = await response.json();
    if (response.ok) {
      if (responseJson.data.length > 0) {
        setNoMoreNewsHint('');
        setNewsList(responseJson.data);
        setNewsId(responseJson.data[0].id);
      } else {
        setNoNewsHint(t('newsNoNews'));
        setNoMoreNewsHint(t('newsNoMoreNews'));
      }
    } else {
      throw Error(responseJson.message);
    }
  }, []);

  useEffectAsync(async () => {
    if (newsId) {
      const response = await fetch(`/api/news/${newsId}`);
      const responseJson = await response.json();
      if (response.ok) {
        setNoNewsHint('');
        setNews(responseJson.data);
      } else {
        throw Error(responseJson.message);
      }
    }
  }, [newsId]);

  useEffectAsync(async () => {
    setNewsRule('');
  }, []);

  const handleCardClick = (id) => {
    setNewsId(id);
    document.getElementById('page-content').scrollTo(0, 0);
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['moreTitle']}>
      <Container maxWidth="xs" className={classes.container}>
        <div className={classes.moreList}>
          <Typography className={classes.title}>{t('newsMoreNews')}</Typography>
          {newsList.length > 0 ? (
            <div className={classes.sliderContainer}>
              <NewsSlider>
                {newsList.map((item) => (
                  <NewsCard
                    disableImagePreview
                    key={item.id}
                    news={item}
                    onClick={handleCardClick}
                  />
                ))}
              </NewsSlider>
            </div>
          ) : (
            <Typography className={classes.noContentHint}>{noMoreNewsHint}</Typography>
          )}
        </div>
        <Divider className={classes.divider} />
        <div className={classes.news}>
          {news ? (
            <>
              <Typography className={classes.title}>{news.title}</Typography>
              <Typography className={classes.duration}>
                {formatTime(news.startTime, 'date', hours12)}
                ~
                {formatTime(news.endTime, 'date', hours12)}
              </Typography>
              <div>{parse(news.content)}</div>
            </>
          ) : (
            <Typography className={classes.noContentHint}>{noNewsHint}</Typography>
          )}
        </div>
        <Typography className={classes.rule}>{newsRule}</Typography>
      </Container>
    </PageLayout>
  );
};

export default NewsPage;
