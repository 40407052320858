import './geocoder.css';
import maplibregl from 'maplibre-gl';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { map } from '../core/MapView';
import { errorsActions } from '../../store';
import { useTranslation } from '../../common/components/LocalizationProvider';

const MapGeocoder = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const advancedOptions = {
    // countryCodes: ['tw'],
  };

  useEffect(() => {
    const geocoder = {
      forwardGeocode: async (config) => {
        const features = [];
        try {
          const request = `https://nominatim.openstreetmap.org/search?q=${config.query}&format=geojson&polygon_geojson=1&addressdetails=1&countrycodes=${advancedOptions.countryCodes?.join(',')}`;
          const response = await fetch(request);
          const geojson = await response.json();
          geojson.features.forEach((feature) => {
            const center = [
              feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
              feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
            ];
            features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: center,
              },
              place_name: feature.properties.display_name,
              properties: feature.properties,
              text: feature.properties.display_name,
              place_type: ['place'],
              center,
              bbox: feature.bbox,
            });
          });
        } catch (e) {
          dispatch(errorsActions.push(e.message));
        }
        return { features };
      },
    };

    const control = new MaplibreGeocoder(geocoder, {
      maplibregl,
      collapsed: true,
      placeholder: t('sharedSearch'),
    });

    // override MaplibreGeocoder._fitBoundsForMarkers() for using bbox first to zoom search results
    const method = '_fitBoundsForMarkers';
    const proto = '__proto__';
    control[proto][method] = () => {
      const propStrTypeahead = '_typeahead';
      if (control[propStrTypeahead].data.length < 1) { return; }

      const results = control[propStrTypeahead].data
        .filter((result) => !(typeof result === 'string'))
        .slice(0, control.options.limit);

      const propStrClearEl = '_clearEl';
      control[propStrClearEl].style.display = 'none';

      const propStrMap = '_map';
      const propStrMaplibregl = '_maplibregl';
      if (control.options.flyTo && control[propStrMaplibregl]) {
        if (control[propStrMap]) {
          const defaultFlyOptions = { padding: 100 };
          const bounds = new control[propStrMaplibregl].LngLatBounds();
          results.forEach((feature) => bounds.extend(feature.bbox || feature.geometry.coordinates));

          control[propStrMap].fitBounds(bounds.toArray(), defaultFlyOptions);
        }
      }

      const propStrHandleResultMarkers = '_handleResultMarkers';
      if (results.length > 0 && control[propStrMaplibregl]) {
        control[propStrHandleResultMarkers](results);
      }
    };

    map.addControl(control);
    return () => map.removeControl(control);
  }, [dispatch]);

  return null;
};

export default MapGeocoder;
