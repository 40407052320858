import React from 'react';
import { useParams } from 'react-router-dom';
import { NavigateBefore } from '@mui/icons-material';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import PageHeader from '../common/components/PageHeader';
import AccountMenu from './components/AccountMenu';
import NotificationSettingsTable from '../common/components/NotificationSettingsTable';

const GroupNotificationsPage = () => {
  const t = useTranslation();
  const { id } = useParams();

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'groupMember']}>
      <PageHeader
        navIcon={<NavigateBefore />}
        title={t('groupNotificationSettings')}
      />
      <NotificationSettingsTable endpoint={`groups/${id}/notifications`} />
    </PageLayout>
  );
};

export default GroupNotificationsPage;
