import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ListItemText, ListItemIcon } from '@mui/material';
import GoogleIcon from '../resources/images/google-icon.png';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  navigationBar: {
    display: 'flex',
    marginTop: '15px',
  },
  image: {
    maxWidth: '60%',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    color: 'black',
  },
}));

const GoogleImage = () => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <div className={classes.navigationBar}>
      <ListItemIcon sx={{ width: '10%' }}>
        <img src={GoogleIcon} className={classes.image} alt="Google Icon" />
      </ListItemIcon>
      <div className={classes.textContainer}>
        <ListItemText primary="Google" sx={{ width: '30%', position: 'absolute' }} />
        <ListItemText primary={t('isBinded')} sx={{ marginLeft: '60%', position: 'absolute' }} />
      </div>
    </div>
  );
};

export default GoogleImage;
