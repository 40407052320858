import React from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import CardLayout from './CardLayout';

const useStyles = makeStyles(() => ({
  dialogBackgroud: {
    minWidth: '26.25rem',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'absolute',
    top: 0,
  },
}));

const CardDialog = ({
  children,
  isOpen,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: classes.dialogBackgroud,
        }}
      >
        <CardLayout>
          {children}
        </CardLayout>
      </Dialog>
    </div>
  );
};

export default CardDialog;
