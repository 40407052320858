import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useEffectAsync } from '../reactHelper';

export default (keyword, filter, filterSort, filterMap, positions, setFilteredDevices, setFilteredPositions) => {
  const user = useSelector((state) => state.session.user.email);
  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);
  const groupDevices = useSelector((state) => state.devices.groupItems);
  const [allDevices, setAllDevices] = useState([...Object.values(devices), ...Object.values(groupDevices)]);

  useEffectAsync(async () => {
    const groupId = filter.groups;
    if (groupId) {
      const response = await fetch(`/api/groups/single-group-devices?groupId=${groupId}`);
      if (response.ok) {
        const data = await response.json();
        setAllDevices(data);
      } else {
        throw Error(await response.text());
      }
    } else {
      setAllDevices([...Object.values(devices), ...Object.values(groupDevices)]);
    }
  }, [filter]);

  useEffect(() => {
    setAllDevices([...Object.values(devices), ...Object.values(groupDevices)]);
  }, []);

  useEffect(() => {
    // update the latest status of registered devices / group devices
    const groupId = filter.groups;
    if (groupId && allDevices[0]?.hasOwnProperty('owner') && allDevices[0].owner !== user) {
      // update the latest status of group devices
      const groupDeviceArray = Object.values(groupDevices);
      const updatedGroupDevices = groupDeviceArray.filter((device) => allDevices.some((all) => all.id === device.id));
      setAllDevices(updatedGroupDevices);
    } else if (groupId) {
      // update the latest status of registered devices
      const deviceArray = Object.values(devices);
      const updatedDevices = deviceArray.filter((device) => allDevices.some((all) => all.id === device.id));
      setAllDevices(updatedDevices);
    } else {
      setAllDevices([...Object.values(devices), ...Object.values(groupDevices)]);
    }
  }, [devices, groupDevices]);

  useEffect(() => {
    const filtered = filter.statuses.length > 0 ? (Object.values(allDevices)
      .filter((device) => !filter.statuses.length || filter.statuses.includes(device.status))
      .filter((device) => {
        const lowerCaseKeyword = keyword.toLowerCase();
        return [device.name, device.uniqueId, device.phone, device.model, device.contact].some((s) => s && s.toLowerCase().includes(lowerCaseKeyword));
      })) : (Object.values([]));

    switch (filterSort) {
      case 'name':
        filtered.sort((device1, device2) => device1.name.localeCompare(device2.name));
        break;
      case 'lastUpdate':
        filtered.sort((device1, device2) => {
          const time1 = device1.lastUpdate ? moment(device1.lastUpdate).valueOf() : 0;
          const time2 = device2.lastUpdate ? moment(device2.lastUpdate).valueOf() : 0;
          return time2 - time1;
        });
        break;
      default:
        break;
    }
    setFilteredDevices(filtered);
    setFilteredPositions(filterMap
      ? filtered.map((device) => positions[device.id]).filter(Boolean)
      : Object.values(positions));
  }, [keyword, filter, filterSort, filterMap, groups, devices, groupDevices, allDevices, positions, setFilteredDevices, setFilteredPositions]);
};
