import React, { useState } from 'react';
import {
  Button, TextField, Typography, Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginLayout from './LoginLayout';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import { useCatch } from '../reactHelper';
import FormContainer from './FormContainer';
import AlertDialog from '../common/components/AlertDialog';
import { sendPasswordResetEmail } from '../firebase/auth';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '1.75rem',
    fontWeight: 400,
    lineHeight: '2.25rem',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  button: {
    minWidth: '80px',
    minHeight: '30px',
    maxWidth: '30%',
    maxHeight: '10%',
  },
}));

const ResetPasswordPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const { language } = useLocalization();

  const [email, setEmail] = useState('');
  const [isEmailSendModalOpen, setIsEmailSendModalOpen] = useState(false);

  const isValidEmail = /(.+)@(.+)\.(.{2,})/.test(email);

  const handleSubmit = useCatch(async (event) => {
    event.preventDefault();
    try {
      const locale = (language.length > 2) ? `${language.slice(0, 2)}-${language.slice(-2).toLowerCase()}` : language;
      await sendPasswordResetEmail(email, locale);
    } catch (error) {
      throw new Error();
    }
    setIsEmailSendModalOpen(true);
  });

  const handleSpecialKey = (event) => {
    if (event.keyCode === 13 && isValidEmail) {
      handleSubmit(event);
    }
  };

  const handleClickAgree = () => {
    setIsEmailSendModalOpen(false);
    navigate('/login');
  };

  return (
    <LoginLayout onSubmit={(e) => e.preventDefault()}>
      <AlertDialog
        isOpen={isEmailSendModalOpen}
        title={t('resetPasswordModalTitle')}
        content={(
          <>
            {t('resetPasswordModalDescription1')}
            <br />
            {t('resetPasswordModalDescription2')}
          </>
        )}
        agreeBtnText={t('resetPasswordModalButtonAgree')}
        onAgree={handleClickAgree}
      />
      <FormContainer
        header={
          <Typography className={classes.title}>{t('loginReset')}</Typography>
        }
      >
        <TextField
          variant="filled"
          required
          type="email"
          label={t('email')}
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onKeyUp={handleSpecialKey}
          helperText=" "
        />
        <Typography color="text.hint">
          {t('resetPasswordHint')}
        </Typography>
        <Grid container spacing={1} className={classes.gridItem}>
          <Grid item xs={2}>
            <Button variant="contained" color="containedButtonGrey" onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid item xs={8} className={classes.button}>
            <Button
              variant="contained"
              color="containedButtonGreen"
              onClick={handleSubmit}
              disabled={!isValidEmail}
              fullWidth
            >
              {t('resetPasswordButtonNext')}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
    </LoginLayout>
  );
};

export default ResetPasswordPage;
