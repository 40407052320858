import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BottomMenu from './common/components/BottomMenu';
import SocketController from './SocketController';
import CachingController from './CachingController';
import { useEffectAsync } from './reactHelper';
import { sessionActions } from './store';
import auth from './firebase/auth';

const useStyles = makeStyles(() => ({
  page: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  menu: {
    zIndex: 4,
  },
}));

const App = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const newServer = useSelector((state) => state.session.server.newServer);
  const initialized = useSelector((state) => !!state.session.user);
  const isLoggingOut = useSelector((state) => state.session.isLoggingOut);
  const isLoggingOutRef = useRef(isLoggingOut);

  const getLoginNavigateTo = () => {
    // only allow accounts/delete-account route
    const filterRoutesPattern = /^\/accounts\/delete-account$/;
    return !isLoggingOut && filterRoutesPattern.test(location.pathname) ? `/login?next=${location.pathname}` : '/login';
  };

  useEffect(() => {
    isLoggingOutRef.current = isLoggingOut;
  }, [isLoggingOut]);

  useEffectAsync(async () => {
    let fireBaseListener;
    if (isLoggingOut) {
      await auth.signOut();
    } else {
      if (!initialized) {
        const response = await fetch('/api/session');
        if (response.ok) {
          dispatch(sessionActions.updateUser(await response.json()));
        } else if (newServer) {
          navigate('/register');
        } else {
          await auth.signOut();
          navigate(getLoginNavigateTo());
        }
      }
      // Check firebase auth has valid user state when page is refreshed
      fireBaseListener = auth.onAuthStateChanged((user) => {
        if (!user && !isLoggingOutRef.current) {
          dispatch(sessionActions.updateUser(null));
          navigate(getLoginNavigateTo());
        }
      });
    }
    return () => {
      if (fireBaseListener) {
        fireBaseListener();
      }
    };
  }, [initialized, isLoggingOut]);

  return !initialized ? (<LinearProgress />) : (
    <>
      <SocketController />
      <CachingController />
      <div className={classes.page}>
        <Outlet />
      </div>
      {!desktop && (
        <div className={classes.menu}>
          <BottomMenu />
        </div>
      )}
    </>
  );
};

export default App;
