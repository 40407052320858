import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TextField, Button,
  MenuItem, Snackbar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCatch, useEffectAsync } from '../reactHelper';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import AccountsMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import { snackBarDurationShortMs } from '../common/util/duration';

const EditDriverPage = () => {
  const t = useTranslation();
  const [deviceList, setDeviceList] = useState([]);
  const [driver, setDriver] = useState();
  const [assignedDevice, setAssignedDevice] = useState([]);
  const [open, setOpen] = useState(false);
  const [driverNameCheck, setDriverNameCheck] = useState('');

  const navigate = useNavigate();
  const { id } = useParams();

  useEffectAsync(async () => {
    const response = await fetch(`/api/drivers/${id}`);
    if (response.ok) {
      const items = await response.json();
      setDriver(items);
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    if (driver) {
      const getDrivers = await fetch('/api/drivers');
      const getDevices = await fetch('/api/devices');
      if (getDrivers.ok) {
        const DriverItems = await getDrivers.json();
        const DeviceItems = await getDevices.json();
        const filteredDevices = DeviceItems.filter((device) => !DriverItems.some((driver) => driver.deviceId === device.id));
        setDeviceList(filteredDevices);
        const filterAssignedDevice = DeviceItems.find((device) => driver.deviceId === device.id);
        setAssignedDevice(filterAssignedDevice);
      } else {
        throw Error(await getDrivers.text());
      }
    }
  }, [driver]);

  const checkInputText = (input) => {
    if (!input) {
      setDriverNameCheck(t('driverNameIsEmptyErrorMessage'));
      return true;
    }
    if (input.length < 1 || input.length > 40) {
      setDriverNameCheck(t('inputTextLengthLimitType', { minLength: 1, maxLength: 40 }));
      return true;
    }
    return false;
  };

  const handleSave = useCatch(async () => {
    const isFail = checkInputText(driver.name);
    if (!isFail) {
      const response = await fetch(`/api/drivers/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(driver),
      });
      if (response.ok) {
        setOpen(true);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        throw Error(await response.text());
      }
    }
  });

  return (
    <PageLayout menu={<AccountsMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('editDriverTitle')}
          button={(
            <Button
              fullWidth
              variant="outlined"
              color="outlinedButtonGreen"
              onClick={handleSave}
            >
              {t('shareSave')}
            </Button>
          )}
        >
          <TextField
            required
            color="success"
            variant="filled"
            value={driver?.name || ''}
            error={driverNameCheck !== ''}
            helperText={driverNameCheck !== '' ? driverNameCheck : ' '}
            onChange={(event) => {
              setDriverNameCheck('');
              setDriver({ ...driver, name: event.target.value });
            }}
            label={t('driverName')}
          />
          <TextField
            color="success"
            variant="filled"
            value={driver?.phone || ''}
            onChange={(event) => {
              setDriver({ ...driver, phone: event.target.value });
            }}
            helperText=" "
            label={t('driverPhone')}
          />
          <TextField
            select
            variant="filled"
            label={t('deviceTitle')}
            value={assignedDevice && Object.keys(assignedDevice).length > 0 ? driver?.deviceId : ''}
            defaultValue=""
            helperText=" "
            onChange={(event) => {
              setDriver({ ...driver, deviceId: event.target.value });
            }}
          >
            <MenuItem value="">{t('shareSelectNothing')}</MenuItem>
            {assignedDevice && <MenuItem key={assignedDevice.id} value={assignedDevice.id}>{assignedDevice.name}</MenuItem>}
            {deviceList && deviceList.length > 0 && deviceList.map((device) => (
              <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
            ))}
          </TextField>
        </CardSection>
      </CardLayout>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={snackBarDurationShortMs}
        message={t('shareSaved')}
      />
    </PageLayout>
  );
};

export default EditDriverPage;
