import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Typography,
  FormGroup, Switch, ListItemText, ListItemIcon,
} from '@mui/material';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import makeStyles from '@mui/styles/makeStyles';
import AccountMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch, useEffectAsync } from '../reactHelper';
import { devicesActions } from '../store';
import { truncateText } from '../common/util/formatter';

const useStyles = makeStyles(() => ({
  switchContainer: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const GroupsShareDevicesPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [groupDevices, setGroupDevices] = useState([]);
  const [group, setGroup] = useState({});
  const [allShare, setAllShare] = useState(false);

  useEffect(() => {
    // check if all devices button are checked to trigger allShare button
    let isAllDeviceChecked = false;
    if (devices.length === groupDevices.length) {
      isAllDeviceChecked = devices.every((device) => groupDevices.some((groupDevice) => (groupDevice.groupId === Number(id) && groupDevice.deviceId === device.id)));
    }
    if (groupDevices.length === 0) {
      isAllDeviceChecked = false;
    }
    setAllShare(isAllDeviceChecked);
  }, [devices, groupDevices]);

  useEffectAsync(async () => {
    setLoading(true);
    const response = await fetch(`/api/groups/${id}`);
    try {
      if (response.ok) {
        const group = await response.json();
        setGroup(group);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        const data = await response.json();
        setDevices(data);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/groups/groupDevice?groupId=${id}`);
      if (response.ok) {
        setGroupDevices(await response.json());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const onGroupItemSaved = useCatch(async () => {
    const response = await fetch(`/api/groups/groupDevice?groupId=${id}`);
    if (response.ok) {
      const getGroupDevices = await response.json();
      setGroupDevices(getGroupDevices);
      dispatch(devicesActions.updateGroupItems(getGroupDevices));
    } else {
      throw Error(await response.text());
    }
  });

  const createBody = (deviceId) => {
    const body = {};
    body.groupId = id;
    body.deviceId = deviceId;
    return body;
  };

  const handleAllShare = useCatch(async (event) => {
    const isChecked = event.target.checked;
    const results = [];
    const url = '/api/permissions';
    if (isChecked) {
      devices.forEach(async (device) => {
        // check if device.id is not in groupdevices
        if (!groupDevices.some((groupDevice) => groupDevice.groupId === Number(id) && groupDevice.deviceId === device.id)) {
          results.push(fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createBody(device.id)),
          }));
          await Promise.all(results);
          onGroupItemSaved();
        }
      });
    } else {
      devices.forEach(async (device) => {
        // check if device.id is not in groupdevices
        if (groupDevices.some((groupDevice) => groupDevice.groupId === Number(id) && groupDevice.deviceId === device.id)) {
          results.push(fetch(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createBody(device.id)),
          }));
          await Promise.all(results);
          onGroupItemSaved();
        }
      });
    }
    setAllShare(isChecked);
  });

  const handleShare = useCatch(async (event, deviceId) => {
    const isChecked = event.target.checked;
    const results = [];
    const url = '/api/permissions';
    results.push(fetch(url, {
      method: isChecked ? 'POST' : 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(createBody(deviceId)),
    }));
    await Promise.all(results);
    onGroupItemSaved();
  });

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('groupShareDeviceShow')}
        >
          <Typography gutterBottom variant="h5" component="div">
            {group.name}
          </Typography>
          <Typography variant="body2">
            {t('shareDevicesMessage')}
          </Typography>
          <FormGroup>
            <div className={classes.switchContainer}>
              <ListItemText primary={t('shareAll')} />
              <Switch
                checked={allShare}
                onChange={(event) => {
                  setAllShare(event.target.checked);
                  handleAllShare(event);
                }}
              />
            </div>
            {!loading ? devices.map((item) => (
              <div className={classes.switchContainer} key={item.id}>
                <ListItemIcon>
                  <BikeScooterIcon />
                </ListItemIcon>
                <ListItemText primary={truncateText(item.name, 25)} />
                <Switch
                  checked={groupDevices.some((device) => device.groupId === Number(id) && device.deviceId === item.id) || allShare}
                  onChange={(event) => handleShare(event, item.id)}
                />
              </div>
            )) : null}
          </FormGroup>
        </CardSection>
      </CardLayout>
    </PageLayout>
  );
};

export default GroupsShareDevicesPage;
