import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'groups',
  initialState: {
    items: {},
    groupId: null,
    groupIds: [],
    groupSeletedColumns: [],
  },
  reducers: {
    update(state, action) {
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    selectGroupId(state, action) {
      state.groupId = action.payload;
      state.groupIds = state.groupId ? state.groupId : [];
    },
    selectGroupIds(state, action) {
      state.groupIds = action.payload;
      [state.groupId] = state.groupIds;
    },
    selectGroupColumns(state, action) {
      state.groupSeletedColumns = action.payload;
    },
  },
});

export { actions as groupsActions };
export { reducer as groupsReducer };
