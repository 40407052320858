import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainLayout from '../common/components/MainLayout';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    borderRadius: 0,
  },
  form: {
    maxWidth: theme.spacing(88),
    padding: theme.spacing(5),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
}));

const AccountDeleteHelpPage = () => {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <MainLayout>
      <Paper className={classes.paper}>
        <form className={classes.form}>
          <Typography variant="h6">{t('helpAccountDeleteTitle')}</Typography>
          <div>
            <Typography>{t('helpAccountDeleteDescription')}</Typography>
            <ul>
              <li>{t('helpAccountDeletConfirmList1')}</li>
              <li>{t('helpAccountDeletConfirmList2')}</li>
              <li>{t('helpAccountDeletConfirmList3')}</li>
            </ul>
          </div>
          <Typography variant="h6">{t('helpAccountDeleteReminderTitle')}</Typography>
          <Typography>{t('helpAccountDeleteReminderDescription')}</Typography>
          <div>
            <Typography variant="h6">{t('helpAccountDeleteStepTitle')}</Typography>
            <ol>
              <li>{t('helpAccountDeleteStep1')}</li>
              <li>{t('helpAccountDeleteStep2')}</li>
              <li>{t('helpAccountDeleteStep3')}</li>
            </ol>
          </div>
          <Link to="/accounts/delete-account" target="_blank">
            <Button fullWidth variant="contained" color="containedButtonGreen">
              {t('helpAccountDeleteButtonStart')}
            </Button>
          </Link>
        </form>
      </Paper>
    </MainLayout>
  );
};

export default AccountDeleteHelpPage;
