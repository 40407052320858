export default {
  // sidebarWidth: '28%',
  sidebarWidthTablet: '52px',
  drawerWidthDesktop: '22.5rem', // '360px'
  drawerWidthTablet: '320px',
  drawerHeightPhone: '250px',
  filterFormWidth: '160px',
  eventsDrawerWidth: '360px',
  bottomBarHeight: 56,
  popupMapOffset: 300,
  popupMaxWidth: 320,
  popupImageHeight: 180,
  cardLayoutMaxWidth: '29.25rem',
  newsCardHeight: '13.5rem',
  newsCardWidth: '22.5rem',
  newsCardWidthPhone: '15rem',
};
