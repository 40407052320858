import React from 'react';
import { useDispatch } from 'react-redux';
// import Button from '@mui/material/Button';
// import { Snackbar } from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from './LocalizationProvider';
import { useCatch } from '../../reactHelper';
// import { snackBarDurationLongMs } from '../util/duration';
import AlertDialog from './AlertDialog';
import { messagesActions } from '../../store';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     [theme.breakpoints.down('md')]: {
//       bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(1)})`,
//     },
//   },
//   button: {
//     height: 'auto',
//     marginTop: 0,
//     marginBottom: 0,
//     color: theme.palette.colors.negative,
//   },
// }));

const RemoveDialog = ({
  open, endpoint, itemId, itemName, itemTypeName, onResult,
  title, content, agreeBtnText, disagreeBtnText, onAgree, onDisagree,
}) => {
  // const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const handleRemove = useCatch(async () => {
    const response = await fetch(`/api/${endpoint}/${itemId}`, { method: 'DELETE' });
    if (response.ok) {
      dispatch(messagesActions.push(itemTypeName ? t('sharedDeletedItem', { itemName: itemTypeName }) : t('sharedDeleted')));
      onResult(true);
    } else {
      onResult(false);
      throw Error(await response.text() || t('sharedDeleteFailed'));
    }
  });

  const handleAgree = () => {
    if (onAgree) {
      onAgree();
      return;
    }
    handleRemove();
  };

  const handleDisagree = () => {
    if (onDisagree) {
      onDisagree();
    }
  };

  const getDialogTitle = () => {
    if (title) {
      return title;
    }
    return itemTypeName ? t('sharedDeleteItem', { itemName: itemTypeName }) : t('sharedDelete');
  };

  const getContent = () => {
    if (content) {
      return content;
    }
    if (itemName && itemTypeName) {
      return t('sharedDeleteItemWithTypeConfirm', { itemName, itemTypeName });
    }
    if (itemName || itemTypeName) {
      return t('sharedDeleteItemConfirm', { itemName: itemName || itemTypeName });
    }
    return t('sharedDeleteConfirm');
  };

  return (
    // <Snackbar
    //   className={classes.root}
    //   open={open}
    //   autoHideDuration={snackBarDurationLongMs}
    //   onClose={() => onResult(false)}
    //   message={t('sharedRemoveConfirm')}
    //   action={(
    //     <Button size="small" className={classes.button} onClick={handleRemove}>
    //       {t('sharedRemove')}
    //     </Button>
    //   )}
    // />
    <AlertDialog
      isOpen={open}
      title={getDialogTitle()}
      content={getContent()}
      agreeBtnText={agreeBtnText ?? t('sharedDelete')}
      disagreeBtnText={disagreeBtnText ?? t('sharedCancel')}
      onAgree={handleAgree}
      onDisagree={handleDisagree}
    />
  );
};

export default RemoveDialog;
