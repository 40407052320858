import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({
  permissions = [],
}) => {
  permissions = Array.isArray(permissions) ? permissions : [permissions];
  if (permissions.some((permission) => permission())) {
    return <Outlet />;
  }
  return <Navigate to="/not-found" />;
};

export default ProtectedRoute;
