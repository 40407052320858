import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { prefixString } from '../../../common/util/stringUtils';

const SupportTicketStatusSelect = (props) => {
  const t = useTranslation();
  const ticketStatuses = ['pending', 'processing', 'resolved', 'closed'];

  return (
    <TextField
      {...props}
      select
      variant="filled"
      label={t('sharedStatus')}
      helperText=" "
    >
      <MenuItem value="" sx={{ display: 'none' }} />
      {ticketStatuses.map((type) => (
        <MenuItem key={type} value={type}>
          {t(prefixString('supportTicketStatus', type))}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SupportTicketStatusSelect;
