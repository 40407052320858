import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SupportTicketStatusSelect from './SupportTicketStatusSelect';
import SupportTicketTypeSelect from './SupportTicketTypeSelect';
import { formatTime } from '../../../common/util/formatter';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    height: 'inherit',
  },
  lastUpdateTime: {
    float: 'right',
    lineHeight: '2rem',
  },
}));

const ReadonlyTextField = (props) => (
  <TextField
    {...props}
    fullWidth
    variant="filled"
    InputProps={{ readOnly: true }}
    sx={{ input: { cursor: 'not-allowed' } }}
    helperText=" "
  />
);

const SupportTicketDialog = ({
  open, onClose, ticket, setTicket, onSave,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleSave = () => {
    if (onSave) {
      onSave(ticket);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="lg"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        {`${t('supportTicket')} - ${ticket?.ticketNo}`}
        <Typography variant="caption" className={classes.lastUpdateTime}>
          {ticket ? `${t('sharedLastUpdateTime')}: ${formatTime(ticket.lastUpdateTime)}` : ''}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid xs={6}>
            <SupportTicketTypeSelect
              fullWidth
              value={ticket?.type || ''}
              onChange={(e) => setTicket({ ...ticket, type: e.target.value })}
            />
          </Grid>
          <Grid xs={6}>
            <SupportTicketStatusSelect
              fullWidth
              value={ticket?.status || ''}
              onChange={(e) => setTicket({ ...ticket, status: e.target.value })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid xs={6}>
            <ReadonlyTextField
              label={t('sharedUsername')}
              value={ticket?.username || ''}
            />
          </Grid>
          <Grid xs={6}>
            <ReadonlyTextField
              label={t('userEmail')}
              value={ticket?.userEmail || ''}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid xs={6}>
            <ReadonlyTextField
              label={t('sharedDevice')}
              value={ticket?.deviceName || ''}
            />
          </Grid>
          <Grid xs={6}>
            <ReadonlyTextField
              label={t('sharedCreateTime')}
              value={ticket ? formatTime(ticket.createTime) : ''}
            />
          </Grid>
        </Grid>
        <TextField
          multiline
          fullWidth
          maxRows={10}
          variant="filled"
          label={t('sharedDescription')}
          value={ticket?.content || ''}
          onChange={(e) => setTicket({ ...ticket, content: e.target.value })}
          helperText=" "
        />
        <TextField
          multiline
          fullWidth
          minRows={5}
          maxRows={10}
          variant="filled"
          label={t('supportTicketProcessContent')}
          value={ticket?.processContent || ''}
          onChange={(e) => setTicket({ ...ticket, processContent: e.target.value })}
          helperText=" "
          inputRef={(input) => input && input.focus()}
          onFocus={(e) => e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length,
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('sharedCancel')}</Button>
        <Button onClick={handleSave}>{t('sharedSave')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupportTicketDialog;
