import { useMemo } from 'react';
// source from: [usePositionAttributes.js]

export default (t) => useMemo(() => ({
  speed: {
    name: t('positionSpeed'),
    type: 'number',
    dataType: 'speed',
    property: true,
  },
  totalDistance: {
    name: t('deviceTotalDistance'),
    type: 'number',
    dataType: 'distance',
  },
}), [t]);
