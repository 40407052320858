import { initializeApp } from 'firebase/app';

const authDomain = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST || process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FMS_FIREBASE_API_KEY,
  authDomain,
};

const app = initializeApp(firebaseConfig);

export default app;
