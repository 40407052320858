import React from 'react';
import PageLayout from '../common/components/PageLayout';
import AccountsMenu from './components/AccountMenu';
import OnGoingGif from '../common/components/OnGoingGif';

const BillPage = () => (
  <PageLayout menu={<AccountsMenu />} breadcrumbs={['settingsUser', 'billManagement']}>
    <OnGoingGif />
  </PageLayout>
);

export default BillPage;
