import { Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagesActions } from '../../store';
import { snackBarDurationShortMs } from '../util/duration';

const SnackbarMessageHandler = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.messages.messages.find(() => true));

  return (
    <Snackbar
      open={!!message}
      autoHideDuration={snackBarDurationShortMs}
      onClose={() => dispatch(messagesActions.pop())}
      message={message}
    />
  );
};

export default SnackbarMessageHandler;
