import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slick.css';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { NavigateBefore } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  arrow: (props) => {
    let styles = {};
    if (props.className.includes('slick-disabled')) {
      styles = {
        display: 'none',
      };
    } else {
      styles = {
        display: 'flex',
        height: '100%',
        top: 0,
        color: theme.palette.text.primary,
        '&:hover, &:focus': {
          color: theme.palette.text.primary,
        },
      };
    }
    return {
      ...styles,
      transform: props.className.includes('slick-next') ? 'rotate(180deg)' : 'none',
    };
  },
}));

const Arrow = (props) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <IconButton
      className={`${className} ${classes.arrow}`}
      onClick={onClick}
    >
      <NavigateBefore />
    </IconButton>
  );
};

const NewsSlider = ({
  children,
}) => {
  const settings = {
    className: 'center',
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  );
};

export default NewsSlider;
