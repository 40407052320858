import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Typography, MenuItem, FormGroup, TextField, Button,
  Switch, OutlinedInput, InputAdornment, IconButton, ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation, useTranslationKeys } from '../common/components/LocalizationProvider';

import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { prefixString, unprefixString } from '../common/util/stringUtils';
import SelectField from '../common/components/SelectField';
import useMapStyles from '../map/core/useMapStyles';
// import useMapOverlays from '../map/overlay/useMapOverlays';
import { useCatch } from '../reactHelper';
import { sessionActions } from '../store';
import { useRestriction } from '../common/util/permissions';
import { useAttributePreference } from '../common/util/preferences';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import CardAccordion from '../common/components/CardAccordion';

const deviceFields = [
  { id: 'name', name: 'sharedName' },
  { id: 'uniqueId', name: 'deviceIdentifier' },
  // { id: 'phone', name: 'sharedPhone' },
  // { id: 'model', name: 'deviceModel' },
  // { id: 'contact', name: 'deviceContact' },
];

const useStyles = makeStyles(() => ({
  tokenActions: {
    display: 'flex',
    flexDirection: 'column',
  },
  switchContainer: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const PreferencesPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const readonly = useRestriction('readonly');

  const user = useSelector((state) => state.session.user);
  const [attributes, setAttributes] = useState(user.attributes);

  const versionApp = process.env.REACT_APP_VERSION.slice(0, -2);
  const versionFmsWeb = process.env.REACT_APP_FMS_WEB_VERSION;
  const versionServer = useSelector((state) => state.session.server.version);
  const versionFmsServer = useSelector((state) => state.session.server.fmsVersion);
  const socket = useSelector((state) => state.session.socket);

  const [token, setToken] = useState(null);
  const [tokenExpiration, setTokenExpiration] = useState(moment().add(1, 'week').locale('en').format(moment.HTML5_FMT.DATE));

  const mapStyles = useMapStyles();
  // const mapOverlays = useMapOverlays();

  const positionAttributes = usePositionAttributes(t);
  const positionColumns = Object.keys(positionAttributes);

  const hiddenPositionColumns = useAttributePreference('report.routeHiddenPositionColumns', '').split(',');
  const displayPositionAttributes = positionColumns
    .filter((attributeKey) => hiddenPositionColumns.indexOf(attributeKey) === -1)
    .reduce((obj, key) => Object.assign(obj, { [key]: positionAttributes[key] }), {});

  const defaultSelectedPositionColumns = ['speed', 'totalDistance', 'course'];
  const displayColumns = attributes.positionItems?.split(',').filter((column) => positionColumns.indexOf(column) > -1 && hiddenPositionColumns.indexOf(column) === -1);

  // const filter = createFilterOptions();

  const generateToken = useCatch(async () => {
    const expiration = moment(tokenExpiration, moment.HTML5_FMT.DATE).toISOString();
    const response = await fetch('/api/session/token', {
      method: 'POST',
      body: new URLSearchParams(`expiration=${expiration}`),
    });
    if (response.ok) {
      setToken(await response.text());
    } else {
      throw Error(await response.text());
    }
  });

  const alarms = useTranslationKeys((it) => it.startsWith('alarm')).map((it) => ({
    key: unprefixString('alarm', it),
    name: t(it),
  }));

  const handleSave = useCatch(async () => {
    const response = await fetch(`/api/users/${user.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...user, attributes }),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch(sessionActions.updateUser(data));
      navigate(-1);
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['moreTitle', 'sharedPreferences']}>
      <CardLayout>
        <CardSection
          header={t('systemPreferences')}
          description={t('systemPreferencesInfo')}
          childrenSx={{ padding: 0 }}
          button={(
            <Button
              fullWidth
              variant="outlined"
              color="outlinedButtonGreen"
              onClick={handleSave}
            >
              {t('saveChange')}
            </Button>
          )}
        >
          {!readonly && (
            <>
              <CardAccordion
                summary={t('mapTitle')}
                detailSx={{ gap: '2rem' }}
              >
                <TextField
                  select
                  variant="filled"
                  label={t('mapActive')}
                  SelectProps={{
                    multiple: true,
                    value: attributes.activeMapStyles?.split(',') || ['carto', 'googleRoad'],
                    onChange: (e, child) => {
                      const clicked = mapStyles.find((s) => s.id === child.props.value);
                      if (clicked.available) {
                        setAttributes({ ...attributes, activeMapStyles: e.target.value.join(',') });
                      } else if (clicked.id !== 'custom') {
                        const query = new URLSearchParams({ attribute: clicked.attribute });
                        navigate(`/settings/user/${user.id}?${query.toString()}`);
                      }
                    },
                  }}
                >
                  {mapStyles.map((style) => (
                    <MenuItem key={style.id} value={style.id}>
                      <Typography component="span" color={style.available ? 'textPrimary' : 'error'}>{style.title}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
                {/* <FormControl>
                  <InputLabel>{t('mapOverlay')}</InputLabel>
                  <Select
                    label={t('mapOverlay')}
                    value={attributes.selectedMapOverlay || ''}
                    onChange={(e) => {
                      const clicked = mapOverlays.find((o) => o.id === e.target.value);
                      if (!clicked || clicked.available) {
                        setAttributes({ ...attributes, selectedMapOverlay: e.target.value });
                      } else if (clicked.id !== 'custom') {
                        const query = new URLSearchParams({ attribute: clicked.attribute });
                        navigate(`/settings/user/${user.id}?${query.toString()}`);
                      }
                    }}
                  >
                    <MenuItem value="">{'\u00a0'}</MenuItem>
                    {mapOverlays.map((overlay) => (
                      <MenuItem key={overlay.id} value={overlay.id}>
                        <Typography component="span" color={overlay.available ? 'textPrimary' : 'error'}>{overlay.title}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <TextField
                  select
                  variant="filled"
                  label={t('attributePopupInfo')}
                  SelectProps={{
                    multiple: true,
                    value: displayColumns || defaultSelectedPositionColumns,
                    onChange: (e) => {
                      setAttributes({ ...attributes, positionItems: e.target.value.join(',') });
                    },
                  }}
                >
                  {Object.keys(displayPositionAttributes).map((column) => (
                    <MenuItem key={column} value={column}>{displayPositionAttributes[column].name}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  variant="filled"
                  label={t('mapLiveRoutes')}
                  value={attributes.mapLiveRoutes || 'none'}
                  onChange={(e) => setAttributes({ ...attributes, mapLiveRoutes: e.target.value })}
                >
                  <MenuItem key="none" value="none">{t('sharedDisabled')}</MenuItem>
                  <MenuItem key="selected" value="selected">{t('deviceSelected')}</MenuItem>
                  <MenuItem key="all" value="all">{t('notificationAlways')}</MenuItem>
                </TextField>
                <TextField
                  select
                  variant="filled"
                  label={t('mapDirection')}
                  value={attributes.mapDirection || 'selected'}
                  onChange={(e) => setAttributes({ ...attributes, mapDirection: e.target.value })}
                >
                  <MenuItem key="none" value="none">{t('sharedDisabled')}</MenuItem>
                  <MenuItem key="selected" value="selected">{t('deviceSelected')}</MenuItem>
                  <MenuItem key="all" value="all">{t('notificationAlways')}</MenuItem>
                </TextField>
                <FormGroup>
                  <div className={classes.switchContainer}>
                    <ListItemText primary={t('attributeShowGeofences')} />
                    <Switch
                      checked={attributes.hasOwnProperty('mapGeofences') ? attributes.mapGeofences : true}
                      onChange={(e) => setAttributes({ ...attributes, mapGeofences: e.target.checked })}
                    />
                  </div>
                  <div className={classes.switchContainer}>
                    <ListItemText primary={t('deviceFollow')} />
                    <Switch
                      checked={attributes.hasOwnProperty('mapFollow') ? attributes.mapFollow : true}
                      onChange={(e) => setAttributes({ ...attributes, mapFollow: e.target.checked })}
                    />
                  </div>
                  <div className={classes.switchContainer}>
                    <ListItemText primary={t('mapClustering')} />
                    <Switch
                      checked={attributes.hasOwnProperty('mapCluster') ? attributes.mapCluster : true}
                      onChange={(e) => setAttributes({ ...attributes, mapCluster: e.target.checked })}
                    />
                  </div>
                  {/* <div className={classes.switchContainer}>
                    <ListItemText primary={t('mapOnSelect')} />
                    <Switch
                      checked={attributes.hasOwnProperty('mapOnSelect') ? attributes.mapOnSelect : true}
                      onChange={(e) => setAttributes({ ...attributes, mapOnSelect: e.target.checked })}
                    />
                  </div> */}
                </FormGroup>
              </CardAccordion>
              <CardAccordion
                summary={t('unitTitle')}
                detailSx={{ gap: '2rem' }}
              >
                <TextField
                  select
                  variant="filled"
                  label={t('settingsCoordinateFormat')}
                  value={user.coordinateFormat || 'dd'}
                  onChange={(e) => dispatch(sessionActions.updateUser({ ...user, coordinateFormat: e.target.value }))}
                >
                  <MenuItem key="dd" value="dd">{t('sharedDecimalDegrees')}</MenuItem>
                  <MenuItem key="ddm" value="ddm">{t('sharedDegreesDecimalMinutes')}</MenuItem>
                  <MenuItem key="dms" value="dms">{t('sharedDegreesMinutesSeconds')}</MenuItem>
                </TextField>
                <TextField
                  select
                  variant="filled"
                  label={t('settingsSpeedUnit')}
                  value={attributes.speedUnit || 'kn'}
                  onChange={(e) => setAttributes({ ...attributes, speedUnit: e.target.value })}
                >
                  <MenuItem key="kn" value="kn">{t('sharedKn')}</MenuItem>
                  <MenuItem key="kmh" value="kmh">{t('sharedKmh')}</MenuItem>
                  <MenuItem key="mph" value="mph">{t('sharedMph')}</MenuItem>
                </TextField>
                <TextField
                  select
                  variant="filled"
                  label={t('settingsDistanceUnit')}
                  value={attributes.distanceUnit || 'km'}
                  onChange={(e) => setAttributes({ ...attributes, distanceUnit: e.target.value })}
                >
                  <MenuItem key="km" value="km">{t('sharedKm')}</MenuItem>
                  <MenuItem key="mi" value="mi">{t('sharedMi')}</MenuItem>
                  <MenuItem key="nmi" value="nmi">{t('sharedNmi')}</MenuItem>
                </TextField>
                {/* <TextField
                  select
                  variant="filled"
                  label={t('settingsAltitudeUnit')}
                  value={attributes.altitudeUnit || 'm'}
                  onChange={(e) => setAttributes({ ...attributes, altitudeUnit: e.target.value })}
                >
                  <MenuItem key="m" value="m">{t('sharedMeters')}</MenuItem>
                  <MenuItem key="ft" value="ft">{t('sharedFeet')}</MenuItem>
                </TextField> */}
                {/* <TextField
                  select
                  variant="filled"
                  label={t('settingsVolumeUnit')}
                  value={attributes.volumeUnit || 'ltr'}
                  onChange={(e) => setAttributes({ ...attributes, volumeUnit: e.target.value })}
                >
                  <MenuItem key="ltr" value="ltr">{t('sharedLiter')}</MenuItem>
                  <MenuItem key="usGal" value="usGal">{t('sharedUsGallon')}</MenuItem>
                  <MenuItem key="impGal" value="impGal">{t('sharedImpGallon')}</MenuItem>
                </TextField> */}
                {/* <FormControl variant="standard" sx={{ borderBottom: 1, borderColor: '#2ECD77' }}>
                  <InputLabel sx={{ color: '#2ECD77' }}>{t('sharedTimezone')}</InputLabel>
                  <Select
                    defaultValue={attributes.timezone || ''}
                    onChange={(e) => setAttributes({ ...attributes, timezone: e.target.value })}
                    endpoint="/api/server/timezones"
                    label={t('sharedTimezone')}
                  >
                    {timezones.map((zone) => (
                      <MenuItem key={zone} value={zone}>
                        {zone}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                {/* <SelectField
                  value={attributes.timezone || ''}
                  onChange={(e) => setAttributes({ ...attributes, timezone: e.target.value })}
                  endpoint="/api/server/timezones"
                  keyGetter={(it) => it}
                  titleGetter={(it) => it}
                  label={t('sharedTimezone')}
                /> */}
                <div className={classes.switchContainer}>
                  <ListItemText primary={t('settingsTwelveHourFormat')} />
                  <Switch
                    checked={user.twelveHourFormat || false}
                    onChange={(event) => dispatch(sessionActions.updateUser({ ...user, twelveHourFormat: event.target.checked }))}
                  />
                </div>
              </CardAccordion>
              <CardAccordion
                summary={t('deviceTitle')}
                detailSx={{ gap: '2rem' }}
              >
                <SelectField
                  value={attributes.devicePrimary || 'name'}
                  onChange={(e) => setAttributes({ ...attributes, devicePrimary: e.target.value })}
                  data={deviceFields}
                  emptyValue={null}
                  titleGetter={(it) => t(it.name)}
                  label={t('devicePrimaryInfo')}
                />
                <SelectField
                  value={attributes.deviceSecondary || 'uniqueId'}
                  onChange={(e) => setAttributes({ ...attributes, deviceSecondary: e.target.value })}
                  data={deviceFields}
                  emptyValue={null}
                  titleGetter={(it) => t(it.name)}
                  label={t('deviceSecondaryInfo')}
                />
              </CardAccordion>
              {user.superuser && (
                <>
                  <CardAccordion
                    summary={t('sharedSound')}
                    detailSx={{ gap: '2rem' }}
                  >
                    <SelectField
                      multiple
                      value={attributes.soundEvents?.split(',') || []}
                      onChange={(e) => setAttributes({ ...attributes, soundEvents: e.target.value.join(',') })}
                      endpoint="/api/notifications/types"
                      keyGetter={(it) => it.type}
                      titleGetter={(it) => t(prefixString('event', it.type))}
                      label={t('eventsSoundEvents')}
                    />
                    <SelectField
                      multiple
                      value={attributes.soundAlarms?.split(',') || ['sos']}
                      onChange={(e) => setAttributes({ ...attributes, soundAlarms: e.target.value.join(',') })}
                      data={alarms}
                      keyGetter={(it) => it.key}
                      label={t('eventsSoundAlarms')}
                    />
                  </CardAccordion>
                  <CardAccordion
                    summary={t('userToken')}
                    detailSx={{ gap: '2rem' }}
                  >
                    <TextField
                      label={t('userExpirationTime')}
                      type="date"
                      value={tokenExpiration}
                      onChange={(e) => {
                        setTokenExpiration(e.target.value);
                        setToken(null);
                      }}
                    />
                    <OutlinedInput
                      multiline
                      rows={6}
                      readOnly
                      type="text"
                      value={token || ''}
                      endAdornment={(
                        <InputAdornment position="end">
                          <div className={classes.tokenActions}>
                            <IconButton size="small" edge="end" onClick={generateToken} disabled={!!token}>
                              <CachedIcon fontSize="small" />
                            </IconButton>
                            <IconButton size="small" edge="end" onClick={() => navigator.clipboard.writeText(token)} disabled={!token}>
                              <ContentCopyIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </InputAdornment>
                      )}
                    />
                  </CardAccordion>
                </>
              )}
              {/** System Version and Info */}
              <CardAccordion
                summary={t('sharedInfoTitle')}
                detailSx={{ gap: '2rem' }}
                defaultExpanded
              >
                <Typography color="text.hint">
                  &copy;2023 ATrack Version&nbsp;
                  {process.env.REACT_APP_FMS_FORMAL_VERSION}
                </Typography>
                <div color="text.hint">
                  <Typography color="text.hint">{t('settingsConnection')}</Typography>
                  <Typography color="text.hint">{socket ? t('deviceStatusOnline') : t('deviceStatusOffline')}</Typography>
                </div>
                {user.superuser && (
                  <>
                    <div color="text.hint">
                      <Typography color="text.hint">{t('settingsAppVersion')}</Typography>
                      <Typography color="text.hint">{versionApp}</Typography>
                    </div>
                    <div color="text.hint">
                      <Typography color="text.hint">{t('settingsServerVersion')}</Typography>
                      <Typography color="text.hint">{versionServer || '-'}</Typography>
                    </div>
                    <div color="text.hint">
                      <Typography color="text.hint">{t('settingsFmsWebVersion')}</Typography>
                      <Typography color="text.hint">{versionFmsWeb}</Typography>
                    </div>
                    <div>
                      <Typography color="text.hint">{t('settingsFmsServerVersion')}</Typography>
                      <Typography color="text.hint">{versionFmsServer || '-'}</Typography>
                    </div>
                  </>
                )}
              </CardAccordion>
            </>
          )}
        </CardSection>
      </CardLayout>
    </PageLayout>
  );
};

export default PreferencesPage;
