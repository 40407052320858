import React, {
  Fragment, useCallback, useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import {
  IconButton, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ReportFilter from './components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import usePersistedState from '../common/util/usePersistedState';
import PositionValue from '../common/components/PositionValue';
import ColumnSelect from './components/ColumnSelect';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { useAttributePreference } from '../common/util/preferences';
import { useCatch } from '../reactHelper';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import MapRoutePoints from '../map/MapRoutePoints';
import MapPositions from '../map/MapPositions';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import StyledTableHeadCell from '../common/components/StyledTableHeadCell';
import MapCamera from '../map/MapCamera';
import MapGeofence from '../map/MapGeofence';
// import scheduleReport from './common/scheduleReport';

const RouteReportPage = () => {
  // const navigate = useNavigate();
  const classes = useReportStyles();
  const t = useTranslation();

  const positionAttributes = usePositionAttributes(t);
  const positionColumns = Object.keys(positionAttributes);

  const hiddenPositionColumns = useAttributePreference('report.routeHiddenPositionColumns', '').split(',');
  const displayPositionAttributes = positionColumns
    .filter((attributeKey) => hiddenPositionColumns.indexOf(attributeKey) === -1)
    .reduce((obj, key) => Object.assign(obj, { [key]: positionAttributes[key] }), {});

  const devices = useSelector((state) => ({ ...state.devices.items, ...state.devices.groupItems }));

  const defaultSelectedPositionColumns = ['id', 'latitude', 'longitude', 'speed', 'course', 'fixTime', 'distance', 'totalDistance', 'tag1Temp'];
  const [columns, setColumns] = usePersistedState('routeColumns', defaultSelectedPositionColumns);
  const displayColumns = columns.filter((column) => positionColumns.indexOf(column) > -1 && hiddenPositionColumns.indexOf(column) === -1);

  // save to localStorage.routeColumns
  useEffect(() => {
    setColumns(displayColumns);
  }, []);

  // const [available, setAvailable] = useState([]);
  const [items, setItems] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setIsVisible] = useState(false);

  const onMapPointClick = useCallback((positionId) => {
    setSelectedItem(items.find((it) => it.id === positionId));
  }, [items, setSelectedItem]);

  const handleValidate = (from, to) => {
    // check page period select
    let validate = true;
    const oneDay = 1000 * 60 * 60 * 24; // One day Time in ms (milliseconds)
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const dateDelta = (toDate - fromDate) / oneDay;
    if (dateDelta > 31) {
      setErrMsg(t('periodCustomize') + t('errorMessageType1')); // over 30 days
      validate = false;
    } else if (dateDelta < 0) {
      setErrMsg(t('periodCustomize') + t('errorMessageType2')); // start_date is later than end_date
      validate = false;
    }
    return validate;
  };
  const handleSubmit = useCatch(async ({ deviceIds, from, to, type }) => {
    const validate = handleValidate(from, to);
    const query = new URLSearchParams({ from, to });
    deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
    // page period select is illegal
    if (!validate) {
      setItems([]);
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
    // page period select is legal
    if (type === 'export' && validate) {
      query.append('dataColumns', columns.join(','));
      window.location.assign(`/api/reports/route/xlsx?${query.toString()}`);
    } else if (type === 'mail' && validate) {
      query.append('dataColumns', columns.join(','));
      const response = await fetch(`/api/reports/route/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else if (type === 'json' && validate) {
      setLoading(true);
      try {
        const response = await fetch(`/api/reports/route?${query.toString()}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.length) {
            // const keySet = new Set();
            // const keyList = [];
            // data.forEach((position) => {
            //   Object.keys(position).forEach((it) => keySet.add(it));
            //   Object.keys(position.attributes).forEach((it) => keySet.add(it));
            // });
            // ['id', 'deviceId', 'outdated', 'network', 'attributes'].forEach((key) => keySet.delete(key));
            // Object.keys(positionAttributes).forEach((key) => {
            //   if (keySet.has(key)) {
            //     keyList.push(key);
            //     keySet.delete(key);
            //   }
            // });
            // setAvailable([...keyList, ...keySet].map((key) => [key, positionAttributes[key]?.name || key]));
            setItems(data);
          } else {
            setItems([]);
            setErrMsg(t('sharedNoDataWithType', { typeName: t('reportRouteRecords') }));
            setIsVisible(true);
            setTimeout(() => {
              setIsVisible(false);
            }, 3000);
          }
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  // const handleSchedule = useCatch(async (deviceIds, groupIds, report) => {
  //   report.type = 'route';
  //   const error = await scheduleReport(deviceIds, groupIds, report);
  //   if (error) {
  //     throw Error(error);
  //   } else {
  //     navigate('/reports/scheduled');
  //   }
  // });

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportRoute']}>
      <div className={classes.container}>
        {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapGeofence />
              {[...new Set(items.map((it) => it.deviceId))].map((deviceId) => {
                const positions = items.filter((position) => position.deviceId === deviceId);
                return (
                  <Fragment key={deviceId}>
                    <MapRoutePath positions={positions} />
                    <MapRoutePoints positions={positions} onClick={onMapPointClick} />
                  </Fragment>
                );
              })}
              <MapPositions positions={[selectedItem]} titleField="fixTime" />
            </MapView>
            <MapCamera positions={items} />
          </div>
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter handleSubmit={handleSubmit}>
              <ColumnSelect
                columns={displayColumns}
                setColumns={setColumns}
                columnsObject={displayPositionAttributes}
                // columnsArray={available}
                rawValues
                disabled={!(displayColumns.length + 1)}
              />
            </ReportFilter>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell className={classes.columnAction} />
                <StyledTableHeadCell>{t('sharedDevice')}</StyledTableHeadCell>
                {displayColumns.map((key) => (<StyledTableHeadCell key={key}>{displayPositionAttributes[key].name}</StyledTableHeadCell>))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? items.slice(0, 4000).map((item) => (
                <TableRow key={item.id}>
                  <TableCell className={classes.columnAction} padding="none">
                    {selectedItem === item ? (
                      <IconButton size="small" onClick={() => setSelectedItem(null)}>
                        <GpsFixedIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton size="small" onClick={() => setSelectedItem(item)}>
                        <LocationSearchingIcon fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{devices[item.deviceId].name}</TableCell>
                  {displayColumns.map((key) => (
                    <TableCell key={key}>
                      <PositionValue
                        position={item}
                        property={item.hasOwnProperty(key) ? key : null}
                        attribute={item.hasOwnProperty(key) ? null : key}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              )) : (<TableShimmer columns={displayColumns.length + 2} startAction />)}
            </TableBody>
          </Table>
        </div>
        {visible && <Alert severity="error">{errMsg}</Alert>}
      </div>
    </PageLayout>
  );
};

export default RouteReportPage;
