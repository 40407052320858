import React from 'react';
import {
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import comingSoonDesktop from '../../resources/images/coming soon_desktop_1920x1080.png';
import comingSoonTablet from '../../resources/images/coming soon_tablet_1560x1080.png';
import comingSoonMobile from '../../resources/images/coming soon_mobile_960x1440.png';

const useStyles = makeStyles(() => ({
  gif: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
}));

const OnGoingGif = () => {
  const theme = useTheme();
  const classes = useStyles();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  let pageContent = null;
  if (desktop) {
    pageContent = comingSoonDesktop;
  }
  if (tablet) {
    pageContent = comingSoonTablet;
  }
  if (mobile) {
    pageContent = comingSoonMobile;
  }
  return <img src={pageContent} alt="coming-soon" className={classes.gif} />;
};

export default OnGoingGif;
