import React from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { groupsActions } from '../../store';

const ColumnSelect = ({
  pageId, setColumns, columnsObject, disabled,
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const groupSeletedColumns = useSelector((state) => state.groups.groupSeletedColumns);
  const handleDevices = (e) => {
    setColumns(e.target.value);
    if (pageId !== null) {
      dispatch(groupsActions.selectGroupColumns(e.target.value));
    }
  };
  return (
    <div className={classes.filterItem}>
      <FormControl fullWidth>
        <InputLabel className={classes.selectLabel}>{t('sharedColumns')}</InputLabel>
        <Select
          variant="filled"
          label={t('sharedColumns')}
          value={groupSeletedColumns}
          onChange={handleDevices}
          multiple
          disabled={disabled}
        >
          {Object.keys(columnsObject).map((key) => (
            <MenuItem key={key} value={key}>{columnsObject[key].name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ColumnSelect;
