import React, { useState } from 'react';
import {
  Table, TableRow, TableHead, TableBody, TableCell, TableContainer,
} from '@mui/material';
import { useSelector } from 'react-redux';
import StyledTableHeadCell from '../common/components/StyledTableHeadCell';
import PageLayout from '../common/components/PageLayout';
import AccountsMenu from './components/AccountMenu';
import DriverFilter from './components/DriverFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import CollectionActions from './components/CollectionActions';
import useReportStyles from '../reports/common/useReportStyles';
import { useEffectAsync, useCatch } from '../reactHelper';
import { truncateText } from '../common/util/formatter';

const DriversPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const [loading, setLoading] = useState(false);
  const [driverItems, setDriverItems] = useState([]);
  const [driverItemsShow, setDriverItemsShow] = useState([]);
  const [timestamp, setTimestamp] = useState(Date.now());

  const devices = useSelector((state) => state.devices.items);

  useEffectAsync(async () => {
    setLoading(true);
    const response = await fetch('/api/drivers');
    if (response.ok) {
      const items = await response.json();
      setDriverItems(items);
      setDriverItemsShow(items);
    }
    setLoading(false);
  }, [timestamp]);

  const handleSubmit = useCatch(async ({ selectedDriver, selectedDevice }) => {
    setLoading(true);
    try {
      if (selectedDriver !== 'all' && selectedDevice === 'none') {
        const filteredDriver = driverItems.filter((driver) => driver.id === selectedDriver);
        setDriverItemsShow(filteredDriver);
      }
      if (selectedDriver === 'all' && selectedDevice !== 'none') {
        const filteredDriver = driverItems.filter((driver) => driver.deviceId === selectedDevice);
        setDriverItemsShow(filteredDriver);
      }
      if (selectedDriver === 'all' && selectedDevice === 'none') {
        setDriverItemsShow(driverItems);
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <PageLayout menu={<AccountsMenu />} breadcrumbs={['settingsUser', 'sharedDrivers']}>
      <div className={classes.header}>
        <DriverFilter handleSubmit={handleSubmit}>
          {}
        </DriverFilter>
      </div>
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell sx={{ width: '25%' }}>{t('driverName')}</StyledTableHeadCell>
              <StyledTableHeadCell sx={{ width: '25%' }}>{t('driverPhone')}</StyledTableHeadCell>
              <StyledTableHeadCell sx={{ width: '45%' }}>{t('driverWithDevice')}</StyledTableHeadCell>
              <StyledTableHeadCell className={classes.columnAction} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && driverItemsShow.map((item) => (
              <TableRow key={item.id}>
                <TableCell sx={{ width: '25%' }} className={classes.tableCell}>{truncateText(item.name, 20)}</TableCell>
                <TableCell sx={{ width: '25%' }} className={classes.tableCell}>{item.phone ? truncateText(item.phone, 20) : item.phone}</TableCell>
                <TableCell sx={{ width: '45%' }} className={classes.tableCell}>{devices[item.deviceId]?.name}</TableCell>
                <TableCell className={classes.columnAction} padding="none">
                  <CollectionActions
                    itemId={item.id}
                    itemName={item.name}
                    itemTypeName={t('settingsDriver')}
                    endpoint="/drivers"
                    setTimestamp={setTimestamp}
                    removePath={null}
                    removeDialogContent={t('sharedDeleteItemConfirm', { itemName: `${item.name}` })}
                    editPath="/accounts/edit-driver"
                    // onEdit={handleEdit}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageLayout>
  );
};

export default DriversPage;
