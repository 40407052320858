import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button, TextField, Typography, MenuItem, ListItem, ListItemIcon, Switch, ListItemText, Snackbar, Tooltip, tooltipClasses, Link,
} from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from '../common/components/LocalizationProvider';
import GoogleIcon from './GoogleImage';
import AppleIcon from './AppleImage';
import FacebookIcon from './FacebookImage';
import AccountsMenu from './components/AccountMenu';
import useQuery from '../common/util/useQuery';
import { sessionActions } from '../store';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import PageLayout from '../common/components/PageLayout';
import { useCatch } from '../reactHelper';
import { snackBarDurationShortMs } from '../common/util/duration';
import countryCodes from '../resources/countryCodes.json';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black',
  },
  selectButton: {
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
    display: 'flex',
  },
  label: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    fontWeight: 'bold',
    fontSize: 12,
  },
  countryFlag: {
    paddingBottom: '0.1rem',
  },
  countryCode: {
    marginLeft: '0.5rem',
  },
  selectIcon: {
    color: theme.palette.colors.text,
  },
  link: {
    color: theme.palette.text.link,
    textDecorationColor: theme.palette.text.link,
    '&:hover': {
      fontWeight: 'bold',
    },
  },
}));

const MultiFactorAuthenticationSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const currentUser = useSelector((state) => state.session.user);
  const [switchEnabled, setSwitchEnabled] = useState(currentUser.isPhoneMultiFactorEnabled);

  const handleSwitchChange = (event) => {
    setSwitchEnabled(event.target.checked);
    navigate('/accounts/two-factor-authentication');
  };

  const isPhoneEmpty = !(currentUser.countryCode && currentUser.phone);
  const isSwitchDisabled = currentUser.isPhoneMultiFactorEnabled === undefined ||
                            currentUser.isPhoneMultiFactorEnabled === null ||
                            isPhoneEmpty;

  return (
    <div className={classes.textContainer}>
      <ListItemText primary={t('loginNeedVerificationCode')} />
      <Tooltip
        title={isPhoneEmpty ? t('twoFactorAuthenticationTooltipPhoneEmpty') : ''}
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
                marginTop: 0,
              },
            },
          },
        }}
      >
        <span>
          <Switch
            edge="end"
            onChange={handleSwitchChange}
            checked={switchEnabled}
            disabled={isSwitchDisabled}
          />
        </span>
      </Tooltip>
    </div>
  );
};

const DeleteAccountLink = () => {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Link
      href="/accounts/delete-account"
      className={classes.link}
    >
      {t('profileBottomLinkDeleteYourAccount')}
    </Link>
  );
};

const BasicInfoPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  // const admin = useAdministrator();
  const currentUser = useSelector((state) => state.session.user);
  const loginType = useSelector((state) => state.session.user.signInProvider);
  const [pageSelect, setPageSelect] = useState('thirdPartyRegister'); // login by [general way] or [third party way (Apple / Google / Facebook) ]
  const [bind, setBind] = useState('');
  const [item, setItem] = useState(currentUser);
  const query = useQuery();
  const [queryHandled, setQueryHandled] = useState(false);
  const attribute = query.get('attribute');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errors, setErrors] = useState({
    lastNameError: '',
    nameError: '',
    countryCodeError: '',
    phoneError: '',
  });

  useEffect(() => {
    if (loginType === 'password') {
      setPageSelect('register'); // login by general way
    } else if (loginType === 'google.com') {
      setBind('google');
    } else if (loginType === 'apple.com') {
      setBind('apple');
    } else if (loginType === 'facebook.com') {
      setBind('facebook');
    }
  }, []);

  useEffect(() => {
    if (!queryHandled && item && attribute) {
      if (!item.attributes.hasOwnProperty('attribute')) {
        const updatedAttributes = { ...item.attributes };
        updatedAttributes[attribute] = '';
        setItem({ ...item, attributes: updatedAttributes });
      }
      setQueryHandled(true);
    }
  }, [item, queryHandled, setQueryHandled, attribute]);

  const isInputEmpty = (input) => input === null || input.trim() === '';

  const handleUserInfoSave = useCatch(async () => {
    if (item.signInProvider === 'password') {
      const inputErrors = {
        lastNameError: isInputEmpty(item.lastName) ? t('sharedHelperTextItemIsRequired', { itemName: t('lastName') }) : '',
        nameError: isInputEmpty(item.name) ? t('sharedHelperTextItemIsRequired', { itemName: t('firstName') }) : '',
        countryCodeError: isInputEmpty(item.countryCode) ? t('sharedHelperTextItemIsRequired', { itemName: t('countryCode') }) : '',
        phoneError: isInputEmpty(item.phone) ? t('sharedHelperTextItemIsRequired', { itemName: t('sharedPhone') }) : '',
      };
      setErrors(inputErrors);
      if (Object.values(inputErrors).some((value) => value !== '' && value !== null)) {
        return;
      }
    }

    const response = await fetch(`/api/users/${item.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      const result = await response.json();
      if (result.id === currentUser.id) {
        dispatch(sessionActions.updateUser(result));
        setSnackbarOpen(true);
      }
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <PageLayout
      menu={<AccountsMenu />}
      breadcrumbs={['settingsUser', 'basicInfo']}
    >
      <CardLayout>
        <CardSection
          header={t('info')}
          button={(
            <Button
              fullWidth
              variant="outlined"
              color="outlinedButtonGreen"
              onClick={handleUserInfoSave}
            >
              {t('sharedSave')}
            </Button>
          )}
        >
          <Grid container spacing={2}>
            <Grid xs={6}>
              <TextField
                variant="filled"
                label={t('lastName')}
                value={item.lastName || ''}
                required={item.signInProvider === 'password'}
                onChange={(event) => setItem({ ...item, lastName: event.target.value })}
                helperText={errors?.lastNameError || ' '}
                error={!!errors?.lastNameError}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                variant="filled"
                label={t('firstName')}
                value={item.name || ''}
                required={item.signInProvider === 'password'}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                helperText={errors?.nameError || ' '}
                error={!!errors?.nameError}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label={t('email')}
                required
                disabled
                value={item.email || ''}
                helperText={t('This email will be your account.')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={5}>
              <TextField
                fullWidth
                select
                variant="filled"
                required={item.signInProvider === 'password'}
                label={t('countryCode')}
                value={item.countryCode || ''}
                onChange={(e) => setItem({ ...item, countryCode: e.target.value })}
                SelectProps={{
                  classes: { icon: classes.selectIcon },
                }}
                helperText={errors?.countryCodeError || ' '}
                error={!!errors?.countryCodeError}
              >
                {countryCodes.map((items) => (
                  <MenuItem key={items.code} value={items.country_code}>
                    <Box component="span">
                      <span className={classes.countryCode}>{items.code}</span>
                      <span className={classes.countryCode}>{items.country_code}</span>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={7}>
              <TextField
                fullWidth
                variant="filled"
                required={item.signInProvider === 'password'}
                value={item.phone || ''}
                onChange={(event) => setItem({ ...item, phone: event.target.value })}
                label={t('sharedPhone')}
                helperText={errors?.phoneError || ' '}
                error={!!errors?.phoneError}
              />
            </Grid>
          </Grid>
        </CardSection>
        {pageSelect === 'register' ? (
          <CardSection
            header={t('safety')}
            description={t('safetyExplanation')}
            button={pageSelect === 'register' && (
              <Button
                fullWidth
                type="button"
                color="outlinedButtonGreen"
                variant="outlined"
                onClick={() => navigate(`/accounts/reset-password/${currentUser.id}`)}
              >
                {t('changePassword')}
              </Button>
            )}
          />
        ) : (
          <CardSection
            header={t('safety')}
            description={t('thirdPartyLoginSafetyExplanation')}
          >
            <div className={classes.label}>
              <Typography variant="h10" component="div" marginTop="theme.spacing(5)">
                {t('bindedTitle')}
              </Typography>
              {bind === 'apple' && (
                <ListItem>
                  <ListItemIcon>
                    <AppleIcon />
                  </ListItemIcon>
                </ListItem>
              )}
              {bind === 'google' && (
                <ListItem>
                  <ListItemIcon>
                    <GoogleIcon />
                  </ListItemIcon>
                </ListItem>
              )}
              {bind === 'facebook' && (
                <ListItem>
                  <ListItemIcon>
                    <FacebookIcon />
                  </ListItemIcon>
                </ListItem>
              )}
            </div>
          </CardSection>
        )}
        {pageSelect === 'register' && (
          <CardSection
            header={t('twoFactorAuthentication')}
            description={t('2FAExplanation')}
          >
            <MultiFactorAuthenticationSection />
          </CardSection>
        )}
        <CardSection
          description={t('profileBottomExplanation', {
            deleteAccountLink: <DeleteAccountLink />,
          })}
          descriptionSx={{ color: 'text.hint' }}
        />
      </CardLayout>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={snackBarDurationShortMs}
        message={t('shareSaved')}
      />
    </PageLayout>
  );
};

export default BasicInfoPage;
