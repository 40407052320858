import React from 'react';
import { makeStyles } from '@mui/styles';
import ATrackLogo from '../../resources/images/ATrackLogo_color.png';

const useStyles = makeStyles(() => ({
  image: {
    position: 'absolute',
    width: '10rem',
    left: '3.75rem',
  },
}));

const AtrackLogoImage = () => {
  const classes = useStyles();

  return (
    <img src={ATrackLogo} className={classes.image} alt="ATrack Logo" />
  );
};

export default AtrackLogoImage;
