import React, { createRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Chip, Tooltip, Typography } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMore />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '85%',
    gap: '0.5rem',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  padding: 0,
}));

const useStyles = makeStyles(() => ({
  summaryText: {
    fontWeight: 500,
  },
  itemCount: {
    fontSize: '0.875rem',
    fontWeight: 500,
    height: '1.25rem',
  },
}));

const EventsAccordion = ({
  summary, children, itemCount,
}) => {
  const classes = useStyles();
  const summaryRef = createRef();
  const [disableTooltip, setDisableTooltip] = useState(false);

  useEffect(() => {
    setDisableTooltip(summaryRef.current.scrollWidth <= summaryRef.current.clientWidth);
  }, []);

  return (
    <Accordion>
      <AccordionSummary>
        <Tooltip title={summary} disableHoverListener={disableTooltip}>
          <Typography className={classes.summaryText} ref={summaryRef} noWrap>{summary}</Typography>
        </Tooltip>
        <Chip
          label={itemCount}
          color="chipRed"
          className={classes.itemCount}
        />
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default EventsAccordion;
