import React, { useState } from 'react';
import { Alert, IconButton, LinearProgress } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectAsync } from './reactHelper';
import { sessionActions } from './store';

const isValidWebVersion = (currentVersion, minVersion) => {
  const currentVersionParts = currentVersion.split(' ');
  const minVersionParts = minVersion.split(' ');
  // check product name
  if (currentVersionParts[0] !== minVersionParts[0]) {
    return false;
  }

  // check version, e.g. v1.1.0.0 > v1.0.2.0, every part is a number and max < 100
  const currentVersionNumbers = currentVersionParts[1].replace(/^v/, '').split('.');
  const minVersionNumbers = minVersionParts[1].replace(/^v/, '').split('.');
  const currentVersionNumber = parseInt(currentVersionNumbers[0], 10) * 1000000
    + parseInt(currentVersionNumbers[1], 10) * 10000
    + parseInt(currentVersionNumbers[2], 10) * 100
    + parseInt(currentVersionNumbers[3], 10);
  const minVersionNumber = parseInt(minVersionNumbers[0], 10) * 1000000
    + parseInt(minVersionNumbers[1], 10) * 10000
    + parseInt(minVersionNumbers[2], 10) * 100
    + parseInt(minVersionNumbers[3], 10);
  return currentVersionNumber >= minVersionNumber;
};

const checkSettings = (serverSettings) => {
  const webMinVersion = serverSettings.attributes['web.minVersion'];
  const currentVersion = process.env.REACT_APP_FMS_WEB_VERSION;
  if (webMinVersion && !isValidWebVersion(currentVersion, webMinVersion)) {
    throw Error('Page version is too old. Please refresh the page.');
  }

  // check auth tenantId
  const currentTenantId = process.env.REACT_APP_FIREBASE_AUTH_TENANT_ID || '';
  if (currentTenantId !== serverSettings.authTenantId) {
    throw Error('Auth tenantId is not matched. Please refresh the page.');
  }
};

const ServerProvider = ({
  children,
}) => {
  const dispatch = useDispatch();

  const initialized = useSelector((state) => !!state.session.server);
  const [error, setError] = useState(null);

  useEffectAsync(async () => {
    if (!error) {
      try {
        const response = await fetch('/api/server');
        if (response.ok) {
          const serverSettings = await response.json();
          dispatch(sessionActions.updateServer(serverSettings));
          checkSettings(serverSettings);
        } else {
          throw Error(await response.text());
        }
      } catch (error) {
        setError(error.message);
      }
    }
  }, [error]);

  if (error) {
    return (
      <Alert
        severity="error"
        action={(
          <IconButton color="inherit" size="small" onClick={() => setError(null)}>
            <ReplayIcon fontSize="inherit" />
          </IconButton>
        )}
      >
        {error}
      </Alert>
    );
  }
  if (!initialized) {
    return (<LinearProgress />);
  }
  return children;
};

export default ServerProvider;
