import React, { useCallback, useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, debounce, TablePagination, TableContainer,
} from '@mui/material';
import { useEffectAsync } from '../../reactHelper';
import { useTranslation } from '../../common/components/LocalizationProvider';
import PageLayout from '../../common/components/PageLayout';
import SettingsMenu from '../components/SettingsMenu';
import TableShimmer from '../../common/components/TableShimmer';
import SearchHeader from '../components/SearchHeader';

const GroupsPage = () => {
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const getAdmin = (group) => {
    const admin = group.members.find((member) => member.role === 'admin');
    return admin ? admin.email : '';
  };

  const getAllMembers = (group) => {
    const members = group.members.filter((member) => member.role !== 'admin');
    return members.map((member) => (
      <li key={member.userId}>
        {member.email}
      </li>
    ));
  };

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/management/groups?q=${searchKeyword}&page=${page + 1}&size=${rowsPerPage}`);
      if (response.ok) {
        const responseJson = await response.json();
        setItems(responseJson.data.items);
        setTotalCount(responseJson.data.totalCount);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp, page, rowsPerPage]);

  const handleSearch = useCallback(debounce(() => {
    setTimestamp(Date.now());
  }, 500), []);

  const handleSearchKeywordOnChange = (value) => {
    setSearchKeyword(value);
    handleSearch(value);
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['moreTitle', 'settingsGroups']}>
      <SearchHeader
        keyword={searchKeyword}
        onChange={handleSearchKeywordOnChange}
        alwaysShow
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('sharedName')}</TableCell>
              <TableCell>{t('groupAdmin')}</TableCell>
              <TableCell>{t('groupAllMember')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{getAdmin(item)}</TableCell>
                <TableCell>{getAllMembers(item)}</TableCell>
              </TableRow>
            )) : (<TableShimmer columns={2} endAction />)}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('labelRowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => t('labelDisplayedRows', { from, to, count })}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => { setPage(newPage); }}
          onRowsPerPageChange={(e) => {
            setRowPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>
    </PageLayout>
  );
};

export default GroupsPage;
