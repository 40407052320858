import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider, Typography, IconButton, useMediaQuery, Toolbar, TextField, Button, Snackbar,
} from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import MapView from '../map/core/MapView';
// import MapCurrentLocation from '../map/MapCurrentLocation';
import MapGeofenceEdit from '../map/draw/MapGeofenceEdit';
import GeofencesList from './GeofencesList';
import { useTranslation } from '../common/components/LocalizationProvider';
import MapGeocoder from '../map/geocoder/MapGeocoder';
import CardDialog from '../common/components/CardDialog';
import CardSection from '../common/components/CardSection';
import { geometryToArea } from '../map/core/mapUtil';
import { errorsActions, geofencesActions } from '../store';
import { snackBarDurationShortMs } from '../common/util/duration';
import RemoveDialog from '../common/components/RemoveDialog';
import { useAttributePreference } from '../common/util/preferences';
import { speedFromKnots, speedToKnots, speedUnitString } from '../common/util/converter';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  drawer: {
    zIndex: 1,
  },
  drawerPaper: {
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.dimensions.drawerWidthTablet,
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.dimensions.drawerHeightPhone,
    },
  },
  mapContainer: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  fileInput: {
    display: 'none',
  },
}));

const GeofencesPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedGeofenceId, setSelectedGeofenceId] = useState();
  const [geofenceName, setGeofenceName] = useState('');
  const [geofenceArea, setGeofenceArea] = useState('');
  const [geofenceSpeedLimit, setGeofenceSpeedLimit] = useState('');
  const [geofenceNameCheck, setGeofenceNameCheck] = useState('');
  const [geofenceSpeedCheck, setGeofenceSpeedCheck] = useState('');

  const geofences = useSelector((state) => state.geofences.items);

  const speedUnit = useAttributePreference('speedUnit');

  // const handleFile = (event) => {
  //   const files = Array.from(event.target.files);
  //   const [file] = files;
  //   const reader = new FileReader();
  //   reader.onload = async () => {
  //     const xml = new DOMParser().parseFromString(reader.result, 'text/xml');
  //     const segment = xml.getElementsByTagName('trkseg')[0];
  //     const coordinates = Array.from(segment.getElementsByTagName('trkpt'))
  //       .map((point) => `${point.getAttribute('lat')} ${point.getAttribute('lon')}`)
  //       .join(', ');
  //     const area = `LINESTRING (${coordinates})`;
  //     const newItem = { name: '', area };
  //     try {
  //       const response = await fetch('/api/geofences', {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify(newItem),
  //       });
  //       if (response.ok) {
  //         const item = await response.json();
  //         navigate(`/settings/geofence/${item.id}`);
  //       } else {
  //         throw Error(await response.text());
  //       }
  //     } catch (error) {
  //       dispatch(errorsActions.push(error.message));
  //     }
  //   };
  //   reader.onerror = (event) => {
  //     dispatch(errorsActions.push(event.target.error));
  //   };
  //   reader.readAsText(file);
  // };

  const refreshGeofences = async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  };

  const checkInput = () => {
    let isFail = false;
    if (geofenceName.length < 1 || geofenceName.length > 40) {
      setGeofenceNameCheck(t('inputTextLengthLimitType', { minLength: 1, maxLength: 40 }));
      isFail = true;
    }
    if (geofenceSpeedLimit && !Number.isFinite(parseFloat(geofenceSpeedLimit))) {
      setGeofenceSpeedCheck(t('inputSpeedTypeLimit'));
      isFail = true;
    }
    if (geofenceSpeedLimit > Number.MAX_SAFE_INTEGER) {
      setGeofenceSpeedCheck(t('inputSpeedLimit', { maxInteger: Number.MAX_SAFE_INTEGER }));
      isFail = true;
    }
    if (geofenceSpeedLimit < 0) {
      setGeofenceSpeedCheck(t('inputSpeedNegativeLimit'));
      isFail = true;
    }
    return isFail;
  };

  const handleGeofenceChange = (event) => {
    if (event.type === 'draw.create') {
      const feature = event.features[0];
      const area = geometryToArea(feature.geometry);
      setGeofenceArea(area);
      setSelectedGeofenceId(null);
      setIsModalOpen(true);
    } else if (event.type === 'draw.delete') {
      const feature = event.features[0];
      const geofenceId = feature.id;
      setSelectedGeofenceId(geofenceId);
      setIsDeleteDialogOpen(true);
    }
  };

  const handleGeofenceSave = async () => {
    const isFail = checkInput();
    if (!isFail) {
      const apiUrl = selectedGeofenceId ? `/api/geofences/${selectedGeofenceId}` : '/api/geofences';
      const method = selectedGeofenceId ? 'PUT' : 'POST';
      let data = {
        name: geofenceName,
        attributes: {},
      };
      if (selectedGeofenceId) {
        const geofence = geofences[selectedGeofenceId];
        data = { ...geofence, ...data };
      } else {
        data = { area: geofenceArea, ...data };
      }

      if (geofenceSpeedLimit) {
        data.attributes.speedLimit = speedToKnots(Number(geofenceSpeedLimit), speedUnit);
      } else {
        delete data.attributes.speedLimit;
      }

      try {
        const response = await fetch(apiUrl, {
          method,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          setIsModalOpen(false);
          setGeofenceName('');
          setGeofenceArea('');
          setGeofenceSpeedLimit('');
          setGeofenceNameCheck('');
          setGeofenceSpeedCheck('');
          refreshGeofences();
          setSnackbarOpen(true);
        } else {
          throw Error(await response.text());
        }
      } catch (error) {
        dispatch(errorsActions.push(error.message));
      }
    }
  };

  const handleDeleteResult = (result) => {
    if (result) {
      setIsDeleteDialogOpen(false);
      refreshGeofences();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setGeofenceName('');
    setGeofenceArea('');
    setGeofenceSpeedLimit('');
    setGeofenceNameCheck('');
    setGeofenceSpeedCheck('');
    refreshGeofences();
  };

  const handleEdit = (geofenceId) => {
    setSelectedGeofenceId(geofenceId);
    setGeofenceName(geofences[geofenceId].name);
    setGeofenceArea(geofences[geofenceId].area);
    setGeofenceSpeedLimit(speedFromKnots(geofences[geofenceId].attributes?.speedLimit, speedUnit));
    setIsModalOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Drawer
          className={classes.drawer}
          anchor={isPhone ? 'bottom' : 'left'}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          <Toolbar>
            <IconButton edge="start" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" className={classes.title}>{t('sharedGeofences')}</Typography>
            {/* <label htmlFor="upload-gpx">
              <input accept=".gpx" id="upload-gpx" type="file" className={classes.fileInput} onChange={handleFile} />
              <IconButton edge="end" component="span" onClick={() => {}}>
                <UploadFileIcon />
              </IconButton>
            </label> */}
          </Toolbar>
          <Divider />
          <GeofencesList onGeofenceSelected={setSelectedGeofenceId} onEdit={handleEdit} />
        </Drawer>
        <div className={classes.mapContainer}>
          <MapView>
            <MapGeofenceEdit
              selectedGeofenceId={selectedGeofenceId}
              onGeofenceChange={(event) => handleGeofenceChange(event)}
            />
          </MapView>
          {/* <MapCurrentLocation /> */}
          <MapGeocoder />
        </div>
      </div>
      {/* Create/Edit Geofence Modal */}
      <CardDialog
        isOpen={isModalOpen}
      >
        <CardSection
          navIcon={<Close />}
          navIconOnClick={handleCloseModal}
          header={selectedGeofenceId ? t('sharedEditGeofence') : t('sharedCreateGeofence')}
          button={(
            <Button
              fullWidth
              variant="outlined"
              color="outlinedButtonGreen"
              disabled={!geofenceName || !geofenceArea}
              onClick={handleGeofenceSave}
            >
              {t('save')}
            </Button>
          )}
        >
          <TextField
            error={geofenceNameCheck !== ''}
            variant="filled"
            label={t('sharedGeofenceName')}
            value={geofenceName}
            helperText={geofenceNameCheck !== '' ? geofenceNameCheck : ' '}
            onChange={(event) => setGeofenceName(event.target.value)}
          />
          <TextField
            error={geofenceSpeedCheck !== ''}
            variant="filled"
            label={`${t('attributeSpeedLimit')} (${speedUnitString(speedUnit, t)})`}
            value={geofenceSpeedLimit || ''}
            helperText={geofenceSpeedCheck !== '' ? geofenceSpeedCheck : ' '}
            onChange={(event) => setGeofenceSpeedLimit(event.target.value)}
          />
        </CardSection>
      </CardDialog>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={snackBarDurationShortMs}
        message={t('shareSaved')}
      />
      <RemoveDialog
        open={isDeleteDialogOpen}
        endpoint="geofences"
        itemId={selectedGeofenceId}
        itemName={geofences[selectedGeofenceId]?.name}
        itemTypeName={t('sharedGeofence')}
        onResult={handleDeleteResult}
        onDisagree={() => {
          setIsDeleteDialogOpen(false);
          refreshGeofences();
        }}
      />
    </div>
  );
};

export default GeofencesPage;
