import React from 'react';
import {
  Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsIcon from '@mui/icons-material/Settings';
// import CreateIcon from '@mui/icons-material/Create';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import FolderIcon from '@mui/icons-material/Folder';
// import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';
// import BuildIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
import {
  BikeScooter, Feed, Info, Notes, SupportAgent,
} from '@mui/icons-material';
// import TodayIcon from '@mui/icons-material/Today';
// import PublishIcon from '@mui/icons-material/Publish';
// import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  useRestriction, useAdministrator, useSuperuser, // useManager,
} from '../../common/util/permissions';
import useFeatures from '../../common/util/useFeatures';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '0.375rem 1rem',
  },
  listSubheader: {
    padding: '0 1rem',
    margin: '0.25rem 0',
    fontSize: '0.95rem',
    fontWeight: 500,
    color: theme.palette.text.nav,
  },
}));

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const SettingsMenu = () => {
  const classes = useStyles();
  const t = useTranslation();
  const location = useLocation();

  const readonly = useRestriction('readonly');
  const admin = useAdministrator();
  const superuser = useSuperuser();
  // const manager = useManager();
  // const userId = useSelector((state) => state.session.user.id);

  const features = useFeatures();

  return (
    <>
      <List
        subheader={(
          <ListSubheader component="div" className={classes.listSubheader}>
            {t('settingsTitle')}
          </ListSubheader>
        )}
      >
        <MenuItem
          title={t('systemPreferences')}
          link="/settings/preferences"
          icon={<SettingsIcon />}
          selected={location.pathname === '/settings/preferences'}
        />
        {!readonly && (
          <>
            {/* <MenuItem
              title={t('sharedNotifications')}
              link="/settings/notify"
              icon={<NotificationsIcon />}
              selected={location.pathname.startsWith('/settings/notify')}
            /> */}
            <MenuItem
              title={t('sharedNotifications')}
              link="/settings/notifications"
              icon={<NotificationsIcon />}
              selected={location.pathname.startsWith('/settings/notification')}
            />
            <MenuItem
              title={t('supportService')}
              link="/settings/support"
              icon={<PsychologyAltIcon />}
              selected={location.pathname.startsWith('/settings/support')}
            />
            {/* <MenuItem
              title={t('settingsUser')}
              link={`/settings/user/${userId}`}
              icon={<PersonIcon />}
              selected={location.pathname === `/settings/user/${userId}`}
            /> */}
            {/* <MenuItem
              title={t('deviceTitle')}
              link="/settings/devices"
              icon={<SmartphoneIcon />}
              selected={location.pathname.startsWith('/settings/device')}
            />
            <MenuItem
              title={t('sharedGeofences')}
              link="/geofences"
              icon={<CreateIcon />}
              selected={location.pathname.startsWith('/settings/geofence')}
            /> */}
            {/* {!features.disableDrivers && (
              <MenuItem
                title={t('sharedDrivers')}
                link="/settings/drivers"
                icon={<PersonIcon />}
                selected={location.pathname.startsWith('/settings/driver')}
              />
            )} */}
            {/* {!features.disableCalendars && (
                  <MenuItem
                    title={t('sharedCalendars')}
                    link="/settings/calendars"
                    icon={<TodayIcon />}
                    selected={location.pathname.startsWith('/settings/calendar')}
                  />
                )} */}
          </>
        )}
      </List>
      <List
        subheader={(
          <ListSubheader component="div" className={classes.listSubheader}>
            {t('news')}
          </ListSubheader>
        )}
      >
        <MenuItem
          title={t('news')}
          link="/news"
          icon={<Feed />}
          selected={location.pathname.startsWith('/news')}
        />
      </List>
      {admin && (
        <>
          <Divider className={classes.divider} />
          <List
            subheader={(
              <ListSubheader component="div" className={classes.listSubheader}>
                {t('userAdmin')}
              </ListSubheader>
            )}
          >
            {/* {!features.disableComputedAttributes && (
              <MenuItem
                title={t('sharedComputedAttributes')}
                link="/settings/attributes"
                icon={<StorageIcon />}
                selected={location.pathname.startsWith('/settings/attribute')}
              />
            )} */}
            {/* {!features.disableMaintenance && (
              <MenuItem
                title={t('sharedMaintenance')}
                link="/settings/maintenances"
                icon={<BuildIcon />}
                selected={location.pathname.startsWith('/settings/maintenance')}
              />
            )} */}
            {/* <MenuItem
              title={t('sharedSavedCommands')}
              link="/settings/commands"
              icon={<PublishIcon />}
              selected={location.pathname.startsWith('/settings/command') && !location.pathname.startsWith('/settings/command-send')}
            /> */}
            <MenuItem
              title={t('settingsUsers')}
              link="/settings/management/users"
              icon={<PeopleIcon />}
              selected={location.pathname.startsWith('/settings/management/users')}
            />
            {!features.disableGroups && (
              <MenuItem
                title={t('settingsGroups')}
                link="/settings/management/groups"
                icon={<FolderIcon />}
                selected={location.pathname.startsWith('/settings/management/groups')}
              />
            )}
            <MenuItem
              title={t('deviceTitle')}
              link="/settings/management/devices"
              icon={<BikeScooter />}
              selected={location.pathname.startsWith('/settings/management/devices')}
            />
            <MenuItem
              title={t('newsTitle')}
              link="/settings/management/news"
              icon={<Info />}
              selected={location.pathname.startsWith('/settings/management/news')}
            />
            <MenuItem
              title={t('supportService')}
              link="/settings/management/support"
              icon={<SupportAgent />}
              selected={location.pathname.startsWith('/settings/management/support')}
            />
            <MenuItem
              title={t('managementApiLogs')}
              link="/settings/management/logs/api-logs"
              icon={<Notes />}
              selected={location.pathname.startsWith('/settings/management/logs/api-logs')}
            />
            <MenuItem
              title={t('managementDeviceLogs')}
              link="/settings/management/logs/device-logs"
              icon={<Notes />}
              selected={location.pathname.startsWith('/settings/management/logs/device-logs')}
            />
          </List>
        </>
      )}
      {superuser && (
        <>
          <Divider className={classes.divider} />
          <List
            subheader={(
              <ListSubheader component="div">
                {t('Superuser')}
              </ListSubheader>
            )}
          >
            {/* <MenuItem
              title={t('settingsUser')}
              link={`/settings/user/${userId}`}
              icon={<PersonIcon />}
              selected={location.pathname === `/settings/user/${userId}`}
            /> */}
            <MenuItem
              title={t('settingsServer')}
              link="/settings/management/server"
              icon={<StorageIcon />}
              selected={location.pathname === '/settings/management/server'}
            />
          </List>
        </>
      )}
    </>
  );
};

export default SettingsMenu;
