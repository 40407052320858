import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'devices',
  initialState: {
    items: {},
    selectedId: null,
    selectedIds: [],
    groupItems: {},
    groupSeletedId: null,
    groupSeletedIds: [],
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    update(state, action) {
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    select(state, action) {
      state.selectedId = action.payload;
    },
    selectId(state, action) {
      state.selectedId = action.payload;
      state.selectedIds = state.selectedId ? [state.selectedId] : [];
    },
    selectIds(state, action) {
      state.selectedIds = action.payload;
      [state.selectedId] = state.selectedIds;
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
    // Below are group shared devices redux function
    refreshGroupItems(state, action) {
      state.groupItems = {};
      action.payload.forEach((item) => state.groupItems[item.id] = item);
    },
    updateGroupItems(state, action) {
      action.payload.forEach((item) => state.groupItems[item.id] = item);
    },
    selectGroup(state, action) {
      state.groupSelectedId = action.payload;
    },
    selectGroupId(state, action) {
      state.groupSelectedId = action.payload;
      state.groupSelectedIds = state.groupSelectedId ? [state.groupSelectedId] : [];
    },
    selectGroupIds(state, action) {
      state.groupSelectedIds = action.payload;
      [state.groupSelectedId] = state.groupSelectedIds;
    },
    removeGroup(state, action) {
      delete state.groupItems[action.payload];
    },
  },
});

export { actions as devicesActions };
export { reducer as devicesReducer };
